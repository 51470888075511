import _ from 'lodash'
import update from 'immutability-helper'

import {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  SET_PRODUCT_FILTER,
  SET_PRODUCT_FORM,
  SET_PRODUCT_FORM_INGREDIENT,
  SET_PRODUCT_FORM_BYPRODUCT,
  ADD_PRODUCT_FORM_INGREDIENT,
  REMOVE_PRODUCT_FORM_INGREDIENT,
  RESET_PRODUCT_FORM,
  QUERY_PRODUCTS_REQUEST,
  QUERY_PRODUCTS_FAILURE,
  QUERY_PRODUCTS_SUCCESS,
  PRODUCT_USAGE_REQUEST,
  PRODUCT_USAGE_FAILURE,
  PRODUCT_USAGE_SUCCESS,
} from '../actions/products'

import {
  SYNC_ADD_PRODUCT,
  SYNC_UPDATE_PRODUCT,
  SYNC_PRODUCT_QTY,
} from '../actions/sync'

export const BLANK_INGREDIENT_STOCK = {
  resourceId: null,
  stockId: null,
  qty: '',
  unit: null,
}
export const BLANK_INGREDIENT_PRODUCT = {
  recipeId: null,
  productId: null,
  qty: '',
  unit: null,
}

export const INITIAL_STATE = {
  add: { isFetching: false, error: null },
  update: { isFetching: false, error: null },
  load: { isFetching: false, error: null },

  filter: {
    offset: 0,
    limit: 15,
    date: '',
    query: '',
    showAll: true,
    showOverdrawn: false,
  },
  // all loaded products keyed by id
  items: {},

  // new product form
  form: {
    name: '',
    recipeId: null,
    qty: '',
    unit: null,
    producedOnDate: '',
    producedOnTime: '',
    desc: '',
    salePrice: '',
    duration: '',
    ingredients: [],
    byProduct: null, // {resourceId, qty, unit, costPct}
  },

  query: {
    listing: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 10,
    },
    status: {
      isFetching: false,
      error: null,
    },
  },

  usage: {
    log: [],
    productId: null,
    status: { isFetching: false, error: null },
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case LOAD_PRODUCTS_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    case QUERY_PRODUCTS_REQUEST:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case QUERY_PRODUCTS_FAILURE:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case QUERY_PRODUCTS_SUCCESS:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          filter: action.filter,
        },
        items: action.cache
          ? {
            ...state.items,
            ..._.keyBy(action.listing, 'id'),
          }
          : state.items,
      }

    case PRODUCT_USAGE_REQUEST:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case PRODUCT_USAGE_FAILURE:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case PRODUCT_USAGE_SUCCESS:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: false,
            error: null,
          },
          log: action.log,
        },
      }

    // filter
    case SET_PRODUCT_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      }

    // form
    case SET_PRODUCT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }

    case SET_PRODUCT_FORM_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredients: update(state.form.ingredients, {
            [action.index]: { $merge: action.ingredient },
          }),
        },
      }

    case SET_PRODUCT_FORM_BYPRODUCT:
      return update(state, { form: { byProduct: { $merge: action.update } } })

    case ADD_PRODUCT_FORM_INGREDIENT:
      return update(state, {
        form: { ingredients: { $push: [action.ingredient] } },
      })

    case REMOVE_PRODUCT_FORM_INGREDIENT:
      return update(state, {
        form: { ingredients: { $splice: [[action.index, 1]] } },
      })

    case RESET_PRODUCT_FORM:
      return {
        ...state,
        form: { ...INITIAL_STATE.form },
      }

    // sync
    case SYNC_ADD_PRODUCT:
    case SYNC_UPDATE_PRODUCT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    case SYNC_PRODUCT_QTY:
      return update(state, {
        items: { $merge: _.keyBy(action.payload.products, 'id') },
      })

    default:
      return state
  }
}
