import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Grid,
  Label,
  Progress,
  Menu,
  Loader,
  Table,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'd3-format'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import {
  displayUnit,
  displayResourceUnit,
  getUnitPrice,
} from '../../common/unit-utils'
import { getLabel, getProductLabel, COLORS, withParamsAndNavigate } from '../../utils'
import { queryStocks, stocksUsage } from '../../actions/stocks'
import NotFound from '../common/NotFound'

class Stock extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    stock: PropTypes.object,
    resource: PropTypes.object,
    supplier: PropTypes.object,
    queryStocks: PropTypes.func.isRequired,
    queryStatus: PropTypes.object.isRequired,
    stocksUsage: PropTypes.func.isRequired,
    usageStatus: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { stock, queryStocks, stocksUsage, id } = this.props
    !stock && queryStocks({ id }, { cache: true })
    stocksUsage(id)
  }

  render() {
    const { stock, queryStatus, resource, supplier, log, usageStatus } =
      this.props

    if (queryStatus.isFetching) {
      return <Loader active />
    }

    if (!stock) {
      return <NotFound message='Няма такава наличност.' />
    }

    const {
      stock: {
        arrival,
        receipt,
        deliveryId,
        batches,
        quantity,
        curQty,
        unit,
        price,
        batchId,
        producer,
        origin,
        bioCert,
        desc,
        expiry,
        storage,
      },
      originProduct,
    } = this.props

    const label = getLabel(stock)
    const title = `Наличност - ${resource.name}`
    const unitPrice = getUnitPrice(
      price,
      quantity * batches,
      unit,
      resource.unit
    )

    return (
      <div>
        <Helmet title={title} />

        <Menu attached='top' inverted color={COLORS.STOCK} borderless>
          <Menu.Item header content={title} />
        </Menu>

        <Segment attached>
          <Label
            color={COLORS.STOCK}
            style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
            ribbon
            content={label}
            size='big'
          />

          <Grid doubling columns={4} padded='vertically'>
            <Grid.Row>
              <Grid.Column>
                <Header
                  size='medium'
                  content={
                    <Link to={'/resources/' + resource.id}>
                      {resource.name}
                    </Link>
                  }
                  subheader='суровина'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='medium'
                  content={
                    <Link to={`/companies/${supplier.id}`}>
                      {supplier.name}
                    </Link>
                  }
                  subheader='доставчик'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='medium'
                  content={moment(arrival).format('DD/MM/YY HH:mm')}
                  subheader='пристигане'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={
                    <Link to={`/stocks/delivery/${deliveryId}`}>
                      {receipt || '~'}
                    </Link>
                  }
                  subheader='документ'
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Progress
                  value={curQty}
                  total={batches * quantity}
                  indicating
                  progress
                  precision={1}
                  label={`${format('.3~f')(curQty)} ${displayUnit(unit)}`}
                  error={curQty < 0}
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={`${batches} x ${quantity} ${displayUnit(unit)}`}
                  subheader='количество'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={`${format('.2f')(price)} лв.`}
                  subheader='цена'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={`${format('.2f')(
                    unitPrice
                  )} лв. ${displayResourceUnit(resource.unit)}`}
                  subheader='ед. цена'
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Header
                  size='small'
                  content={batchId || '~'}
                  subheader='партиден №'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={producer || '~'}
                  subheader='производител'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={origin || '~'}
                  subheader='произход'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={(expiry && moment(expiry).format('DD/MM/YY')) || '~'}
                  subheader='годност'
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Header
                  size='small'
                  content={storage || '~'}
                  subheader='съхранение'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={bioCert || '~'}
                  subheader='био сертификат'
                />
              </Grid.Column>

              <Grid.Column>
                <Header
                  size='small'
                  content={desc || '~'}
                  subheader='описание'
                />
              </Grid.Column>

              {originProduct ? (
                <Grid.Column>
                  <Header
                    size='small'
                    content={
                      <Link to={`/products/${originProduct.id}`}>
                        {originProduct.name}
                      </Link>
                    }
                    subheader='от продукт'
                  />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </Segment>

        <Menu secondary pointing>
          <Menu.Item active>История</Menu.Item>
        </Menu>
        <div className='y-scrollable'>
          <Table selectable compact unstackable size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>дата</Table.HeaderCell>
                <Table.HeaderCell>
                  продукт / продажба / ревизия
                </Table.HeaderCell>
                <Table.HeaderCell>име / вид / бележки</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>преди</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Δ</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>след</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {usageStatus.isFetching && (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Segment basic padded>
                      <Loader active />
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {!usageStatus.isFetching && _.isEmpty(log) && (
                <Table.Row disabled>
                  <Table.Cell colSpan={6} textAlign='center'>
                    <em>Няма открити записи.</em>
                  </Table.Cell>
                </Table.Row>
              )}
              {!usageStatus.isFetching &&
                _.map(log, (entry, index) => {
                  const { before, after, diff } = entry
                  const date =
                    entry.producedOn || entry.revisedAt || entry.soldOn
                  const cost =
                    (entry.soldOn && -entry.price) ||
                    (entry.revisedAt && entry.cost) ||
                    (entry.producedOn &&
                      diff * (stock.price / (stock.quantity * stock.batches)))
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{moment(date).format('DD/MM/YY')}</Table.Cell>
                      {entry.producedOn && (
                        <Table.Cell>
                          <Label
                            size='small'
                            color={COLORS.PRODUCT}
                            style={{ opacity: entry.curQty > 0 ? 1 : 0.5 }}
                            tag
                            as={Link}
                            to={`/products/${entry.id}`}
                            content={getProductLabel(entry)}
                          />
                        </Table.Cell>
                      )}
                      {entry.revisedAt && (
                        <Table.Cell>
                          <Link to={`/revisions/${entry.id}`}>ревизия</Link>
                        </Table.Cell>
                      )}
                      {entry.soldOn && (
                        <Table.Cell>
                          <Link to={`/sales/${entry.id}`}>продажба</Link>
                        </Table.Cell>
                      )}

                      <Table.Cell>
                        {entry.name || entry.notes || entry.type}
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        {`${format('.2f')(cost)} лв.`}
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        {format('.3f')(before)}
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        {format('.3f')(diff)}
                      </Table.Cell>
                      <Table.Cell textAlign='right' negative={after < 0}>
                        {format('.3f')(after)}
                      </Table.Cell>
                      <Table.Cell>{displayUnit(stock.unit)}</Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (
    { stocks, resources, companies, products },
    { params: { id } },
  ) => {
    const stock =
      stocks.items[id] ||
      (stocks.query.filter.id === id && stocks.query.listing[0])
    let originProduct = null
    if (stock && stock.originProduct) {
      originProduct = products.items[stock.originProduct]
    }
    return {
      id,
      stock,
      originProduct,
      queryStatus: stocks.query.status,
      usageStatus: stocks.usage.status,
      log: stocks.usage.log,
      resource: stock && resources.items[stock.resource],
      supplier: stock && companies.items[stock.supplier],
    }
  },
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        queryStocks,
        stocksUsage,
      },
      dispatch
    )
)(Stock))
