import React, { lazy, useEffect, useState } from 'react'
import { Container, Divider, Loader } from 'semantic-ui-react'
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Login from './Login'
import TopHeader from './TopHeader'
import NotFound from './common/NotFound'

import { bootstrap } from '../actions/auth'

import Resources from './resource/Resources'
import Resource from './resource/Resource'
import NewResource from './resource/NewResource'
import EditResource from './resource/EditResource'
import Settings from './Settings'

import Recipes from './recipe/Recipes'
import Recipe from './recipe/Recipe'
import EditRecipe from './recipe/EditRecipe'
import NewRecipe from './recipe/NewRecipe'

import Products from './product/Products'
import Product from './product/Product'
import NewProduct from './product/NewProduct'
import EditProduct from './product/EditProduct'

import Companies from './company/Companies'
import Company from './company/Company'
import EditCompany from './company/EditCompany'
import NewCompany from './company/NewCompany'

import Stocks from './stocks/Stocks'
import Stock from './stocks/Stock'
import NewStockDelivery from './stocks/NewStockDelivery'
import StockDelivery from './stocks/StockDelivery'
import EditStockDelivery from './stocks/EditStockDelivery'
import StockJournal from './stocks/StockJournal'

import Revision from './revision/Revision'
import Revisions from './revision/Revisions'
import NewRevisions from './revision/NewRevisions'
import EditRevision from './revision/EditRevision'

import Sales from './sales/Sales'
import Sale from './sales/Sale'
import SaleReceipt from './sales/SaleReceipt'
import SaleReceiptPrint from './sales/SaleReceiptPrint'
import TradeDocPrint from './sales/TradeDocPrint'
import NewSales from './sales/NewSales'
import EditSaleReceipt from './sales/EditSaleReceipt'
import PriceTiers from './sales/PriceTiers'

const Reports = lazy(() => import('./reports/Reports'))
const Expenses = lazy(() => import('./expenses/Expenses'))

const CoreLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [hasBootstrapped, setHasBootstrapped] = useState(false)
  const { user, isBootstrapping } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(bootstrap()).then(() => setHasBootstrapped(true))
  }, [dispatch])

  if (isBootstrapping || !hasBootstrapped) {
    return <Loader active size='large' />
  }

  return (
    <Routes>
      <Route
        element={
          <Container>
            <Helmet title='Pantry' />
            <TopHeader />

            <Outlet />

            <Divider hidden />
          </Container>
        }
      >
        {!user ? (
          <>
            <Route path='login' element={<Login />} />
            <Route
              path='*'
              element={<Navigate to='/login' state={{ from: location }} />}
            />
          </>
        ) : (
          <>
            <Route path='login' element={<Navigate to='/' />} />
            <Route index element={<Navigate to='/products' />} />

            <Route path='resources' element={<Resources />} />
            <Route path='resources/new' element={<NewResource />} />
            <Route path='resources/:id/edit' element={<EditResource />} />
            <Route path='resources/:id/:slug?/*' element={<Resource />} />

            <Route path='products' element={<Products />} />
            <Route path='products/new' element={<NewProduct />} />
            <Route path='products/:id/edit' element={<EditProduct />} />
            <Route path='products/:id/:slug?' element={<Product />} />

            <Route path='recipes' element={<Recipes />} />
            <Route path='recipes/new' element={<NewRecipe />} />
            <Route path='recipes/:id/edit' element={<EditRecipe />} />
            <Route path='recipes/:id/:slug?/*' element={<Recipe />} />

            <Route path='companies' element={<Companies />} />
            <Route path='companies/new' element={<NewCompany />} />
            <Route path='companies/:id/edit' element={<EditCompany />} />
            <Route path='companies/:id/:slug?/*' element={<Company />} />

            <Route path='stocks' element={<Stocks />} />
            <Route path='stocks/:id' element={<Stock />} />
            <Route path='stocks/delivery/new' element={<NewStockDelivery />} />
            <Route
              path='stocks/delivery/:deliveryId'
              element={<StockDelivery />}
            />
            <Route
              path='stocks/delivery/:deliveryId/edit'
              element={<EditStockDelivery />}
            />

            <Route path='journal' element={<StockJournal />} />

            <Route path='revisions' element={<Revisions />} />
            <Route path='revisions/new' element={<NewRevisions />} />
            <Route path='revisions/:id' element={<Revision />} />
            <Route path='revisions/:id/edit' element={<EditRevision />} />

            <Route path='sales' element={<Sales />} />
            <Route path='sales/new' element={<NewSales />} />
            <Route path='sales/:id' element={<Sale />} />
            <Route path='sales/receipt/:receiptId' element={<SaleReceipt />} />
            <Route
              path='sales/receipt/:receiptId/edit'
              element={<EditSaleReceipt />}
            />
            <Route path='sales/price-tiers' element={<PriceTiers />} />

            <Route path='expenses' element={<Expenses />} />

            <Route path='reports/*' element={<Reports />} />

            <Route path='settings' element={<Settings />} />

            <Route path='*' element={<NotFound />} />
          </>
        )}
      </Route>

      {user && (
        <>
          <Route
            path='sales/receipt/:receiptId/print'
            element={<SaleReceiptPrint />}
          />
          <Route
            path='sales/receipt/:receiptId/print-tradedoc'
            element={<TradeDocPrint />}
          />
        </>
      )}
    </Routes>
  )
}

export default CoreLayout
