import _ from 'lodash'
import React from 'react'
import { Table, Segment, Loader, Label } from 'semantic-ui-react'
import moment from 'moment'

import { COLORS, getProductLabel, getLabel } from '../../utils'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { displayUnit } from '../../common/unit-utils'
import { format } from 'd3-format'
import RoleFilter from '../common/RoleFilter'

export default ({ listing, loading }) => {
  const resources = useSelector(({ resources }) => resources.items)

  return (
    <div className='y-scrollable'>
      <Table
        selectable
        compact
        unstackable
        color={COLORS.REVISION}
        size='small'
        singleLine
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>дата</Table.HeaderCell>
            <Table.HeaderCell>етикет</Table.HeaderCell>
            <Table.HeaderCell>име</Table.HeaderCell>
            <Table.HeaderCell>бележки</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>преди</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>след</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='center'>
              Δ
            </Table.HeaderCell>
            <RoleFilter>
              <Table.HeaderCell textAlign='center'>цена</Table.HeaderCell>
            </RoleFilter>
            <Table.HeaderCell>от</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <Segment basic padded>
                  <Loader active />
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : (
            _.map(listing, (revision) => {
              const {
                revisedAt,
                newQty,
                product,
                stock,
                oldQty,
                cost,
                notes,
                createdBy,
              } = revision

              const diff = newQty - oldQty
              const name =
                (product && product.name) ||
                (stock && resources[stock.resource].name)
              const unit = (product && product.unit) || (stock && stock.unit)

              return (
                <Table.Row key={revision._id}>
                  <Table.Cell>
                    {moment(revisedAt).format('DD-MM-YY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    {product ? (
                      <Label
                        color={COLORS.PRODUCT}
                        style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                        tag
                        as={Link}
                        to={`/products/${product._id}`}
                        content={getProductLabel(product)}
                      />
                    ) : (
                      <Label
                        color={COLORS.STOCK}
                        style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                        tag
                        as={Link}
                        to={`/stocks/${stock._id}`}
                        content={getLabel(stock)}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/revisions/${revision._id}`}>{name}</Link>
                  </Table.Cell>
                  <Table.Cell title={notes}>
                    {_.truncate(notes, { length: 35 })}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {format('.4~f')(oldQty)}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {format('.4~f')(newQty)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign='right'
                    negative={diff < 0}
                    positive={diff > 0}
                  >
                    {format('.4~f')(diff)}
                  </Table.Cell>
                  <Table.Cell
                    style={{ paddingLeft: 0 }}
                    negative={diff < 0}
                    positive={diff > 0}
                  >
                    {displayUnit(unit)}
                  </Table.Cell>
                  <RoleFilter>
                    <Table.Cell
                      textAlign='right'
                      negative={cost < 0}
                      positive={cost > 0}
                    >
                      {_.isNil(cost) ? '~' : `${format('.2f')(cost)} лв.`}
                    </Table.Cell>
                  </RoleFilter>
                  <Table.Cell>{_.get(createdBy, 'username')}</Table.Cell>
                </Table.Row>
              )
            })
          )}
        </Table.Body>
      </Table>
    </div>
  )
}
