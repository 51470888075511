import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Dropdown, Message, Checkbox, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import { formActions } from '../../actions/resources'
import {
  displayUnit,
  displayResourceUnit,
  RESOURCE_UNITS,
  DENSITY_UNITS,
  getSalePriceUnit,
} from '../../common/unit-utils'
import DecimalInput from '../common/DecimalInput'

export class ResourceForm extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    buttonGroup: PropTypes.node.isRequired,
    // connect
    form: PropTypes.object.isRequired,
    // form actions
    setForm: PropTypes.func.isRequired,
  }

  render() {
    const {
      name,
      desc,
      unitPrice,
      unit,
      density,
      densityUnit,
      allergen,
      gtn,
      salePrice,
    } = this.props.form
    const { setForm } = this.props

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        loading={this.props.loading}
        error={!!this.props.error}
      >
        <Form.Group widths='equal'>
          <Form.Input
            label='Суровина'
            placeholder='име на суровина'
            required
            autoFocus
            value={name}
            onChange={(e, { value }) => setForm({ name: value })}
          />

          <Form.Field className='required'>
            <label>Цена</label>
            <DecimalInput
              placeholder='цена'
              required
              value={unitPrice}
              onChange={(e, { value }) => setForm({ unitPrice: value })}
              fluid
              labelPosition='right'
              label={
                <Dropdown
                  value={unit}
                  options={_.map(RESOURCE_UNITS, (unit) => ({
                    value: unit,
                    text: displayResourceUnit(unit),
                  }))}
                  onChange={(e, { value }) => {
                    setForm({ unit: value })
                  }}
                />
              }
            />
          </Form.Field>

          <Form.Field>
            <label>Продажна цена</label>
            <DecimalInput
              placeholder='продажна цена'
              label={`лв./${displayUnit(getSalePriceUnit(unit)) || 'м.ед.'}`}
              labelPosition='right'
              value={salePrice}
              onChange={(e, { value }) => setForm({ salePrice: _.trim(value) })}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Плътност</label>
            <DecimalInput
              value={density}
              disabled={unit === 'count'}
              onChange={(e, { value }) => setForm({ density: value })}
              fluid
              labelPosition='right'
              label={
                <Dropdown
                  value={densityUnit}
                  placeholder='м. ед.'
                  disabled={unit === 'count' || !density}
                  options={_.map(DENSITY_UNITS, (unit) => ({
                    value: unit,
                    text: displayUnit(unit),
                  }))}
                  onChange={(e, { value }) => {
                    setForm({ densityUnit: value })
                  }}
                />
              }
            />
          </Form.Field>

          <Form.Field
            control={DecimalInput}
            fluid
            label='Бруто/Нето'
            value={gtn}
            onChange={(e, { value }) => setForm({ gtn: value })}
          />

          <Form.Field>
            <label>Алерген</label>
            <Checkbox
              toggle
              checked={allergen}
              placeholder='алерген'
              onChange={() => setForm({ allergen: !allergen })}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Input
            width={16}
            label='Описание'
            value={desc}
            onChange={(e, { value }) => setForm({ desc: value })}
          />
        </Form.Group>
        <Divider hidden />

        {this.props.buttonGroup}
        <Message error content={this.props.error} />
      </Form>
    )
  }
}

export default connect(
  (state) => ({
    form: state.resources.form,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        ...formActions,
      },
      dispatch
    )
)(ResourceForm)
