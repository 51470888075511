import { parse, isValid } from 'date-fns'

import { api } from '../utils'

// fetch revision
export const FETCH_REVISION_REQUEST = 'FETCH_REVISION_REQUEST'
export const FETCH_REVISION_SUCCESS = 'FETCH_REVISION_SUCCESS'
export const FETCH_REVISION_FAILURE = 'FETCH_REVISION_FAILURE'

export const fetchRevision = (revisionId) => (dispatch, getState) => {
  const { fetch } = getState().revisions

  if (fetch.isFetching) {
    return Promise.resolve()
  }

  dispatch({ type: FETCH_REVISION_REQUEST })

  return api
    .withOrg(getState)('/revisions/' + revisionId)
    .then((res) =>
      dispatch({
        type: FETCH_REVISION_SUCCESS,
        revision: res,
      })
    )
    .catch((error) => dispatch({ type: FETCH_REVISION_FAILURE, error }))
}

// query revisions

export const QUERY_REVISIONS_REQUEST = 'QUERY_REVISIONS_REQUEST'
export const QUERY_REVISIONS_SUCCESS = 'QUERY_REVISIONS_SUCCESS'
export const QUERY_REVISIONS_FAILURE = 'QUERY_REVISIONS_FAILURE'

export const queryRevisions = (filter) => (dispatch, getState) => {
  const { query } = getState().revisions
  filter = filter || query.filter

  if (query.status.isFetching) {
    return Promise.resolve()
  }

  // take care of timezone
  let date = filter.date
  let md = parse(date, 'dd-MM-yyyy', new Date())
  if (isValid(md)) {
    date = md.toISOString()
  }

  dispatch({ type: QUERY_REVISIONS_REQUEST })

  return api
    .withOrg(getState)('/revisions', {
      query: { ...filter, date },
    })
    .then((res) =>
      dispatch({
        type: QUERY_REVISIONS_SUCCESS,
        listing: res.listing,
        total: res.total,
        filter,
      })
    )
    .catch((error) => dispatch({ type: QUERY_REVISIONS_FAILURE, error }))
}

// add revisions

export const ADD_REVISIONS_REQUEST = 'ADD_REVISIONS_REQUEST'
export const ADD_REVISIONS_SUCCESS = 'ADD_REVISIONS_SUCCESS'
export const ADD_REVISIONS_FAILURE = 'ADD_REVISIONS_FAILURE'

export const addRevisions = (revisions) => (dispatch, getState) => {
  if (getState().revisions.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_REVISIONS_REQUEST, payload: revisions })

  return api
    .withOrg(getState)('/revisions', { method: 'POST', body: revisions })
    .then((res) => {
      return dispatch({
        type: ADD_REVISIONS_SUCCESS,
        payload: res.revisions,
      })
    })
    .catch((error) => dispatch({ type: ADD_REVISIONS_FAILURE, error }))
}

// update revision

export const UPDATE_REVISION_REQUEST = 'UPDATE_REVISION_REQUEST'
export const UPDATE_REVISION_SUCCESS = 'UPDATE_REVISION_SUCCESS'
export const UPDATE_REVISION_FAILURE = 'UPDATE_REVISION_FAILURE'

export const updateRevision = (revisionId, update) => (dispatch, getState) => {
  if (getState().revisions.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_REVISION_REQUEST })

  return api
    .withOrg(getState)(`/revisions/${revisionId}`, {
      method: 'POST',
      body: update,
    })
    .then((res) =>
      dispatch({
        type: UPDATE_REVISION_SUCCESS,
        payload: res,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_REVISION_FAILURE, error }))
}

// remove revision

export const REMOVE_REVISION_REQUEST = 'REMOVE_REVISION_REQUEST'
export const REMOVE_REVISION_SUCCESS = 'REMOVE_REVISION_SUCCESS'
export const REMOVE_REVISION_FAILURE = 'REMOVE_REVISION_FAILURE'

export const removeRevision = (revisionId) => (dispatch, getState) => {
  if (getState().revisions.remove.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_REVISION_REQUEST })

  return api
    .withOrg(getState)(`/revisions/${revisionId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_REVISION_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_REVISION_FAILURE, error }))
}

// entry form

export const REVISIONS_FORM_SET = 'REVISIONS_FORM_SET'
export const setRevisionsForm = (form) => ({ type: REVISIONS_FORM_SET, form })

export const RESET_REVISIONS_FORM = 'RESET_REVISIONS_FORM'
export const resetRevisionsForm = () => ({ type: RESET_REVISIONS_FORM })

export const REVISIONS_FORM_SET_ITEM = 'REVISIONS_FORM_SET_ITEM'
export const setRevisionsFormItem = (item) => ({
  type: REVISIONS_FORM_SET_ITEM,
  item,
})

export const REVISIONS_FORM_ADD_ITEM = 'REVISIONS_FORM_ADD_ITEM'
export const addRevisionsFormItem = () => ({ type: REVISIONS_FORM_ADD_ITEM })

export const REVISIONS_FORM_REMOVE_ITEM = 'REVISIONS_FORM_REMOVE_ITEM'
export const removeRevisionsFormItem = (index) => ({
  type: REVISIONS_FORM_REMOVE_ITEM,
  index,
})

export const REVISIONS_FORM_EDIT_ITEM = 'REVISIONS_FORM_EDIT_ITEM'
export const editRevisionsFormItem = (index) => ({
  type: REVISIONS_FORM_EDIT_ITEM,
  index,
})

export const REVISIONS_FORM_UPDATE_ITEM = 'REVISIONS_FORM_UPDATE_ITEM'
export const updateRevisionsFormItem = () => ({
  type: REVISIONS_FORM_UPDATE_ITEM,
})

export const REVISIONS_FORM_MOVE_ITEM = 'REVISIONS_FORM_MOVE_ITEM'
export const moveRevisionsFormItem = (fromIndex, toIndex) => ({
  type: REVISIONS_FORM_MOVE_ITEM,
  fromIndex,
  toIndex,
})

// query product/stock curQty before given time

export const CALC_QTY_BEFORE_REQUEST = 'CALC_QTY_BEFORE_REQUEST'
export const CALC_QTY_BEFORE_SUCCESS = 'CALC_QTY_BEFORE_SUCCESS'
export const CALC_QTY_BEFORE_FAILURE = 'CALC_QTY_BEFORE_FAILURE'

export const calcQtyBefore = (type, id, before) => (dispatch, getState) => {
  if (getState().revisions.calcQtyBefore.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: CALC_QTY_BEFORE_REQUEST })

  return api
    .withOrg(getState)(`/${type}s/${id}/usage`, {
      query: { before, 'no-log': true },
    })
    .then((res) =>
      dispatch({
        type: CALC_QTY_BEFORE_SUCCESS,
        payload: res,
      })
    )
    .catch((error) => dispatch({ type: CALC_QTY_BEFORE_FAILURE, error }))
}
