import _ from 'lodash'
import { RESOURCE_UNITS, DENSITY_UNITS } from '../common/unit-utils'

import {
  ADD_RESOURCE_REQUEST,
  ADD_RESOURCE_SUCCESS,
  ADD_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  REMOVE_RESOURCE_REQUEST,
  REMOVE_RESOURCE_SUCCESS,
  REMOVE_RESOURCE_FAILURE,
  LOAD_RESOURCES_REQUEST,
  LOAD_RESOURCES_SUCCESS,
  LOAD_RESOURCES_FAILURE,
  formTypes,
  SET_RESOURCE_FILTER,
} from '../actions/resources'

import {
  SYNC_ADD_RESOURCE,
  SYNC_UPDATE_RESOURCE,
  SYNC_REMOVE_RESOURCE,
} from '../actions/sync'

export const INITIAL_STATE = {
  add: {
    isFetching: false,
    error: null,
  },
  update: {
    isFetching: false,
    error: null,
  },
  remove: {
    isFetching: false,
    error: null,
  },
  load: {
    isFetching: false,
    error: null,
  },
  form: {
    name: '',
    desc: '',
    unit: RESOURCE_UNITS[0],
    unitPrice: '',
    salePrice: '',
    density: '',
    densityUnit: DENSITY_UNITS[0],
    allergen: false,
    gtn: 1,
  },
  filter: {
    offset: 0,
    limit: 15,
    query: '',
  },
  items: {},
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_RESOURCE_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_RESOURCE_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_RESOURCE_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_RESOURCE_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case REMOVE_RESOURCE_REQUEST:
      return {
        ...state,
        remove: {
          isFetching: true,
          error: null,
          id: action.resourceId,
        },
      }
    case REMOVE_RESOURCE_FAILURE:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: action.error,
        },
      }
    case REMOVE_RESOURCE_SUCCESS:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: null,
        },
        items: _.omit(state.items, state.remove.id),
      }

    case LOAD_RESOURCES_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_RESOURCES_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_RESOURCES_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    // form actions
    case formTypes.RESOURCE_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }
    case formTypes.RESOURCE_FORM_RESET:
      return {
        ...state,
        form: INITIAL_STATE.form,
      }

    // filter
    case SET_RESOURCE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      }

    // sync
    case SYNC_ADD_RESOURCE:
    case SYNC_UPDATE_RESOURCE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    case SYNC_REMOVE_RESOURCE:
      return {
        ...state,
        items: _.omit(state.items, action.payload.id),
      }

    default:
      return state
  }
}
