import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Table,
  Dropdown,
  Icon,
  Message,
  Header,
  Divider,
  Input,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { format } from 'd3-format'
import validator from 'validator'

import { formActions } from '../../actions/recipes'
import {
  displayUnit,
  getValidIngredientUnits,
  QUANTITY_UNITS,
  DENSITY_UNITS,
  ingredientCost,
  ingredientsCost,
  getSalePriceUnit,
} from '../../common/unit-utils'
import './RecipeForm.css'
import DraggableRow from './DraggableRow'
import { clickToFocus, focus, COLORS } from '../../utils'
import DecimalInput from '../common/DecimalInput'

export class RecipeForm extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    curRecipeId: PropTypes.string,
    buttonGroup: PropTypes.node.isRequired,
    // connect
    form: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    recipes: PropTypes.object.isRequired,
    // form actions
    setForm: PropTypes.func.isRequired,
    addIngredient: PropTypes.func.isRequired,
    moveIngredient: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    copyIngredient: PropTypes.func.isRequired,
    updateIngredient: PropTypes.func.isRequired,
    selectIngredient: PropTypes.func.isRequired,
  }

  handleAddOrUpdateIngredient = () => {
    if (this.isValidIngredient()) {
      if (this.props.form.ingredient.id) {
        this.props.updateIngredient()
      } else {
        this.props.addIngredient(this.props.curRecipeId)
      }
      _.defer(() => focus('[name=ingredient-dropdown]>input'))
    }
  }

  isValidIngredient = () => {
    let {
      ingredient: { resourceId, recipeId, quantity, unit },
    } = this.props.form
    return (
      (resourceId || recipeId) &&
      validator.isFloat(quantity + '', { gt: 0 }) &&
      unit
    )
  }

  getIngredientOptions = () => {
    const { resources, recipes, curRecipeId } = this.props
    return _.map(
      {
        ...resources,
        ..._.pickBy(
          recipes,
          (recipe) => recipe.showAsIngredient && recipe.id !== curRecipeId
        ),
      },
      ({ id, name, desc, showAsIngredient }) => ({
        key: id,
        value: id + (showAsIngredient ? '.r' : ''),
        text: name,
        content: (
          <Header size='tiny'>
            <Icon
              name='circle'
              color={showAsIngredient ? COLORS.RECIPE : COLORS.RESOURCE}
            />
            <Header.Content>{name}</Header.Content>
            <Header.Subheader>
              {_.truncate(desc, { length: 65 })}
            </Header.Subheader>
          </Header>
        ),
      })
    )
  }

  getIngredientUnitOptions = () => {
    const {
      resources,
      recipes,
      form: {
        ingredient: { resourceId, recipeId },
      },
    } = this.props

    const resourceOrRecipe = resourceId
      ? resources[resourceId]
      : recipes[recipeId]
    return _.map(getValidIngredientUnits(resourceOrRecipe), (u) => ({
      key: u,
      value: u,
      text: displayUnit(u),
    }))
  }

  getByProductResourceOptions = () => {
    return _.map(this.props.resources, ({ id, name, desc }) => ({
      key: id,
      value: id,
      text: name,
      content: (
        <Header size='tiny'>
          <Icon name='circle' color={COLORS.RESOURCE} />
          <Header.Content>{name}</Header.Content>
          <Header.Subheader>
            {_.truncate(desc, { length: 65 })}
          </Header.Subheader>
        </Header>
      ),
    }))
  }

  getByProductUnitOptions = () => {
    const {
      resources,
      form: {
        byProduct: { resourceId },
      },
    } = this.props

    const resource = resources[resourceId]
    return _.map(getValidIngredientUnits(resource), (u) => ({
      key: u,
      value: u,
      text: displayUnit(u),
    }))
  }

  render() {
    const {
      name,
      desc,
      ingredients,
      ingredient,
      quantity,
      unit,
      density,
      densityUnit,
      salePrice,
      duration,
      showAsIngredient,
      byProduct,
      ingredientCycle,
    } = this.props.form
    const { resources, recipes } = this.props
    const totalCost = ingredientsCost(ingredients, resources, recipes)

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        loading={this.props.loading}
        error={!!this.props.error}
      >
        <Form.Group>
          <Form.Input
            label='Наименование'
            required
            autoFocus
            width={8}
            value={name}
            onChange={(e, { value }) => this.props.setForm({ name: value })}
            onKeyPress={({ key }) => key === 'Enter' && focus('#sale-price')}
          />

          <Form.Field width={4}>
            <label>Продажна цена</label>
            <DecimalInput
              id='sale-price'
              placeholder='продажна цена'
              label={`лв./${displayUnit(getSalePriceUnit(unit)) || 'м.ед.'}`}
              labelPosition='right'
              value={salePrice}
              onChange={(e, { value }) =>
                this.props.setForm({ salePrice: _.trim(value) })
              }
              onKeyPress={({ key }) => key === 'Enter' && focus('#duration')}
            />
          </Form.Field>

          <Form.Field width={4}>
            <label>Трайност</label>
            <Input
              id='duration'
              type='number'
              min={1}
              placeholder='трайност'
              label='дни'
              labelPosition='right'
              value={duration}
              onChange={(e, { value }) =>
                this.props.setForm({ duration: value })
              }
              onKeyPress={({ key }) =>
                key === 'Enter' && focus('#quantity-input')
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field className='required'>
            <label>Количество</label>
            <DecimalInput
              id='quantity-input'
              placeholder='количество'
              value={quantity}
              fluid
              labelPosition='right'
              onChange={(e, { value }) =>
                this.props.setForm({ quantity: value })
              }
              onKeyPress={({ key }) =>
                key === 'Enter' && clickToFocus('#quantity-unit-dropdown')
              }
              label={
                <Dropdown
                  id='quantity-unit-dropdown'
                  placeholder='м. ед.'
                  value={unit}
                  options={_.map(QUANTITY_UNITS, (u) => ({
                    value: u,
                    text: displayUnit(u),
                  }))}
                  onChange={(e, { value }) => {
                    this.props.setForm({ unit: value })
                    e.code === 'Enter' && focus('#density-input')
                  }}
                />
              }
            />
          </Form.Field>

          <Form.Field>
            <label>Плътност</label>
            <DecimalInput
              id='density-input'
              placeholder='плътност'
              value={density}
              disabled={unit === 'count'}
              onChange={(e, { value }) =>
                this.props.setForm({ density: value })
              }
              onKeyPress={({ key }) =>
                key === 'Enter' && clickToFocus('#density-unit-dropdown')
              }
              fluid
              labelPosition='right'
              label={
                <Dropdown
                  id='density-unit-dropdown'
                  placeholder='м. ед.'
                  value={densityUnit}
                  disabled={!density}
                  options={_.map(DENSITY_UNITS, (u) => ({
                    value: u,
                    text: displayUnit(u),
                  }))}
                  onChange={(e, { value }) => {
                    this.props.setForm({ densityUnit: value })
                    e.code === 'Enter' &&
                      focus('[name=ingredient-dropdown]>input')
                  }}
                />
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Field className='required'>
          <label>Съставки</label>
          <Table
            basic='very'
            compact
            collapsing
            size='small'
            unstackable
            className='recipe-form-ingredients'
          >
            <Table.Body>
              {_.map(ingredients, (ingredient, index) => {
                const {
                  id,
                  resourceId,
                  recipeId,
                  quantity,
                  unit,
                  notes,
                } = ingredient
                const cost = ingredientCost(ingredient, resources, recipes)
                const resourceOrRecipe = resourceId
                  ? resources[resourceId]
                  : recipes[recipeId]
                return (
                  <DraggableRow
                    className='ingredient'
                    key={id}
                    index={index}
                    moveIngredient={this.props.moveIngredient}
                    disabled={id === this.props.form.ingredient.id}
                  >
                    <Table.Cell>
                      <Icon
                        className='show-on-hover'
                        name='trash'
                        link
                        color='grey'
                        onClick={() => this.props.removeIngredient(index)}
                      />
                      <Icon
                        className='show-on-hover'
                        name='edit'
                        link
                        color='grey'
                        onClick={() => this.props.copyIngredient(index)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign='right'>{quantity}</Table.Cell>
                    <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                      {displayUnit(unit)}
                    </Table.Cell>
                    <Table.Cell>{resourceOrRecipe.name}</Table.Cell>
                    <Table.Cell>
                      <em>{notes}</em>
                    </Table.Cell>
                    <Table.Cell textAlign='right' disabled>
                      {`${format('.2f')(cost)} лв.`}
                    </Table.Cell>
                    <Table.Cell textAlign='right' disabled>
                      {`${format('.2f')((cost / totalCost) * 100)} %`}
                    </Table.Cell>
                  </DraggableRow>
                )
              })}
            </Table.Body>

            {!_.isEmpty(ingredients) && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={5} />
                  <Table.HeaderCell>
                    {format('.2f')(totalCost)} лв.
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Form.Field>

        {ingredientCycle ? (
          <Message
            compact
            size='tiny'
            negative
            content={`Не може да се добави заради цикъл: ${ingredientCycle.join(
              ' → '
            )}`}
          />
        ) : null}

        <Form.Group widths='equal'>
          <Form.Select
            name='ingredient-dropdown'
            placeholder='съставка'
            search
            disabled={!!ingredient.id}
            openOnFocus={false}
            noResultsMessage='Няма открити съставки.'
            options={this.getIngredientOptions()}
            value={ingredient.resourceId || ingredient.recipeId + '.r'}
            onChange={(e, { value }) => {
              // prevent re-render on scrolling
              if (!e.code || e.code === 'Enter') {
                const [id, isRecipe] = value.split('.')
                const ingredient = isRecipe ? recipes[id] : resources[id]
                this.props.selectIngredient(
                  id,
                  isRecipe,
                  getValidIngredientUnits(ingredient)[0]
                )
              }
              if (e.code === 'Enter') {
                _.defer(() => focus('#ingredient-quantity-input'))
              }
            }}
          />

          <Form.Field>
            <DecimalInput
              id='ingredient-quantity-input'
              placeholder='количество'
              value={ingredient.quantity}
              onChange={(e, { value }) =>
                this.props.setForm({
                  ingredient: { ...ingredient, quantity: value },
                })
              }
              onKeyPress={({ key }) =>
                key === 'Enter' && clickToFocus('#ingredient-unit-dropdown')
              }
              fluid
              labelPosition='right'
              label={
                <Dropdown
                  id='ingredient-unit-dropdown'
                  placeholder='м. ед.'
                  value={ingredient.unit}
                  disabled={!ingredient.resourceId && !ingredient.recipeId}
                  options={this.getIngredientUnitOptions()}
                  onClose={(e) => {
                    // no event means focusing away w/o selection
                    e && focus('#ingredient-notes-input')
                  }}
                  onChange={(e, { value }) => {
                    this.props.setForm({
                      ingredient: { ...ingredient, unit: value },
                    })
                  }}
                />
              }
            />
          </Form.Field>

          <Form.Input
            id='ingredient-notes-input'
            placeholder='бележки'
            value={ingredient.notes}
            onChange={(e, { value }) =>
              this.props.setForm({
                ingredient: { ...ingredient, notes: value },
              })
            }
            onKeyPress={({ key }) =>
              key === 'Enter' && this.handleAddOrUpdateIngredient()
            }
          />

          <Form.Button
            basic
            type='button'
            icon={ingredient.id ? 'save' : 'add'}
            onClick={this.handleAddOrUpdateIngredient}
            disabled={!this.isValidIngredient()}
          />
        </Form.Group>

        <Form.TextArea
          label='Описание'
          rows={6}
          value={desc}
          onChange={(e, { value }) => this.props.setForm({ desc: value })}
        />

        <Form.Checkbox
          label='Съставка в други рецепти'
          checked={showAsIngredient}
          onChange={() =>
            this.props.setForm({ showAsIngredient: !showAsIngredient })
          }
        />

        <Form.Checkbox
          label='Вторичен продукт'
          checked={!!byProduct}
          onChange={() =>
            this.props.setForm({ byProduct: !byProduct ? {} : null })
          }
        />
        {!!byProduct && (
          <Form.Group>
            <Form.Select
              width={4}
              placeholder='суровина'
              noResultsMessage='Няма открити суровини.'
              search
              openOnFocus={false}
              options={this.getByProductResourceOptions()}
              value={byProduct.resourceId}
              onChange={(e, { value }) => {
                this.props.setForm({
                  byProduct: { ...byProduct, resourceId: value },
                })
              }}
            />

            <Form.Field className='required' width={4}>
              <DecimalInput
                id='byproduct-qty-input'
                placeholder='количество'
                value={byProduct.qty}
                fluid
                labelPosition='right'
                onChange={(e, { value }) =>
                  this.props.setForm({
                    byProduct: { ...byProduct, qty: value },
                  })
                }
                onKeyPress={({ key }) =>
                  key === 'Enter' &&
                  clickToFocus('#byproduct-qty-unit-dropdown')
                }
                label={
                  <Dropdown
                    id='byproduct-qty-unit-dropdown'
                    placeholder='м. ед.'
                    value={byProduct.unit}
                    disabled={!byProduct.resourceId}
                    options={this.getByProductUnitOptions()}
                    onChange={(e, { value }) => {
                      this.props.setForm({
                        byProduct: { ...byProduct, unit: value },
                      })
                    }}
                  />
                }
              />
            </Form.Field>
          </Form.Group>
        )}

        <Message error content={this.props.error} />
        <Divider hidden />
        {this.props.buttonGroup}
      </Form>
    )
  }
}

export default connect(
  (state) => ({
    form: state.recipes.form,
    resources: state.resources.items,
    recipes: state.recipes.items,
  }),
  (dispatch) => bindActionCreators({ ...formActions }, dispatch)
)(RecipeForm)
