export const SYNC_ADD_RECIPE = 'SYNC_ADD_RECIPE'
export const SYNC_UPDATE_RECIPE = 'SYNC_UPDATE_RECIPE'
export const SYNC_REMOVE_RECIPE = 'SYNC_REMOVE_RECIPE'
export const SYNC_UPDATE_RECIPE_ACL = 'SYNC_UPDATE_RECIPE_ACL'

export const SYNC_ADD_RESOURCE = 'SYNC_ADD_RESOURCE'
export const SYNC_UPDATE_RESOURCE = 'SYNC_UPDATE_RESOURCE'
export const SYNC_REMOVE_RESOURCE = 'SYNC_REMOVE_RESOURCE'

export const SYNC_ADD_PRODUCT = 'SYNC_ADD_PRODUCT'
export const SYNC_UPDATE_PRODUCT = 'SYNC_UPDATE_PRODUCT'

export const SYNC_ADD_COMPANY = 'SYNC_ADD_COMPANY'
export const SYNC_UPDATE_COMPANY = 'SYNC_UPDATE_COMPANY'
export const SYNC_REMOVE_COMPANY = 'SYNC_REMOVE_COMPANY'

export const SYNC_ADD_STOCK = 'SYNC_ADD_STOCK'
export const SYNC_UPDATE_STOCK = 'SYNC_UPDATE_STOCK'

// curQty
export const SYNC_STOCK_QTY = 'SYNC_STOCK_QTY'
export const SYNC_PRODUCT_QTY = 'SYNC_PRODUCT_QTY'

export const SYNC_ADD_PRICE_TIER = 'SYNC_ADD_PRICE_TIER'
export const SYNC_UPDATE_PRICE_TIER = 'SYNC_UPDATE_PRICE_TIER'
export const SYNC_REMOVE_PRICE_TIER = 'SYNC_REMOVE_PRICE_TIER'

export const sync = (update) => (dispatch, getState) => {
  if (update.org !== getState().auth.activeOrg) {
    // skip updates for other orgs
    return
  }

  dispatch(update)
}
