import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Header, Form, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'

import {
  update,
  formActions,
  UPDATE_COMPANY_SUCCESS,
} from '../../actions/companies'
import CompanyForm from './CompanyForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class EditCompany extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,

    // form actions
    setForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }

  handleUpdate = () => {
    const {
      form,
      company: { id },
    } = this.props

    if (this.isValidCompany()) {
      this.props.update(id, form).then((action) => {
        if (action.type === UPDATE_COMPANY_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(`/companies/${id}`)
        }
      })
    }
  }

  isValidCompany = () => {
    let { name } = this.props.form
    return _.trim(name)
  }

  componentDidMount() {
    const { company, setForm, form } = this.props
    this.uneditedForm = {
      ...form,
      ..._.mapValues(_.omit(company, 'id', 'createdAt'), (v) =>
        v !== null ? v : ''
      ),
    }
    setForm(this.uneditedForm)
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, this.uneditedForm)

  render() {
    if (!this.props.company) {
      return <Header>Няма такъва фирма.</Header>
    }
    const { company, form } = this.props
    return (
      <div>
        <Helmet title={company.name} />

        <Menu attached='top' inverted color={COLORS.COMPANY} borderless>
          <Menu.Item header icon='edit' content={form.name || company.name} />
        </Menu>

        <Segment attached>
          <CompanyForm
            loading={this.props.isFetching}
            error={this.props.error}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='save'
                  content='Запази'
                  type='button'
                  disabled={!this.isValidCompany() || !this.isDirty()}
                  onClick={this.handleUpdate}
                />
                <Form.Button
                  content='Отказ'
                  type='button'
                  basic
                  onClick={() => {
                    this.props.navigate(`/companies/${company.id}`)
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (state, ownProps) => ({
    company: state.companies.items[ownProps.params.id],
    form: state.companies.form,
    isFetching: state.companies.update.isFetching,
    error: state.companies.update.error,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        update,
        ...formActions,
      },
      dispatch
    )
)(EditCompany))
