import React, { Component, Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HelmetProvider } from 'react-helmet-async'
import { Loader } from 'semantic-ui-react'
import 'moment/locale/bg'

import CoreLayout from './components/CoreLayout'
import { MediaContextProvider } from './MediaContext'

class App extends Component {
  render() {
    return (
      <MediaContextProvider>
        <HelmetProvider>
          <DndProvider backend={HTML5Backend}>
            <Suspense fallback={<Loader active size='large' />}>
              <CoreLayout />
            </Suspense>
          </DndProvider>
        </HelmetProvider>
      </MediaContextProvider>
    )
  }
}

export default App
