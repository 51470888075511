import _ from 'lodash'
import React from 'react'
import { Input } from 'semantic-ui-react'

// replace comma with decimal point
export default ({ onChange, ...props }) => (
  <Input
    {...props}
    onChange={(event, data) => {
      data.value = _.replace(data.value, ',', '.')
      return onChange(event, data)
    }}
  />
)
