import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Form, Segment, Menu } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { unstable_usePrompt as usePrompt, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useBeforeunload } from 'react-beforeunload'

import {
  addProduct,
  setProductForm,
  ADD_PRODUCT_SUCCESS,
  resetProductForm,
} from '../../actions/products'
import ProductForm, { isFormValid } from './ProductForm'
import { INITIAL_STATE } from '../../reducers/products'
import { COLORS } from '../../utils'

const NewProduct = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    form,
    add: { isFetching, error },
  } = useSelector((state) => state.products)
  const dispatch = useDispatch()

  const isSaved = useRef(false)
  const [uneditedForm] = useState(() => {
    const now = moment()
    return {
      ...(location.state || INITIAL_STATE.form),
      producedOnDate: now.format('DD-MM-YYYY'),
      producedOnTime: now.format('HH:mm'),
    }
  })

  // effects
  useEffect(() => {
    dispatch(setProductForm(uneditedForm))

    // unmount cleanup
    return () => {
      dispatch(resetProductForm())
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  const handleAddProduct = () => {
    dispatch(
      addProduct({
        ...form,
        producedOn: moment(
          [form.producedOnDate, form.producedOnTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
      })
    ).then((action) => {
      if (action.type === ADD_PRODUCT_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        navigate(`/products/${action.payload.id}`)
      }
    })
  }

  return (
    <>
      <Helmet title='Нов продукт' />
      <Menu
        attached='top'
        borderless
        inverted
        color={COLORS.PRODUCT}
        style={{ opacity: 0.75 }}
      >
        <Menu.Item header content='Нов продукт' />
      </Menu>

      <Segment attached>
        <ProductForm
          loading={isFetching}
          error={error}
          buttonGroup={
            <Form.Group widths='equal'>
              <Form.Button
                fluid
                disabled={!isFormValid(form)}
                loading={isFetching}
                positive
                content='Създай продукт'
                icon='save'
                onClick={handleAddProduct}
              />
              <Form.Button
                fluid
                content='Отмени промени'
                icon='undo'
                disabled={!isDirty()}
                negative
                onClick={() => {
                  window.confirm('Отмени промени?') &&
                    dispatch(setProductForm(uneditedForm))
                }}
              />
            </Form.Group>
          }
        />
      </Segment>
    </>
  )
}

export default NewProduct
