import _ from 'lodash'

import {
  ADD_RECIPE_REQUEST,
  ADD_RECIPE_SUCCESS,
  ADD_RECIPE_FAILURE,
  UPDATE_RECIPE_REQUEST,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAILURE,
  UPDATE_RECIPE_ACL_REQUEST,
  UPDATE_RECIPE_ACL_SUCCESS,
  UPDATE_RECIPE_ACL_FAILURE,
  REMOVE_RECIPE_REQUEST,
  REMOVE_RECIPE_FAILURE,
  REMOVE_RECIPE_SUCCESS,
  LOAD_RECIPES_REQUEST,
  LOAD_RECIPES_SUCCESS,
  LOAD_RECIPES_FAILURE,
  formTypes,
  SET_RECIPE_FILTER,
  RESET_PREP,
  EXPAND_ALL,
  SET_PREP_DOSE,
  TOGGLE_PREP_CHECK,
  TOGGLE_PREP_EXPAND,
} from '../actions/recipes'

import {
  SYNC_ADD_RECIPE,
  SYNC_UPDATE_RECIPE,
  SYNC_REMOVE_RECIPE,
  SYNC_UPDATE_RECIPE_ACL,
} from '../actions/sync'
import { convertUnits } from '../common/unit-utils'

export const INITIAL_STATE = {
  add: {
    isFetching: false,
    error: null,
  },
  update: {
    isFetching: false,
    error: null,
  },
  updateACL: {
    isFetching: false,
    error: null,
  },
  remove: {
    isFetching: false,
    error: null,
  },
  load: {
    isFetching: false,
    error: null,
  },
  form: {
    name: '',
    desc: '',
    quantity: '',
    unit: '',
    density: '',
    densityUnit: '',
    salePrice: '',
    duration: '',
    byProduct: null, // { resourceId, qty, unit }
    ingredients: [],
    ingredient: {
      resourceId: null,
      recipeId: null,
      quantity: '',
      unit: '',
      notes: '',
    },
    showAsIngredient: false,
    ingredientCycle: null,
  },
  filter: {
    offset: 0,
    limit: 20,
    query: '',
    // role access
    acl: null,
  },
  // all loaded recipes keyed by id
  items: {},
  // recipe preparations: dose, expanded ingredient lists...
  preps: {
    // idA: {
    //   dose: 1,
    //   ingredients: [
    //     {recipeId, quantity, unit, notes, levels: []},
    //     {resoucedId, quantity, unit, notes, levels: [recipeId]},
    //     ...
    //   ]
    // },
    // ...
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_RECIPE_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_RECIPE_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_RECIPE_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_RECIPE_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_RECIPE_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_RECIPE_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_RECIPE_ACL_REQUEST:
      return {
        ...state,
        updateACL: {
          isFetching: true,
          acl: action.acl,
          error: null,
        },
      }
    case UPDATE_RECIPE_ACL_FAILURE:
      return {
        ...state,
        updateACL: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_RECIPE_ACL_SUCCESS:
      return {
        ...state,
        updateACL: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          ..._.keyBy(action.payload, 'id'),
        },
      }

    case REMOVE_RECIPE_REQUEST:
      return {
        ...state,
        remove: {
          isFetching: true,
          error: null,
          id: action.recipeId,
        },
      }
    case REMOVE_RECIPE_FAILURE:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: action.error,
        },
      }
    case REMOVE_RECIPE_SUCCESS:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: null,
        },
        items: _.omit(state.items, state.remove.id),
      }

    case LOAD_RECIPES_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_RECIPES_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_RECIPES_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    // form actions
    case formTypes.RECIPE_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }
    case formTypes.RECIPE_FORM_RESET:
      return {
        ...state,
        form: INITIAL_STATE.form,
      }
    case formTypes.RECIPE_FORM_ADD_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredients: [
            ...state.form.ingredients,
            { ...state.form.ingredient, id: _.uniqueId() },
          ],
          ingredient: INITIAL_STATE.form.ingredient,
        },
      }
    case formTypes.RECIPE_FORM_MOVE_INGREDIENT:
      const { fromIndex, toIndex } = action
      const moved = state.form.ingredients.slice()
      moved.splice(toIndex, 0, moved.splice(fromIndex, 1)[0])

      return {
        ...state,
        form: {
          ...state.form,
          ingredients: moved,
        },
      }
    case formTypes.RECIPE_FORM_REMOVE_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredients: _.filter(
            state.form.ingredients,
            (v, i) => i !== action.index
          ),
        },
      }
    case formTypes.RECIPE_FORM_COPY_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredient: state.form.ingredients[action.index],
        },
      }
    case formTypes.RECIPE_FORM_UPDATE_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredients: _.map(state.form.ingredients, (ingredient) => {
            if (ingredient.id === state.form.ingredient.id) {
              return state.form.ingredient
            }
            return ingredient
          }),
          ingredient: INITIAL_STATE.form.ingredient,
        },
      }
    case formTypes.RECIPE_FORM_SELECT_INGREDIENT:
      return {
        ...state,
        form: {
          ...state.form,
          ingredient: {
            ...state.form.ingredient,
            resourceId: action.resourceId,
            recipeId: action.recipeId,
            unit: action.unit,
          },
          ingredientCycle: null,
        },
      }

    // filter
    case SET_RECIPE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      }

    // preparation
    case RESET_PREP:
      return {
        ...state,
        preps: {
          ...state.preps,
          [action.id]: {
            dose: 1,
            ingredients: _.map(state.items[action.id].ingredients, (i) => ({
              ...i,
              levels: [],
            })),
          },
        },
      }
    case SET_PREP_DOSE:
      return {
        ...state,
        preps: {
          ...state.preps,
          [action.id]: {
            ...state.preps[action.id],
            dose: action.dose,
          },
        },
      }
    case TOGGLE_PREP_CHECK:
      var { ingredients } = state.preps[action.id]
      const target = ingredients[action.index]
      return {
        ...state,
        preps: {
          ...state.preps,
          [action.id]: {
            ...state.preps[action.id],
            ingredients: [
              ..._.slice(ingredients, 0, action.index),
              { ...target, checked: !target.checked },
              ..._.slice(ingredients, action.index + 1),
            ],
          },
        },
      }
    case TOGGLE_PREP_EXPAND:
      ingredients = state.preps[action.id].ingredients
      const toggleTarget = ingredients[action.index]
      const { recipeId, expanded, levels, quantity, unit } = toggleTarget
      if (!recipeId) {
        return state
      }
      const targetRx = state.items[recipeId]

      // quantity and unit convertion
      const unitRatio = convertUnits(
        unit,
        targetRx.unit,
        targetRx.density,
        targetRx.densityUnit
      )
      const quantityRatio = (quantity * unitRatio) / targetRx.quantity

      return {
        ...state,
        preps: {
          ...state.preps,
          [action.id]: {
            ...state.preps[action.id],
            ingredients: !expanded
              ? [
                  ..._.slice(ingredients, 0, action.index),

                  { ...toggleTarget, expanded: true },
                  // expanded ingredients
                  ..._.map(targetRx.ingredients, (i) => ({
                    ...i,
                    quantity: i.quantity * quantityRatio,
                    levels: [...levels, recipeId],
                  })),

                  ..._.slice(ingredients, action.index + 1),
                ]
              : [
                  ..._.slice(ingredients, 0, action.index),
                  { ...toggleTarget, expanded: false },
                  ..._.filter(
                    _.slice(ingredients, action.index + 1),
                    ({ levels }) => !_.includes(levels, recipeId)
                  ),
                ],
          },
        },
      }

    case EXPAND_ALL:
      return {
        ...state,
        preps: {
          ...state.preps,
          [action.id]: {
            ...state.preps[action.id],
            ingredients: expandAll(state, state.items[action.id]),
          },
        },
      }

    // sync
    case SYNC_ADD_RECIPE:
    case SYNC_UPDATE_RECIPE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    case SYNC_REMOVE_RECIPE:
      return {
        ...state,
        items: _.omit(state.items, action.payload.id),
      }
    case SYNC_UPDATE_RECIPE_ACL:
      return {
        ...state,
        items: {
          ...state.items,
          ..._.keyBy(action.payload.recipes, 'id'),
        },
      }

    default:
      return state
  }
}

function expandAll(state, { ingredients, qtyRatio = 1, levels = [] }) {
  return _.flattenDeep(
    _.map(ingredients, (i) => {
      const { recipeId } = i
      if (!recipeId) {
        return { ...i, quantity: i.quantity * qtyRatio, levels }
      } else {
        const recipe = state.items[recipeId]

        // quantity and unit convertion
        const unitRatio = convertUnits(
          i.unit,
          recipe.unit,
          recipe.density,
          recipe.densityUnit
        )

        return [
          { ...i, quantity: i.quantity * qtyRatio, levels, expanded: true },
          ...expandAll(state, {
            ingredients: recipe.ingredients,
            qtyRatio: (qtyRatio * i.quantity * unitRatio) / recipe.quantity,
            levels: [...levels, recipeId],
          }),
        ]
      }
    })
  )
}
