import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import {
  Menu,
  Header,
  Table,
  Segment,
  Grid,
  Label,
  Loader,
} from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'd3-format'
import copy from 'copy-text-to-clipboard'

import { displayUnit } from '../../common/unit-utils'
import { getLabel, getLabelsCopy, COLORS } from '../../utils'
import { queryStocks } from '../../actions/stocks'

const StockDelivery = () => {
  const dispatch = useDispatch()
  const { deliveryId } = useParams()
  const { listing: delivery, status } = useSelector(state => state.stocks.query)
  const resources = useSelector(state => state.resources.items)
  const companies = useSelector(state => state.companies.items)

  useEffect(() => {
    dispatch(queryStocks({ delivery: deliveryId }))
  }, [dispatch, deliveryId])

  if (status.isFetching) {
    return <Loader active />
  }

  if (_.isEmpty(delivery)) {
    return <Header>Доставката не е открита.</Header>
  }

  let { supplier, arrival, receipt } = delivery[0]
  supplier = companies[supplier]

  const title = `Доставка - ${supplier.name}`

  return (
    <div>
      <Helmet title={title} />

      <Menu attached='top' inverted color={COLORS.STOCK} borderless>
        <Menu.Item header content={title} />
        <Menu.Menu position='right'>
          <Menu.Item
            icon='copy'
            title='Копирай етикети'
            onClick={() => copy(getLabelsCopy(delivery, resources))}
          />
          <Menu.Item
            icon='edit'
            as={Link}
            to={`/stocks/delivery/${deliveryId}/edit`}
          />
        </Menu.Menu>
      </Menu>

      <Segment attached>
        <Grid columns={3} doubling>
          <Grid.Row>
            <Grid.Column>
              <Header
                content={
                  <Link to={`/companies/${supplier.id}`}>
                    {supplier.name}
                  </Link>
                }
                subheader='доставчик'
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={moment(arrival).format('DD/MM/YY HH:mm')}
                subheader='пристигане'
              />
            </Grid.Column>

            <Grid.Column>
              <Header content={receipt || '~'} subheader='документ' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <div className='y-scrollable'>
        <Table attached compact unstackable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>№</Table.HeaderCell>
              <Table.HeaderCell>суровина</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>
                количество
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
              <Table.HeaderCell>партиден №</Table.HeaderCell>
              <Table.HeaderCell>производител</Table.HeaderCell>
              <Table.HeaderCell>произход</Table.HeaderCell>
              <Table.HeaderCell>годност</Table.HeaderCell>
              <Table.HeaderCell>съхранение</Table.HeaderCell>
              <Table.HeaderCell>био серт.</Table.HeaderCell>
              <Table.HeaderCell>описание</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(delivery, (item, index) => {
              const resource = resources[item.resource]
              const isExpired =
                item.curQty > 0 &&
                item.expiry &&
                moment(item.expiry).isBefore()

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Label
                      color={COLORS.STOCK}
                      style={{ opacity: item.curQty > 0 ? 1 : 0.5 }}
                      as={Link}
                      to={`/stocks/${item.id}`}
                      tag
                      content={getLabel(item)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/resources/${resource.id}`}>
                      {resource.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {item.batches} x {item.quantity}
                  </Table.Cell>
                  <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                    {displayUnit(item.unit)}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>{`${format('.2f')(
                    item.price
                  )} лв.`}</Table.Cell>
                  <Table.Cell>{item.batchId}</Table.Cell>
                  <Table.Cell>{item.producer}</Table.Cell>
                  <Table.Cell>{item.origin}</Table.Cell>
                  <Table.Cell negative={isExpired}>
                    {item.expiry && moment(item.expiry).format('DD/MM/YY')}
                  </Table.Cell>
                  <Table.Cell>{item.storage}</Table.Cell>
                  <Table.Cell>{item.bioCert}</Table.Cell>
                  <Table.Cell>{item.desc}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.HeaderCell textAlign='right'>
                {`${format('.2f')(
                  _.sumBy(delivery, (i) => parseFloat(i.price))
                )} лв.`}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  )
}

export default StockDelivery
