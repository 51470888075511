import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import { Segment, Menu, Button } from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

import { COLORS } from '../../utils'
import RevisionsForm from './RevisionsForm'
import { INITIAL_STATE } from '../../reducers/revisions'
import {
  setRevisionsForm,
  resetRevisionsForm,
  ADD_REVISIONS_SUCCESS,
  addRevisions,
} from '../../actions/revisions'

export default () => {
  const navigate = useNavigate()
  const {
    form,
    add: { isFetching, error },
  } = useSelector((state) => state.revisions)
  const dispatch = useDispatch()

  const isSaved = useRef(false)
  const [uneditedForm] = useState(() => {
    const now = moment()
    return {
      ...INITIAL_STATE.form,
      revisedAtDate: now.format('DD-MM-YYYY'),
      revisedAtTime: now.format('HH:mm'),
    }
  })

  // effects
  useEffect(() => {
    dispatch(setRevisionsForm(uneditedForm))

    // unmount cleanup
    return () => {
      dispatch(resetRevisionsForm())
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  const handleAddRevisions = () => {
    dispatch(
      addRevisions({
        // prepend common notes to each revision
        items: !form.notes
          ? form.items
          : _.map(form.items, (item) => ({
            ...item,
            notes: form.notes + (item.notes ? ` (${item.notes})` : ''),
          })),
        revisedAt: moment(
          [form.revisedAtDate, form.revisedAtTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
      })
    ).then((action) => {
      if (action.type === ADD_REVISIONS_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        navigate(`/revisions`)
      }
    })
  }

  return (
    <>
      <Helmet title='Нова ревизия - Pantry' />
      <Menu
        attached='top'
        borderless
        inverted
        color={COLORS.REVISION}
        style={{ opacity: 0.75 }}
      >
        <Menu.Item header icon='file outline' content='Нова ревизия' />
      </Menu>

      <Segment attached>
        <RevisionsForm
          loading={isFetching}
          error={error}
          buttonGroup={
            <div>
              <Button
                positive
                disabled={!isFormValid(form)}
                loading={isFetching}
                content='Съхрани'
                icon='save'
                onClick={handleAddRevisions}
              />
              <Button
                content='Изчисти'
                icon='refresh'
                disabled={!isDirty()}
                negative
                onClick={() => {
                  window.confirm('Изчисти?') &&
                    dispatch(setRevisionsForm(uneditedForm))
                }}
              />
            </div>
          }
        />
      </Segment>
    </>
  )
}

const isFormValid = (form) => {
  return (
    !_.isEmpty(form.items) &&
    moment(form.revisedAtDate, 'D-M-YYYY').isValid() &&
    moment(form.revisedAtTime, 'H:m').isValid()
  )
}
