import _ from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Container,
  Divider,
  Grid,
  GridColumn,
  Header,
  Message,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { formatLocale } from 'd3-format'
import numToWordsBg from 'nums2words-bg'

import { querySales } from '../../actions/sales'
import { displayUnit } from '../../common/unit-utils'
import { isVATActive, VATRate, withoutVAT, extractVAT } from '../../common/misc'
import './SaleReceiptPrint.scss'

const { format: fmtDecimal } = formatLocale({ decimal: ',', nan: '' })
const fmt = fmtDecimal('.2f')

export default () => {
  const dispatch = useDispatch()
  const { receiptId } = useParams()
  const [query] = useSearchParams()

  const {
    query: {
      listing,
      status: { isFetching, error },
    },
  } = useSelector((state) => state.sales)

  useEffect(() => {
    dispatch(querySales({ receiptId }))
  }, [dispatch, receiptId])

  if (!isFetching && (error || _.isEmpty(listing))) {
    return <Message negative content={error || 'Receipt id not found.'} />
  }

  if (isFetching) {
    return renderLoading()
  }

  return renderSaleReceipt(listing, query.has('copy'))
}

const renderSaleReceipt = (listing, isCopy) => {
  const sale = listing[0]
  const { invoice, buyer, soldOn } = sale
  const receiptDate = new Date(soldOn)
  const [, invoiceId, invoiceDate] = invoice?.match(/(\d+)\s*\/\s*(\S+)/) || []
  const total = _.round(_.sumBy(listing, 'price'), 2)
  const totalVat = extractVAT(total)
  const hasVAT = isVATActive(receiptDate)

  const displayPaymentMethod = (paymentMethod) =>
    ({
      'в брой': 'в брой',
      'наложен платеж': 'в брой',
      'по сметка': 'по сметка',
    }[paymentMethod])

  const paymentTerm = sale.paymentMethod === 'по сметка' ? 'до 7 дни' : ''

  const groupedByNameAndUnitPrice = _.values(
    _.groupBy(listing, ({ name, unitPrice }) => [name, unitPrice])
  )

  return (
    <>
      <Helmet title={`Фактура ${invoice}`} />
      <Container className='print-receipt'>
        <Segment basic textAlign='center'>
          <Header as='h1'>Фактура</Header>
          <Header as='h3' style={{ marginTop: 0 }}>
            № {invoiceId.padStart(10, '0')} / {invoiceDate}
          </Header>
          <Header as='h3'>
            <i>{isCopy ? 'Копие' : 'Oригинал'}</i>
          </Header>
        </Segment>

        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Table compact>
                <Table.Body>
                  {_.map(
                    [
                      ['Получател:', buyer.companyName],
                      ['Адрес:', buyer.location],
                      ['МОЛ:', buyer.manager],
                      ['Идент. №:', buyer.bulstat],
                      ['ДДС №:', buyer.taxNumber],
                    ],
                    ([label, value], index) => (
                      <Table.Row key={index}>
                        <Table.Cell textAlign='right'>
                          <i>{label}</i>
                        </Table.Cell>
                        <Table.Cell textAlign='left'>{value}</Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column>
              <Table compact>
                <Table.Body>
                  {_.map(
                    [
                      ['Доставчик:', 'Еливал ООД'],
                      ['Адрес:', 'гр. Варна, ул. Иларион Макариополски 10'],
                      ['МОЛ:', 'Валентин Костадинов'],
                      ['Идент. №:', '205027462'],
                      ['ДДС. №:', hasVAT && 'BG205027462'],
                      ['Банка:', 'Първа инвестиционна банка АД'],
                      ['BIC:', 'FINVBGSF'],
                      ['IBAN:', 'BG22 FINV 9150 1017 1575 41'],
                    ],
                    ([label, value], index) => (
                      <Table.Row key={index}>
                        <Table.Cell textAlign='right'>
                          <i>{label}</i>
                        </Table.Cell>
                        <Table.Cell textAlign='left'>{value}</Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Table compact celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>№</Table.HeaderCell>
              <Table.HeaderCell>
                Наименование на стоката / услугата
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Мярка</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Количество</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>Ед. цена</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>Стойност</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(groupedByNameAndUnitPrice, (saleGroup, index) => {
              const sale = saleGroup[0]
              const totalGroupPrice = _.sumBy(saleGroup, 'price')
              const totalGroupQty = _.sumBy(saleGroup, 'qty')
              return (
                <Table.Row key={index}>
                  <Table.Cell textAlign='right'>{index + 1}</Table.Cell>
                  <Table.Cell>{sale.name}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    {displayUnit(sale.unit)}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>{totalGroupQty}</Table.Cell>
                  <Table.Cell textAlign='right'>
                    {fmt(hasVAT ? withoutVAT(sale.unitPrice) : sale.unitPrice)}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {fmt(
                      hasVAT ? withoutVAT(totalGroupPrice) : totalGroupPrice
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Grid>
          <Grid.Row columns={2}>
            <GridColumn verticalAlign='middle'>
              <p>
                Основание за неначисляване на ДДС или нулева ставка:
                {!hasVAT && <strong> чл. 113, ал. 9 от ЗДДС</strong>}
              </p>
            </GridColumn>
            <GridColumn>
              <Table
                compact
                textAlign='right'
                collapsing
                style={{ marginLeft: 'auto' }}
              >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Данъчна основа:</Table.Cell>
                    <Table.Cell>
                      {fmt(hasVAT ? total - totalVat : total)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      ДДС ставка {hasVAT && VATRate * 100}%:
                    </Table.Cell>
                    <Table.Cell>{hasVAT && fmt(totalVat)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <b>Сума за плащане:</b>
                    </Table.Cell>
                    <Table.Cell>
                      <b>{fmt(total)}</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </GridColumn>
          </Grid.Row>
        </Grid>

        <div>Словом: {numToWordsBg.currency(total)}</div>

        <Divider hidden />

        <Segment style={{ padding: 0 }}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Table compact basic='very'>
                  <Table.Body>
                    {_.map(
                      [
                        ['Дата на данъчното събитие:', invoiceDate],
                        ['Срок за плащане:', paymentTerm],
                        ['Място на издаване:', 'гр. Варна'],
                      ],
                      ([label, value], index) => (
                        <Table.Row key={index}>
                          <Table.Cell textAlign='right'>{label}</Table.Cell>
                          <Table.Cell textAlign='left'>{value}</Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact basic='very'>
                  <Table.Body>
                    {_.map(
                      [
                        [
                          'Начин на плащане:',
                          displayPaymentMethod(sale.paymentMethod),
                        ],
                      ],
                      ([label, value], index) => (
                        <Table.Row key={index}>
                          <Table.Cell textAlign='right'>{label}</Table.Cell>
                          <Table.Cell textAlign='left'>
                            <b>{value}</b>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Divider hidden />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column textAlign='center'>
              <Divider hidden />
              <Divider fitted />
              <i>Получил:</i> {buyer.manager}
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Divider hidden />
              <Divider fitted />
              <i>Съставил:</i> Елена Костадинова
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />

        <small>
          Съгласно чл. 6, ал. 1 от Закона за счетоводството, чл. 114 от ЗДДС и
          чл. 78 от ППЗДДС печатът и подписът не са задължителни реквизити във
          фактурата.
        </small>

        <Divider hidden />
      </Container>
    </>
  )
}

const renderLoading = () => (
  <Container>
    <Placeholder fluid>
      {_.times(10, (index) => (
        <Placeholder.Line key={index} />
      ))}
    </Placeholder>
  </Container>
)
