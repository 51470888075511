import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Form,
  Header,
  Dropdown,
  Button,
  Icon,
  Grid,
  Divider,
  Message,
  Select,
  Segment,
  Input,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'd3-format'
import { Link } from 'react-router-dom'
import moment from 'moment'
import validator from 'validator'
import DecimalInput from '../common/DecimalInput'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react'

import RoleFilter from '../common/RoleFilter'
import {
  setProductForm,
  setProductFormIngredient,
  setProductFormByProduct,
  addProductFormIngredient,
  removeProductFormIngredient,
} from '../../actions/products'
import {
  BLANK_INGREDIENT_STOCK,
  BLANK_INGREDIENT_PRODUCT,
} from '../../reducers/products'
import {
  getLabel,
  getProductLabel,
  clickToFocus,
  displayProductCost,
  COLORS,
  focus,
} from '../../utils'
import { getStockTotals } from '../../stock-utils'
import {
  displayUnit,
  getValidIngredientUnits,
  QUANTITY_UNITS,
  productIngredientCost,
  productCost,
  getSalePriceUnit,
} from '../../common/unit-utils'
import './ProductForm.scss'

export default ({ editProductId, ...props }) => {
  const resources = useSelector((state) => state.resources.items)
  const recipes = useSelector((state) => state.recipes.items)
  const stocks = useSelector((state) => state.stocks.items)
  const products = useSelector((state) => state.products.items)
  const stockTotals = useSelector(getStockTotals)
  const { form } = useSelector((state) => state.products)
  const dispatch = useDispatch()

  // index of ingredient open for edit
  const [editIndex, setEditIndex] = useState({
    one: null, // recipe|resource
    two: null, // sproduct|stock
  })

  const handleSelectResource = (index, resourceId) => {
    dispatch(
      setProductFormIngredient(index, {
        resourceId,
        stockId: null,
        unit: _.first(getValidIngredientUnits(resources[resourceId])),
      })
    )
  }

  const handleSelectRecipe = (index, recipeId) => {
    dispatch(
      setProductFormIngredient(index, {
        recipeId,
        productId: null,
      })
    )
  }

  const handleSelectStock = (index, stockId) => {
    dispatch(setProductFormIngredient(index, { stockId }))
  }

  const handleSelectProduct = (index, productId) => {
    dispatch(
      setProductFormIngredient(index, {
        productId,
        unit: null,
      })
    )
  }

  const totalCost = productCost(form.ingredients, resources, stocks, products)
  const getByProductCost = (costPct, totalCost) => (costPct * totalCost) / 100
  const byProductCost = getByProductCost(
    _.get(form.byProduct, 'costPct'),
    totalCost
  )

  // update by-product cost on changes
  useEffect(() => {
    if (!form.byProduct) {
      return
    }
    dispatch(setProductFormByProduct({ cost: byProductCost }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byProductCost])

  return (
    <Form loading={props.loading}>
      <Grid doubling>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Form.Input
              value={form.name}
              label='Наименование'
              className='required'
              onChange={(e, { value }) =>
                dispatch(setProductForm({ name: value }))
              }
            />
          </Grid.Column>

          <Grid.Column computer={4} mobile={8}>
            <Form.Field required>
              <label>Дата</label>
              <DateInput
                closable
                placeholder='дата'
                name='producedOnDate'
                value={form.producedOnDate}
                onChange={(e, { value }) =>
                  dispatch(setProductForm({ producedOnDate: value }))
                }
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column computer={4} mobile={8}>
            <Form.Field required>
              <label>Час</label>
              <TimeInput
                closable
                popupPosition='bottom right'
                placeholder='час'
                name='producedOnTime'
                value={form.producedOnTime}
                onChange={(e, { value }) =>
                  dispatch(setProductForm({ producedOnTime: value }))
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Form.Field>
              <label>Рецепта</label>
              <RecipeSelect
                value={form.recipeId}
                recipes={recipes}
                onChange={(e, { value }) => {
                  // '' must default to null (clearable sets to '')
                  dispatch(setProductForm({ recipeId: value || null }))
                }}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column computer={4} mobile={8}>
            <Form.Field>
              <label>Продажна цена</label>
              <DecimalInput
                id='sale-price'
                fluid
                placeholder='продажна цена'
                label={`лв./${displayUnit(getSalePriceUnit(form.unit)) || 'м.ед.'}`}
                labelPosition='right'
                value={form.salePrice}
                onChange={(e, { value }) =>
                  dispatch(setProductForm({ salePrice: _.trim(value) }))
                }
                onKeyPress={({ key }) => key === 'Enter' && focus('#duration')}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column computer={4} mobile={8}>
            <Form.Field>
              <label>Трайност</label>
              <Input
                fluid
                id='duration'
                type='number'
                min={1}
                placeholder='трайност'
                label='дни'
                labelPosition='right'
                value={form.duration}
                onChange={(e, { value }) =>
                  dispatch(setProductForm({ duration: value }))
                }
                onKeyPress={({ key }) =>
                  key === 'Enter' && focus('#quantity-input')
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Form.Field className='required'>
              <label>Количество</label>
              <DecimalInput
                id='quantity-input'
                placeholder='количество'
                value={form.qty}
                fluid
                labelPosition='right'
                onChange={(e, { value }) =>
                  dispatch(setProductForm({ qty: value }))
                }
                label={
                  <Dropdown
                    id='quantity-unit-dropdown'
                    placeholder='м. ед.'
                    value={form.unit}
                    options={_.map(QUANTITY_UNITS, (u) => ({
                      value: u,
                      text: displayUnit(u),
                    }))}
                    onChange={(e, { value }) => {
                      dispatch(setProductForm({ unit: value }))
                    }}
                  />
                }
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column computer={8} mobile={16}>
            <Form.TextArea
              rows={5}
              value={form.desc}
              label='Описание'
              onChange={(e, { value }) =>
                dispatch(setProductForm({ desc: value }))
              }
            />
          </Grid.Column>
        </Grid.Row>

        {/* byProduct */}
        <Grid.Row>
          <Grid.Column>
            <Form.Checkbox
              label='Вторичен продукт'
              toggle
              disabled={props.byProductDisabled}
              checked={!!form.byProduct}
              onClick={() => {
                dispatch(
                  setProductForm({
                    byProduct: !form.byProduct
                      ? { costPct: 15, qty: '' }
                      : null,
                  })
                )
              }}
            />
          </Grid.Column>
        </Grid.Row>

        {form.byProduct && (
          <Grid.Row as={Segment} basic secondary>
            <Grid.Column computer={4} mobile={8}>
              <Form.Field>
                <label>Суровина</label>
                <ResourceSelect
                  fluid
                  disabled={props.byProductDisabled}
                  resources={resources}
                  value={form.byProduct.resourceId}
                  onChange={(e, { value }) => {
                    dispatch(setProductFormByProduct({ resourceId: value }))
                  }}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column computer={4} mobile={8}>
              <Form.Field disabled={props.byProductDisabled}>
                <label>Количество</label>
                <DecimalInput
                  fluid
                  placeholder='количество'
                  value={form.byProduct.qty}
                  labelPosition='right'
                  onChange={(e, { value }) =>
                    dispatch(setProductFormByProduct({ qty: value }))
                  }
                  onKeyPress={({ key }) =>
                    key === 'Enter' &&
                    clickToFocus('#byproduct-qty-unit-dropdown')
                  }
                  label={
                    <Dropdown
                      id='byproduct-qty-unit-dropdown'
                      placeholder='м. ед.'
                      value={form.byProduct.unit}
                      disabled={!form.byProduct.resourceId}
                      options={getUnitOptions(
                        form.byProduct.resourceId,
                        resources
                      )}
                      onChange={(e, { value }) => {
                        dispatch(setProductFormByProduct({ unit: value }))
                      }}
                    />
                  }
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column computer={4} mobile={8}>
              <Form.Input
                type='number'
                step={5}
                disabled={props.byProductDisabled}
                min={0}
                label='Цена %'
                max={100}
                value={form.byProduct.costPct}
                onChange={(e, { value }) => {
                  dispatch(setProductFormByProduct({ costPct: value }))
                }}
              />
            </Grid.Column>

            <Grid.Column computer={4} mobile={8}>
              <RoleFilter>
                <Form.Field>
                  <label>Цена</label>
                  <p style={{ padding: 9 }}>
                    {displayProductCost(form.byProduct.cost, ' лв.')}
                  </p>
                </Form.Field>
              </RoleFilter>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      <Divider hidden />

      <Form.Field className='required'>
        <label>Съставки</label>
      </Form.Field>
      <Grid className='product-form-ingredients'>
        {_.map(form.ingredients, (ingredient, index) => {
          const { resourceId, stockId, recipeId, productId, qty, unit } =
            ingredient
          const cost = productIngredientCost(
            ingredient,
            resources,
            stocks,
            products
          )

          if (_.has(ingredient, 'stockId')) {
            const resource = resources[resourceId]
            const stock = stocks[stockId]
            const stockLabel = stock
              ? getLabel(stock) + formatStockQty(stock, resource)
              : '<наличност>'
            // render stock ingredient
            return (
              <Grid.Row key={index}>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  {editIndex.one === index ? (
                    <ResourceSelect
                      defaultOpen
                      fluid
                      value={resourceId}
                      resources={resources}
                      stockTotals={stockTotals}
                      onChange={(e, { value }) =>
                        handleSelectResource(index, value)
                      }
                      onBlur={() => setEditIndex({})}
                    />
                  ) : (
                    <Button
                      fluid
                      content={resource ? resource.name : '<суровина>'}
                      onClick={() => setEditIndex({ one: index })}
                    />
                  )}
                </Grid.Column>

                <Grid.Column computer={6} tablet={8} mobile={16}>
                  {editIndex.two === index ? (
                    <StockSelect
                      defaultOpen
                      fluid
                      disabled={!resourceId}
                      resourceId={resourceId}
                      resources={resources}
                      stocks={stocks}
                      value={stockId}
                      onChange={(e, { value }) =>
                        handleSelectStock(index, value)
                      }
                      onBlur={() => setEditIndex({})}
                    />
                  ) : (
                    <Button
                      fluid
                      disabled={!resourceId}
                      color={COLORS.STOCK}
                      content={stockLabel}
                      onClick={() => setEditIndex({ two: index })}
                    />
                  )}
                </Grid.Column>

                <Grid.Column computer={3} mobile={8}>
                  <DecimalInput
                    disabled={!resourceId}
                    placeholder='количество'
                    value={qty}
                    fluid
                    labelPosition='right'
                    onChange={(e, { value }) =>
                      dispatch(setProductFormIngredient(index, { qty: value }))
                    }
                    label={
                      <Dropdown
                        placeholder='м. ед.'
                        value={unit}
                        disabled={!resourceId}
                        options={getUnitOptions(resourceId, resources)}
                        onChange={(e, { value }) => {
                          dispatch(
                            setProductFormIngredient(index, { unit: value })
                          )
                        }}
                      />
                    }
                  />
                </Grid.Column>

                <Grid.Column computer={2} mobile={5}>
                  <Button
                    tabIndex='-1'
                    basic
                    icon='trash'
                    title='Премахни съставка'
                    onClick={() => {
                      window.confirm('Премахни съставка?') &&
                        dispatch(removeProductFormIngredient(index))
                    }}
                  />

                  {resourceId && (
                    <Link
                      to={`/resources/${resourceId}`}
                      tabIndex='-1'
                      target='blank'
                    >
                      <Icon name='external' color={COLORS.RESOURCE} />
                    </Link>
                  )}
                  {stockId && (
                    <Link
                      to={`/stocks/${stockId}`}
                      tabIndex='-1'
                      target='_blank'
                    >
                      <Icon name='external' color={COLORS.STOCK} />
                    </Link>
                  )}
                </Grid.Column>

                <Grid.Column computer={1} mobile={3} textAlign='right'>
                  <RoleFilter>{displayProductCost(cost)}</RoleFilter>
                </Grid.Column>
              </Grid.Row>
            )
          }
          if (_.has(ingredient, 'productId')) {
            // render product ingredient
            const recipe = recipes[recipeId]
            const product = products[productId]
            const productLabel = product
              ? `${getProductLabel(product)}  ${product.name} ${formatProductQty(product)}`
              : `<продукт>`
            return (
              <Grid.Row key={index}>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  {editIndex.one === index ? (
                    <RecipeSelect
                      defaultOpen
                      fluid
                      value={recipeId}
                      recipes={recipes}
                      onChange={(e, { value }) =>
                        handleSelectRecipe(index, value)
                      }
                      onBlur={() => setEditIndex({})}
                    />
                  ) : (
                    <Button
                      fluid
                      content={recipe ? recipe.name : '<рецепта>'}
                      onClick={() => setEditIndex({ one: index })}
                    />
                  )}
                </Grid.Column>

                <Grid.Column computer={6} tablet={8} mobile={16}>
                  {editIndex.two === index ? (
                    <ProductSelect
                      defaultOpen
                      fluid
                      value={productId}
                      recipeId={recipeId}
                      products={products}
                      editProductId={editProductId}
                      onChange={(e, { value }) =>
                        handleSelectProduct(index, value)
                      }
                      onBlur={() => setEditIndex({})}
                    />
                  ) : (
                    <Button
                      fluid
                      color={COLORS.PRODUCT}
                      content={productLabel}
                      onClick={() => setEditIndex({ two: index })}
                    />
                  )}
                </Grid.Column>

                <Grid.Column computer={3} mobile={8}>
                  <DecimalInput
                    disabled={!recipeId && !productId}
                    placeholder='количество'
                    value={qty}
                    fluid
                    labelPosition='right'
                    onChange={(e, { value }) =>
                      dispatch(setProductFormIngredient(index, { qty: value }))
                    }
                    label={
                      <Dropdown
                        placeholder='м. ед.'
                        value={unit}
                        disabled={!recipeId && !productId}
                        options={
                          productId
                            ? getUnitOptions(productId, products)
                            : getUnitOptions(recipeId, recipes)
                        }
                        onChange={(e, { value }) => {
                          dispatch(
                            setProductFormIngredient(index, { unit: value })
                          )
                        }}
                      />
                    }
                  />
                </Grid.Column>

                <Grid.Column computer={2} mobile={5}>
                  <Button
                    tabIndex='-1'
                    basic
                    icon='trash'
                    title='Премахни съставка'
                    onClick={() => {
                      window.confirm('Премахни съставка?') &&
                        dispatch(removeProductFormIngredient(index))
                    }}
                  />

                  {recipeId && (
                    <Link
                      to={`/recipes/${recipeId}`}
                      tabIndex='-1'
                      taget='_blank'
                    >
                      <Icon name='external' color={COLORS.RECIPE} />
                    </Link>
                  )}
                  {productId && (
                    <Link
                      to={`/products/${productId}`}
                      tabIndex='-1'
                      target='_blank'
                    >
                      <Icon name='external' color={COLORS.PRODUCT} />
                    </Link>
                  )}
                </Grid.Column>

                <Grid.Column computer={1} mobile={3} textAlign='right'>
                  <RoleFilter>{displayProductCost(cost)}</RoleFilter>
                </Grid.Column>
              </Grid.Row>
            )
          }
        })}

        <Grid.Row>
          <Grid.Column computer={3} tablet={8} mobile={8}>
            <Form.Button
              labelPosition='left'
              icon='add'
              circular
              fluid
              color={COLORS.STOCK}
              size='small'
              content='Наличност'
              onClick={() =>
                dispatch(addProductFormIngredient(BLANK_INGREDIENT_STOCK))
              }
            />
          </Grid.Column>

          <Grid.Column computer={3} tablet={8} mobile={8}>
            <Form.Button
              labelPosition='left'
              icon='add'
              circular
              fluid
              color={COLORS.PRODUCT}
              size='small'
              content='Продукт'
              onClick={() =>
                dispatch(addProductFormIngredient(BLANK_INGREDIENT_PRODUCT))
              }
            />
          </Grid.Column>

          <Grid.Column computer={10} tablet={16} mobile={16} textAlign='right'>
            <RoleFilter>
              <strong>{displayProductCost(totalCost)}</strong>
            </RoleFilter>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Message negative hidden={!props.error} content={props.error} />

      <Divider section />
      {props.buttonGroup}
    </Form>
  )
}

const isIngredientValid = ({ stockId, productId, qty, unit }) => {
  return (
    (stockId || productId) && validator.isFloat(qty + '', { gt: 0 }) && unit
  )
}

const isByProductValid = (byProduct) => {
  return (
    !byProduct ||
    (byProduct.resourceId &&
      validator.isFloat(byProduct.qty + '', { gt: 0 }) &&
      byProduct.unit &&
      validator.isInt(byProduct.costPct + '', { min: 0, max: 100 }))
  )
}

export const isFormValid = (form) => {
  return (
    !_.isEmpty(form.name) &&
    validator.isFloat(form.qty + '', { gt: 0 }) &&
    form.unit &&
    moment(form.producedOnDate, 'D-M-YYYY').isValid() &&
    moment(form.producedOnTime, 'H:m').isValid() &&
    !_.isEmpty(form.ingredients) &&
    _.every(form.ingredients, isIngredientValid) &&
    isByProductValid(form.byProduct)
  )
}

const getUnitOptions = (id, items) => {
  const item = items[id]
  return _.map(getValidIngredientUnits(item), (u) => ({
    key: u,
    value: u,
    text: displayUnit(u),
  }))
}

const getStockOptions = (resourceId, stocks, resources, query) => {
  return _.chain(stocks)
    .filter({ resource: resourceId })
    .filter(({ curQty, seqId }) => curQty > 0 || seqId === query)
    .sortBy(['arrival', 'seqId'])
    .map((stock) => {
      const label = getLabel(stock)
      const qtyTxt = formatStockQty(stock, resources[resourceId])
      return {
        key: stock.id,
        value: stock.id,
        text: label + qtyTxt,
        content: (
          <Header size='tiny' color={stock.curQty ? COLORS.STOCK : null}>
            <Header.Content>{label}</Header.Content>
            <Header.Subheader>
              {qtyTxt}
              <br />
              <em>{_.truncate(stock.desc)}</em>
            </Header.Subheader>
          </Header>
        ),
      }
    })
    .value()
}

const formatStockQty = ({ curQty, unit }, resource) => {
  const gtn = _.get(resource, 'gtn', 1)
  return (
    ` [${format('.3~f')(curQty)}` +
    (gtn === 1 ? '' : ` / ${format('.3~f')(curQty / gtn)}`) +
    ` ${displayUnit(unit)}]`
  )
}

const StockSelect = React.memo(
  ({ resourceId, stocks, resources, ...props }) => {
    const [query, setQuery] = useState()

    return (
      <Select
        search
        onSearchChange={(e, { searchQuery }) => {
          searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
          setQuery(searchQuery && Number(searchQuery))
        }}
        selectOnBlur={false}
        selectOnNavigation={false}
        placeholder='наличност'
        noResultsMessage='Няма наличност'
        options={getStockOptions(resourceId, stocks, resources, query)}
        {...props}
      />
    )
  },
  (prev, next) =>
    prev.value === next.value &&
    prev.resourceId === next.resourceId &&
    prev.stocks === next.stocks &&
    prev.resources === next.resources
)

const formatProductQty = ({ curQty, unit }) =>
  ` [${format('.3~f')(curQty)} ${displayUnit(unit)}]`

const getProductOptions = (recipeId, editProductId, products, query) => {
  return _.chain(products)
    .filter(
      (product) =>
        (!recipeId || recipeId === product.recipeId) &&
        (product.curQty > 0 || product.seqId === query) &&
        (!editProductId || editProductId !== product.id)
    )
    .sortBy(['producedOn'])
    .map((product) => {
      const label = getProductLabel(product)
      const text = product.name + formatProductQty(product)

      return {
        key: product.id,
        value: product.id,
        text: label + ' ' + text,
        content: (
          <Header size='tiny' color={product.curQty ? COLORS.PRODUCT : null}>
            <Header.Content>{label}</Header.Content>
            <Header.Subheader>
              {text}
              <br />
              <em>{_.truncate(product.desc)}</em>
            </Header.Subheader>
          </Header>
        ),
      }
    })
    .value()
}

const SEQ_ID_PATTERN = /^(\d{1,4})$/i

const ProductSelect = React.memo(
  ({ recipeId, editProductId, products, ...props }) => {
    const [query, setQuery] = useState()

    return (
      <Select
        search
        onSearchChange={(e, { searchQuery }) => {
          searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
          setQuery(searchQuery && Number(searchQuery))
        }}
        selectOnBlur={false}
        selectOnNavigation={false}
        placeholder='продукт'
        noResultsMessage='Няма продукт'
        options={getProductOptions(recipeId, editProductId, products, query)}
        {...props}
      />
    )
  },
  (prev, next) =>
    prev.value === next.value &&
    prev.recipeId === next.recipeId &&
    prev.products === next.products
)

const ResourceSelect = React.memo(
  ({ resources, stockTotals, ...props }) => {
    return (
      <Select
        placeholder='суровина'
        noResultsMessage='Няма открити суровини.'
        search
        selectOnBlur={false}
        selectOnNavigation={false}
        openOnFocus={false}
        options={_.map(resources, ({ id, name, desc }) => {
          const { qty, unit } = _.get(stockTotals, id, {})
          let text = name
          if (stockTotals) {
            text += ` (${format('.3~f')(qty)} ${displayUnit(unit)})`
          }
          return {
            key: id,
            value: id,
            text: name,
            disabled: stockTotals && !qty,
            content: (
              <Header size='tiny'>
                <Header.Content>{text}</Header.Content>
                <Header.Subheader>
                  {_.truncate(desc, { length: 65 })}
                </Header.Subheader>
              </Header>
            ),
          }
        })}
        {...props}
      />
    )
  },
  (prev, next) =>
    prev.value === next.value &&
    prev.resources === next.resources &&
    prev.getStockTotal === next.getStockTotal
)

const RecipeSelect = React.memo(
  ({ recipes, ...props }) => {
    return (
      <Select
        search
        openOnFocus={false}
        placeholder='рецепта'
        clearable
        selectOnBlur={false}
        selectOnNavigation={false}
        noResultsMessage='Няма такава рецепта'
        options={_.map(recipes, (recipe) => {
          const { id, name } = recipe
          return {
            key: id,
            value: id,
            text: name,
          }
        })}
        {...props}
      />
    )
  },
  (prev, next) => prev.value === next.value && prev.recipes === next.recipes
)
