import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Segment, Button, Input, Select } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Fuse from 'fuse.js'

import CompanyListing from './CompanyListing'
import PaginationControls from '../common/PaginationControls'

import { setCompanyFilter } from '../../actions/companies'

const Companies = () => {
  const priceTiers = useSelector((state) => state.priceTiers.items)
  const items = useSelector((state) => state.companies.items)
  const filter = useSelector((state) => state.companies.filter)
  const total = useSelector(getTotal)
  const listing = useSelector(getListing)
  const dispatch = useDispatch()

  if (_.isEmpty(items)) {
    return (
      <Segment basic textAlign='center'>
        <Button
          positive
          icon='add'
          as={Link}
          to='/companies/new'
          content='Добави фирма'
        />
      </Segment>
    )
  }

  return (
    <>
      <Helmet title='Фирми - Pantry' />
      <div className='flexwrap'>
        <div>
          <PaginationControls
            {...{
              ...filter,
              total,
              setFilter: (filter) => dispatch(setCompanyFilter(filter)),
            }}
          />
        </div>
        <Input
          size='small'
          icon='search'
          action={{
            icon: 'remove',
            disabled: _.isEmpty(filter.query),
            onClick: () => dispatch(setCompanyFilter({ query: '', offset: 0 })),
          }}
          iconPosition='left'
          placeholder='Търси фирми'
          value={filter.query}
          onChange={(e, { value }) =>
            dispatch(setCompanyFilter({ query: value, offset: 0 }))
          }
        />
        <Button
          size='small'
          content='всички'
          color={(filter.showAll && 'green') || null}
          onClick={() => {
            dispatch(
              setCompanyFilter({
                showAll: !filter.showAll,
                offset: 0,
              })
            )
          }}
        />
        <Button.Group size='small'>
          <Button
            content='доставчик'
            disabled={filter.showAll}
            color={(filter.showSuppliers && 'green') || null}
            onClick={() =>
              dispatch(
                setCompanyFilter({
                  showSuppliers: !filter.showSuppliers,
                  offset: 0,
                })
              )
            }
          />
          <Button
            content='клиент'
            disabled={filter.showAll}
            color={(filter.showBuyers && 'green') || null}
            onClick={() =>
              dispatch(
                setCompanyFilter({
                  showBuyers: !filter.showBuyers,
                  offset: 0,
                })
              )
            }
          />
        </Button.Group>

        <Select
          placeholder='ценови клас'
          clearable
          value={filter.priceTier || ''}
          options={_.map(priceTiers, ({ id, name }) => ({
            value: id,
            text: name,
          }))}
          onChange={(e, { value }) => {
            dispatch(setCompanyFilter({ priceTier: value ? value : null }))
          }}
        />
      </div>

      <CompanyListing {...{ listing }} />
    </>
  )
}

const getItems = (state) => state.companies.items
const getFilter = (state) => state.companies.filter
const getQuery = (state) => state.companies.filter.query

const getFiltered = createSelector(
  [getItems, getFilter],
  (
    items,
    { showAll, showBuyers, showSuppliers, priceTier: priceTierFilter }
  ) => {
    return _.filter(items, ({ isBuyer, isSupplier, priceTier }) => {
      const supplierBuyerFilterResult =
        showAll ||
        (isSupplier && showSuppliers && !showBuyers) ||
        (isBuyer && showBuyers && !showSuppliers) ||
        (showSuppliers && showBuyers && isBuyer && isSupplier) ||
        (!showSuppliers && !showBuyers && !isBuyer && !isSupplier)

      const priceTierFilterResult =
        !priceTierFilter || priceTier === priceTierFilter

      return supplierBuyerFilterResult && priceTierFilterResult
    })
  }
)

const getFuse = createSelector(
  [getFiltered],
  (items) =>
    new Fuse(_.values(items), {
      shouldSort: true,
      location: 0,
      distance: 100,
      threshold: 0.4,
      minMatchCharLength: 2,
      useExtendedSearch: true,
      keys: [
        'name',
        'companyName',
        'certificate',
        'bulstat',
        'location',
        'contact',
        'notes',
        'email',
        'taxNumber',
        'manager',
      ],
    })
)

const getSearchResults = createSelector(
  [getFiltered, getQuery, getFuse],
  (filtered, query, fuse) => {
    const normalized = _.trim(query)
    if (!_.isEmpty(normalized)) {
      return _.map(fuse.search(normalized), 'item')
    }
    return _.sortBy(filtered, ({ createdAt }) => -new Date(createdAt).getTime())
  }
)

const getListing = createSelector(
  [getSearchResults, getFilter],
  (filtered, { offset, limit }) => _.slice(filtered, offset, offset + limit)
)

const getTotal = createSelector([getSearchResults], (filtered) =>
  _.size(filtered)
)

export default Companies
