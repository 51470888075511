import { api } from '../utils'

// add price tier

export const ADD_PRICE_TIER_REQUEST = 'ADD_PRICE_TIER_REQUEST'
export const ADD_PRICE_TIER_SUCCESS = 'ADD_PRICE_TIER_SUCCESS'
export const ADD_PRICE_TIER_FAILURE = 'ADD_PRICE_TIER_FAILURE'

export const add = (priceTier) => (dispatch, getState) => {
  if (getState().priceTiers.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_PRICE_TIER_REQUEST })

  return api
    .withOrg(getState)('/price-tier', { method: 'POST', body: priceTier })
    .then((res) =>
      dispatch({
        type: ADD_PRICE_TIER_SUCCESS,
        payload: res.priceTier,
      })
    )
    .catch((error) => dispatch({ type: ADD_PRICE_TIER_FAILURE, error }))
}

// update price tier

export const UPDATE_PRICE_TIER_REQUEST = 'UPDATE_PRICE_TIER_REQUEST'
export const UPDATE_PRICE_TIER_SUCCESS = 'UPDATE_PRICE_TIER_SUCCESS'
export const UPDATE_PRICE_TIER_FAILURE = 'UPDATE_PRICE_TIER_FAILURE'

export const update = (id, priceTier) => (dispatch, getState) => {
  if (getState().priceTiers.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_PRICE_TIER_REQUEST })

  return api
    .withOrg(getState)(`/price-tier/${id}`, { method: 'POST', body: priceTier })
    .then((res) =>
      dispatch({
        type: UPDATE_PRICE_TIER_SUCCESS,
        payload: res.priceTier,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_PRICE_TIER_FAILURE, error }))
}

// load price tiers

export const LOAD_PRICE_TIERS_REQUEST = 'LOAD_PRICE_TIERS_REQUEST'
export const LOAD_PRICE_TIERS_SUCCESS = 'LOAD_PRICE_TIERS_SUCCESS'
export const LOAD_PRICE_TIERS_FAILURE = 'LOAD_PRICE_TIERS_FAILURE'

export const loadPriceTiers = () => (dispatch, getState) => {
  const { isFetching } = getState().priceTiers.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_PRICE_TIERS_REQUEST })

  return api
    .withOrg(getState)('/price-tiers')
    .then((res) =>
      dispatch({
        type: LOAD_PRICE_TIERS_SUCCESS,
        payload: res.priceTiers,
      })
    )
    .catch((error) => dispatch({ type: LOAD_PRICE_TIERS_FAILURE, error }))
}

// remove price tier

export const REMOVE_PRICE_TIER_REQUEST = 'REMOVE_PRICE_TIER_REQUEST'
export const REMOVE_PRICE_TIER_SUCCESS = 'REMOVE_PRICE_TIER_SUCCESS'
export const REMOVE_PRICE_TIER_FAILURE = 'REMOVE_PRICE_TIER_FAILURE'

export const removePriceTier = (priceTierId) => (dispatch, getState) => {
  const { isFetching } = getState().priceTiers.remove
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_PRICE_TIER_REQUEST, priceTierId })

  return api
    .withOrg(getState)(`/price-tier/${priceTierId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_PRICE_TIER_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_PRICE_TIER_FAILURE, error }))
}
