import _ from 'lodash'
import React, { useRef } from 'react'
import { Form, Button, Segment, Header, Grid, Message } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { login, LOGIN_SUCCESS } from '../actions/auth'
import { useLocation, useNavigate } from 'react-router'


const Login = () => {
  const { isFetching, error } = useSelector(state => state.auth.login)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usernameRef = useRef()
  const passwordRef = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(login({
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    }))
      .then((action) => {
        if (action.type === LOGIN_SUCCESS) {
          navigate(_.get(location.state, 'from', '/'))
        }
      })
  }

  return (
    <Grid centered>
      <Grid.Column computer={6} tablet={12} mobile={16}>
        <Segment>
          <Header>Вход</Header>

          <Form
            onSubmit={handleSubmit}
            loading={isFetching}
            error={!!error}
          >
            <Form.Field>
              <label>Потребителско име</label>
              <input
                placeholder='Потребителско име'
                ref={usernameRef}
                required
                autoFocus
              />
            </Form.Field>

            <Form.Field>
              <label>Парола</label>
              <input
                placeholder='Парола'
                type='password'
                ref={passwordRef}
                required
              />
            </Form.Field>

            <Message error content={error} />

            <Button fluid positive content='Вход' />
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default Login