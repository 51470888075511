import _ from 'lodash'

export const Role = {
  USER: 'user',
  MANAGER: 'manager',
  ADMIN: 'admin',
}

export const ROLES = _.values(Role)

export const PERMISSION_LEVEL = {
  [Role.USER]: 0,
  [Role.MANAGER]: 10,
  [Role.ADMIN]: 100,
}

export const isValid = (role) => _.includes(ROLES, role)

export const isPermitted = ({ has, requires }) => {
  if (!isValid(has) || !isValid(requires)) {
    return false
  }
  return PERMISSION_LEVEL[has] >= PERMISSION_LEVEL[requires]
}

export const permittedRoles = (hasRole) =>
  _.filter(ROLES, (role) => PERMISSION_LEVEL[hasRole] >= PERMISSION_LEVEL[role])
