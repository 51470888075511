import { api } from '../utils'

// add resource

export const ADD_RESOURCE_REQUEST = 'ADD_RESOURCE_REQUEST'
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS'
export const ADD_RESOURCE_FAILURE = 'ADD_RESOURCE_FAILURE'

export const add = (resource) => (dispatch, getState) => {
  if (getState().resources.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_RESOURCE_REQUEST })

  return api
    .withOrg(getState)('/resource', { method: 'POST', body: resource })
    .then((res) =>
      dispatch({
        type: ADD_RESOURCE_SUCCESS,
        payload: res.resource,
      })
    )
    .catch((error) => dispatch({ type: ADD_RESOURCE_FAILURE, error }))
}

// update resource

export const UPDATE_RESOURCE_REQUEST = 'UPDATE_RESOURCE_REQUEST'
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS'
export const UPDATE_RESOURCE_FAILURE = 'UPDATE_RESOURCE_FAILURE'

export const update = (id, resource) => (dispatch, getState) => {
  if (getState().resources.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_RESOURCE_REQUEST })

  return api
    .withOrg(getState)(`/resource/${id}`, { method: 'POST', body: resource })
    .then((res) =>
      dispatch({
        type: UPDATE_RESOURCE_SUCCESS,
        payload: res.resource,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_RESOURCE_FAILURE, error }))
}

// load resources

export const LOAD_RESOURCES_REQUEST = 'LOAD_RESOURCES_REQUEST'
export const LOAD_RESOURCES_SUCCESS = 'LOAD_RESOURCES_SUCCESS'
export const LOAD_RESOURCES_FAILURE = 'LOAD_RESOURCES_FAILURE'

export const loadResources = () => (dispatch, getState) => {
  const { isFetching } = getState().resources.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_RESOURCES_REQUEST })

  return api
    .withOrg(getState)('/resources')
    .then((res) =>
      dispatch({
        type: LOAD_RESOURCES_SUCCESS,
        payload: res.resources,
      })
    )
    .catch((error) => dispatch({ type: LOAD_RESOURCES_FAILURE, error }))
}

// remove resources

export const REMOVE_RESOURCE_REQUEST = 'REMOVE_RESOURCE_REQUEST'
export const REMOVE_RESOURCE_SUCCESS = 'REMOVE_RESOURCE_SUCCESS'
export const REMOVE_RESOURCE_FAILURE = 'REMOVE_RESOURCE_FAILURE'

export const removeResource = (resourceId) => (dispatch, getState) => {
  const { isFetching } = getState().resources.remove
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_RESOURCE_REQUEST, resourceId })

  return api
    .withOrg(getState)(`/resource/${resourceId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_RESOURCE_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_RESOURCE_FAILURE, error }))
}

// resource form
export const formTypes = {
  RESOURCE_FORM_SET: 'RESOURCE_FORM_SET',
  RESOURCE_FORM_RESET: 'RESOURCE_FORM_RESET',
}

export const formActions = {
  setForm: (form) => ({ type: formTypes.RESOURCE_FORM_SET, form }),
  resetForm: () => ({ type: formTypes.RESOURCE_FORM_RESET }),
}

// filter
export const SET_RESOURCE_FILTER = 'SET_RESOURCE_FILTER'
export const setResourceFilter = (filter) => ({
  type: SET_RESOURCE_FILTER,
  filter,
})
