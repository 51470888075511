import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import { Segment, Input, Button, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'
import { format } from 'd3-format'

import PaginationControls from '../common/PaginationControls'
import ProductListing from './ProductListing'
import { setProductFilter } from '../../actions/products'
import RoleFilter from '../common/RoleFilter'

class Products extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired,

    total: PropTypes.number.isRequired,
    totalCost: PropTypes.number.isRequired,
    listing: PropTypes.array.isRequired,
  }

  render() {
    const { setFilter, filter, isEmpty, total, listing, totalCost } = this.props

    if (isEmpty) {
      return (
        <Segment basic textAlign='center'>
          <Button
            positive
            icon='add'
            content='Добави продукт'
            as={Link}
            to='/products/new'
          />
        </Segment>
      )
    }

    return (
      <>
        <Helmet title='Продукти - Pantry' />
        <div className='flexwrap'>
          <div>
            <PaginationControls {...{ ...filter, total, setFilter }} />
          </div>

          <Input
            size='small'
            icon='search'
            action={{
              icon: 'remove',
              disabled: _.isEmpty(filter.query),
              onClick: () => setFilter({ query: '', offset: 0 }),
            }}
            iconPosition='left'
            placeholder='Търси в продукти'
            value={filter.query}
            onChange={(e, { value }) => setFilter({ query: value, offset: 0 })}
          />

          <DateInput
            clearable
            placeholder='до дата'
            animation=''
            value={filter.date}
            closable
            onChange={(e, { value }) => setFilter({ date: value, offset: 0 })}
          />

          <Button
            content='всички'
            positive={filter.showAll}
            size='small'
            onClick={() =>
              setFilter({
                showAll: !filter.showAll,
                showOverdrawn: false,
                offset: 0,
              })
            }
          />

          <RoleFilter>
            <Button
              icon='exclamation'
              positive={filter.showOverdrawn}
              size='small'
              onClick={() =>
                setFilter({
                  showOverdrawn: !filter.showOverdrawn,
                  showAll: false,
                  offset: 0,
                })
              }
            />

            <Label
              as='span'
              size='large'
              style={{ marginLeft: 'auto', marginRight: '0.5em' }}
              content={format(',.2f')(totalCost) + ' лв.'}
            />
          </RoleFilter>
        </div>

        <ProductListing {...{ listing }} />
      </>
    )
  }
}

const getItems = (state) =>
  _.chain(state.products.items)
    .orderBy(['producedOn', 'seqId'], ['desc', 'desc'])
    .value()

const getQuery = (state) => state.products.filter.query
const getDate = (state) => state.products.filter.date
const getOffset = (state) => state.products.filter.offset
const getLimit = (state) => state.products.filter.limit
const getShowAll = (state) => state.products.filter.showAll
const getShowOverdrawn = (state) => state.products.filter.showOverdrawn

const getFuse = createSelector(
  [getItems],
  (items) =>
    new Fuse(_.values(items), {
      shouldSort: false,
      location: 0,
      distance: 100,
      threshold: 0.3,
      minMatchCharLength: 1,
      useExtendedSearch: true,
      keys: [
        {
          name: 'name',
          weight: 0.8,
        },
        {
          name: 'desc',
          weight: 0.2,
        },
      ],
    })
)

const SEQ_ID_PATTERN = /^(\d{1,4})$/i
const OBJECT_ID_PATTERN = /^([0-9a-fA-F]{24})$/

const getQueryResults = createSelector(
  [getItems, getQuery, getFuse],
  (items, query, fuse) => {
    const normalized = _.trim(query)

    // search by seqId
    const seqIdMatch = normalized.match(SEQ_ID_PATTERN)
    if (seqIdMatch) {
      return _.filter(items, { seqId: Number(seqIdMatch[1]) })
    }

    // search by ObjectId
    const idMatch = normalized.match(OBJECT_ID_PATTERN)
    if (idMatch) {
      return _.filter(items, { id: _.toLower(idMatch[1]) })
    }

    if (!_.isEmpty(normalized)) {
      return _.map(fuse.search(normalized), 'item')
    }
    return items
  }
)

const getUntilDate = createSelector(
  [getQueryResults, getDate],
  (items, date) => {
    date = moment(date, 'DD-MM-YYYY', true)
    if (date.isValid()) {
      return _.filter(items, ({ producedOn }) =>
        moment(producedOn).isSameOrBefore(date, 'day')
      )
    }
    return items
  }
)

const getFiltered = createSelector(
  [getUntilDate, getShowAll, getShowOverdrawn],
  (items, showAll, showOverdrawn) =>
    showOverdrawn
      ? _.filter(items, ({ curQty }) => curQty < 0)
      : showAll
      ? items
      : _.filter(items, ({ curQty }) => curQty > 0)
)

const getListing = createSelector(
  [getFiltered, getOffset, getLimit],
  (filtered, offset, limit) => _.slice(filtered, offset, offset + limit)
)

const getTotal = createSelector([getFiltered], (filtered) => _.size(filtered))

const getTotalCost = createSelector([getFiltered], (filtered) =>
  _.sumBy(
    // skip negative curQty (overdrawn products)
    _.filter(filtered, ({ curQty }) => curQty > 0),
    ({ cost, curQty, qty }) => (cost * curQty) / qty
  )
)

export default connect(
  (state) => ({
    isEmpty: _.isEmpty(state.products.items),
    filter: state.products.filter,
    total: getTotal(state),
    totalCost: getTotalCost(state),
    listing: getListing(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setFilter: setProductFilter,
      },
      dispatch
    )
)(Products)
