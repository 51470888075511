import { api } from '../utils'

// add stock

export const ADD_STOCK_REQUEST = 'ADD_STOCK_REQUEST'
export const ADD_STOCK_SUCCESS = 'ADD_STOCK_SUCCESS'
export const ADD_STOCK_FAILURE = 'ADD_STOCK_FAILURE'

export const add = (stock) => (dispatch, getState) => {
  if (getState().stocks.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_STOCK_REQUEST })

  return api
    .withOrg(getState)('/stocks/delivery', { method: 'POST', body: stock })
    .then((res) =>
      dispatch({
        type: ADD_STOCK_SUCCESS,
        payload: res,
      })
    )
    .catch((error) => dispatch({ type: ADD_STOCK_FAILURE, error }))
}

// update stock

export const UPDATE_STOCK_REQUEST = 'UPDATE_STOCK_REQUEST'
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS'
export const UPDATE_STOCK_FAILURE = 'UPDATE_STOCK_FAILURE'

export const update = (deliveryId, delivery) => (dispatch, getState) => {
  if (getState().stocks.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_STOCK_REQUEST })

  return api
    .withOrg(getState)(`/stocks/delivery/${deliveryId}`, {
      method: 'POST',
      body: delivery,
    })
    .then((res) =>
      dispatch({
        type: UPDATE_STOCK_SUCCESS,
        payload: res,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_STOCK_FAILURE, error }))
}

// load stocks

export const LOAD_STOCKS_REQUEST = 'LOAD_STOCKS_REQUEST'
export const LOAD_STOCKS_SUCCESS = 'LOAD_STOCKS_SUCCESS'
export const LOAD_STOCKS_FAILURE = 'LOAD_STOCKS_FAILURE'

export const loadStocks = () => (dispatch, getState) => {
  const { isFetching } = getState().stocks.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_STOCKS_REQUEST })

  return api
    .withOrg(getState)('/stocks')
    .then((res) =>
      dispatch({
        type: LOAD_STOCKS_SUCCESS,
        payload: res.stocks,
      })
    )
    .catch((error) => dispatch({ type: LOAD_STOCKS_FAILURE, error }))
}

// stock form
export const formTypes = {
  STOCK_FORM_SET: 'STOCK_FORM_SET',
  STOCK_FORM_RESET: 'STOCK_FORM_RESET',
  STOCK_FORM_ADD_ITEM: 'STOCK_FORM_ADD_ITEM',
  STOCK_FORM_REMOVE_ITEM: 'STOCK_FORM_REMOVE_ITEM',
  STOCK_FORM_COPY_ITEM: 'STOCK_FORM_COPY_ITEM',
  STOCK_FORM_UPDATE_ITEM: 'STOCK_FORM_UPDATE_ITEM',
}

export const formActions = {
  setForm: (form) => ({ type: formTypes.STOCK_FORM_SET, form }),
  resetForm: (date = '', time = '') => ({
    type: formTypes.STOCK_FORM_RESET,
    date,
    time,
  }),
  addItem: (item) => ({ type: formTypes.STOCK_FORM_ADD_ITEM, item }),
  removeItem: (index) => ({ type: formTypes.STOCK_FORM_REMOVE_ITEM, index }),
  copyItem: (index) => ({ type: formTypes.STOCK_FORM_COPY_ITEM, index }),
  updateItem: () => ({ type: formTypes.STOCK_FORM_UPDATE_ITEM }),
}

// filter
export const SET_STOCK_FILTER = 'SET_STOCK_FILTER'
export const setStockFilter = (filter) => ({ type: SET_STOCK_FILTER, filter })

// query stocks

export const QUERY_STOCKS_REQUEST = 'QUERY_STOCKS_REQUEST'
export const QUERY_STOCKS_SUCCESS = 'QUERY_STOCKS_SUCCESS'
export const QUERY_STOCKS_FAILURE = 'QUERY_STOCKS_FAILURE'

// cache: populate items object regardless of curQty
export const queryStocks = (filter, { cache } = {}) => (dispatch, getState) => {
  const {
    status: { isFetching },
  } = getState().stocks.query
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: QUERY_STOCKS_REQUEST })

  return api
    .withOrg(getState)('/stocks/query', {
      query: filter,
    })
    .then((res) =>
      dispatch({
        type: QUERY_STOCKS_SUCCESS,
        listing: res.listing,
        total: res.total,
        filter,
        cache,
      })
    )
    .catch((error) => dispatch({ type: QUERY_STOCKS_FAILURE, error }))
}

// stock usage

export const STOCKS_USAGE_REQUEST = 'STOCKS_USAGE_REQUEST'
export const STOCKS_USAGE_SUCCESS = 'STOCKS_USAGE_SUCCESS'
export const STOCKS_USAGE_FAILURE = 'STOCKS_USAGE_FAILURE'

export const stocksUsage = (stockId) => (dispatch, getState) => {
  const {
    status: { isFetching },
  } = getState().stocks.usage
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: STOCKS_USAGE_REQUEST })

  return api
    .withOrg(getState)(`/stocks/${stockId}/usage`)
    .then((res) =>
      dispatch({
        type: STOCKS_USAGE_SUCCESS,
        log: res.log,
      })
    )
    .catch((error) => dispatch({ type: STOCKS_USAGE_FAILURE, error }))
}
