import { parse, isValid } from 'date-fns'

import { api } from '../utils'

// fetch sale
export const FETCH_SALE_REQUEST = 'FETCH_SALE_REQUEST'
export const FETCH_SALE_SUCCESS = 'FETCH_SALE_SUCCESS'
export const FETCH_SALE_FAILURE = 'FETCH_SALE_FAILURE'

export const fetchSale = (saleId) => (dispatch, getState) => {
  const { fetch } = getState().sales

  if (fetch.isFetching) {
    return Promise.resolve()
  }

  dispatch({ type: FETCH_SALE_REQUEST })

  return api
    .withOrg(getState)('/sales/' + saleId)
    .then((res) =>
      dispatch({
        type: FETCH_SALE_SUCCESS,
        sale: res,
      })
    )
    .catch((error) => dispatch({ type: FETCH_SALE_FAILURE, error }))
}

// query sales

export const QUERY_SALES_REQUEST = 'QUERY_SALES_REQUEST'
export const QUERY_SALES_SUCCESS = 'QUERY_SALES_SUCCESS'
export const QUERY_SALES_FAILURE = 'QUERY_SALES_FAILURE'

export const querySales = (filter) => (dispatch, getState) => {
  const { query } = getState().sales
  filter = filter || query.filter

  if (query.status.isFetching) {
    return Promise.resolve()
  }

  // take care of timezone
  let fromDate = filter.fromDate
  let mfd = parse(fromDate, 'dd-MM-yyyy', new Date())
  if (isValid(mfd)) {
    fromDate = mfd.toISOString()
  }
  let toDate = filter.toDate
  let mtd = parse(toDate, 'dd-MM-yyyy', new Date())
  if (isValid(mtd)) {
    toDate = mtd.toISOString()
  }

  dispatch({ type: QUERY_SALES_REQUEST })

  return api
    .withOrg(getState)('/sales', {
      query: { ...filter, fromDate, toDate },
    })
    .then((res) =>
      dispatch({
        type: QUERY_SALES_SUCCESS,
        listing: res.listing,
        total: res.total,
        sum: res.sum,
        filter,
      })
    )
    .catch((error) => dispatch({ type: QUERY_SALES_FAILURE, error }))
}

// query invoices

export const QUERY_INVOICES_REQUEST = 'QUERY_INVOICES_REQUEST'
export const QUERY_INVOICES_SUCCESS = 'QUERY_INVOICES_SUCCESS'
export const QUERY_INVOICES_FAILURE = 'QUERY_INVOICES_FAILURE'

export const queryInvoices = (filter) => (dispatch, getState) => {
  const { queryByInvoice } = getState().sales
  filter = filter || queryByInvoice.filter

  if (queryByInvoice.status.isFetching) {
    return Promise.resolve()
  }

  // take care of timezone
  // take care of timezone
  let fromDate = filter.fromDate
  let mfd = parse(fromDate, 'dd-MM-yyyy', new Date())
  if (isValid(mfd)) {
    fromDate = mfd.toISOString()
  }
  let toDate = filter.toDate
  let mtd = parse(toDate, 'dd-MM-yyyy', new Date())
  if (isValid(mtd)) {
    toDate = mtd.toISOString()
  }

  dispatch({ type: QUERY_INVOICES_REQUEST })

  return api
    .withOrg(getState)('/invoices', {
      query: { ...filter, fromDate, toDate },
    })
    .then((res) =>
      dispatch({
        type: QUERY_INVOICES_SUCCESS,
        listing: res.listing,
        total: res.total,
        sum: res.sum,
        filter,
      })
    )
    .catch((error) => dispatch({ type: QUERY_INVOICES_FAILURE, error }))
}

// add sales

export const ADD_SALES_REQUEST = 'ADD_SALES_REQUEST'
export const ADD_SALES_SUCCESS = 'ADD_SALES_SUCCESS'
export const ADD_SALES_FAILURE = 'ADD_SALES_FAILURE'

export const addSales = (sales) => (dispatch, getState) => {
  if (getState().sales.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_SALES_REQUEST })

  return api
    .withOrg(getState)('/sales', { method: 'POST', body: sales })
    .then((res) => {
      return dispatch({
        type: ADD_SALES_SUCCESS,
        payload: res.sales,
      })
    })
    .catch((error) => dispatch({ type: ADD_SALES_FAILURE, error }))
}

// update sale

export const UPDATE_SALE_REQUEST = 'UPDATE_SALE_REQUEST'
export const UPDATE_SALE_SUCCESS = 'UPDATE_SALE_SUCCESS'
export const UPDATE_SALE_FAILURE = 'UPDATE_SALE_FAILURE'

export const updateSale = (receiptId, update) => (dispatch, getState) => {
  if (getState().sales.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_SALE_REQUEST })

  return api
    .withOrg(getState)(`/sales/receipt/${receiptId}`, {
      method: 'POST',
      body: update,
    })
    .then((res) =>
      dispatch({
        type: UPDATE_SALE_SUCCESS,
        payload: res,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_SALE_FAILURE, error }))
}

// remove sale

export const REMOVE_SALE_REQUEST = 'REMOVE_SALE_REQUEST'
export const REMOVE_SALE_SUCCESS = 'REMOVE_SALE_SUCCESS'
export const REMOVE_SALE_FAILURE = 'REMOVE_SALE_FAILURE'

export const removeSale = (saleId) => (dispatch, getState) => {
  if (getState().sales.remove.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_SALE_REQUEST })

  return api
    .withOrg(getState)(`/sales/${saleId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_SALE_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_SALE_FAILURE, error }))
}

// entry form

export const SALES_FORM_SET = 'SALES_FORM_SET'
export const setSalesForm = (form) => ({ type: SALES_FORM_SET, form })

export const RESET_SALES_FORM = 'RESET_SALES_FORM'
export const resetSalesForm = () => ({ type: RESET_SALES_FORM })

export const SALES_FORM_SET_ITEM = 'SALES_FORM_SET_ITEM'
export const setSalesFormItem = (item) => ({ type: SALES_FORM_SET_ITEM, item })

export const SALES_FORM_ADD_ITEM = 'SALES_FORM_ADD_ITEM'
export const addSalesFormItem = () => ({ type: SALES_FORM_ADD_ITEM })

export const SALES_FORM_REMOVE_ITEM = 'SALES_FORM_REMOVE_ITEM'
export const removeSalesFormItem = (index) => ({
  type: SALES_FORM_REMOVE_ITEM,
  index,
})

export const SALES_FORM_EDIT_ITEM = 'SALES_FORM_EDIT_ITEM'
export const editSalesFormItem = (index) => ({
  type: SALES_FORM_EDIT_ITEM,
  index,
})

export const SALES_FORM_UPDATE_ITEM = 'SALES_FORM_UPDATE_ITEM'
export const updateSalesFormItem = () => ({ type: SALES_FORM_UPDATE_ITEM })

export const SALES_FORM_MOVE_ITEM = 'SALES_FORM_MOVE_ITEM'
export const moveSalesFormItem = (fromIndex, toIndex) => ({
  type: SALES_FORM_MOVE_ITEM,
  fromIndex,
  toIndex,
})
