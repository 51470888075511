import _ from 'lodash'
import { format } from 'd3-format'
import { parseISO, format as dateFormat, addDays } from 'date-fns'
import { formatLocale } from 'd3-format'
import { useNavigate, useParams } from 'react-router-dom'
const { format: fmtDecimal } = formatLocale({ decimal: ',', nan: '' })

export const COLORS = {
  RESOURCE: 'green',
  STOCK: 'orange',
  RECIPE: 'yellow',
  PRODUCT: 'violet',
  COMPANY: 'brown',
  SALE: 'olive',
  REVISION: 'red',
  REPORT: 'blue',
  EXPENSE: 'purple',
}

export const api = (path, options) => {
  options = Object.assign(
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-socketid': global.socketId,
      },
    },
    options
  )

  if (options.query) {
    let qs = Object.entries(options.query)
      .filter(([k, v]) => v !== undefined)
      .map(([k, v]) => k + '=' + encodeURIComponent(v))
      .join('&')
    path = path + '?' + qs
  }

  if (options.body) {
    options.body = JSON.stringify(options.body)
  }

  return fetch('/api' + path, options)
    .catch((err) =>
      Promise.reject(new Error(`Connection error: ${err.message}`))
    )
    .then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          let message = text
          try {
            message = JSON.parse(text).message
          } catch (e) { }
          return Promise.reject(message)
        })
      }
      return res.json()
    })
    .catch((err) => {
      // intercept expired session
      if (err === 'Authentication required') {
        window.location.reload()
      }
      return Promise.reject(err)
    })
}

api.withOrg = (getState) => (path, options) => {
  options = options || {}
  options.query = options.query || {}
  options.query.org = getState().auth.activeOrg

  return api(path, options)
}

api.forOrg = (org) => (path, options) => {
  options = options || {}
  options.query = options.query || {}
  options.query.org = org

  return api(path, options)
}

export const clickToFocus = (selector) => {
  const node = document.querySelector(selector)
  node && node.click()
}

export const focus = (selector) => {
  const node = document.querySelector(selector)
  node && node.focus()
}

export const getLabel = ({ seqId, arrival }) => {
  return `R${format('04')(seqId)}.${dateFormat(parseISO(arrival), 'ddMMyy')}`
}

export const getRoleLabel = (acl) => {
  switch (acl) {
    case 'manager':
      return 'У'
    case 'user':
      return 'П'
    default:
      return null
  }
}

export const getRoleTitle = (acl) => {
  switch (acl) {
    case 'manager':
      return 'Управител'
    case 'user':
      return 'Потребител'
    default:
      return null
  }
}

export const getRoleColor = (acl) => {
  switch (acl) {
    case 'manager':
      return 'green'
    case 'user':
      return 'blue'
    default:
      return null
  }
}

export const getProductLabel = ({ seqId, producedOn }) => {
  return `L${format('04')(seqId)}.${dateFormat(parseISO(producedOn), 'ddMMyy')}`
}

export const getUserRole = (auth) => {
  const membership = _.find(
    _.get(auth.user, 'membership'),
    (m) => _.get(m, ['org', 'orgname']) === auth.activeOrg
  )
  return _.get(membership, 'role')
}

export const getOSNewLine = () =>
  navigator.userAgent.indexOf('Win') !== -1 ? '\r\n' : '\n'

export const getLabelsCopy = (stocks, resources) => {
  const DELIMITER = '\t'
  const NEW_LINE = getOSNewLine()

  return _.map(stocks, (stock) => {
    const { name } = resources[stock.resource]
    const { batches, quantity, unit, expiry, desc } = stock
    const exp = expiry ? '' + dateFormat(parseISO(expiry), 'dd/MM/yy') : ''
    const label = [
      name,
      getLabel(stock),
      batches + 'x' + quantity + unit,
      exp,
      desc,
    ].join(DELIMITER)
    return _.times(batches, _.constant(label)).join(NEW_LINE)
  }).join(NEW_LINE)
}

/**
 * Returns receipt information for trade docs and others in plain text CSV.
 */
export const getReceiptCopy = (sales, buyer) => {
  const DELIMITER = ';'
  const NEW_LINE = getOSNewLine()

  if (_.isEmpty(sales)) {
    return ''
  }
  const { invoice, tradeDoc, soldOn } = sales[0]

  const fmt = fmtDecimal('.2~f')

  // HACK: talk to my wife
  const countByRecipeDefault = {
    '6063cad38f65003afa8385cb': 0,
    '6063cb198f65003afa8385cc': 0,
    '6063c7f68f65003afa8385c3': 0,
    '6063c63b8f65003afa8385c2': 0,
    '6063c9278f65003afa8385c5': 0,
    '61bba8288f65003afa8395bd': 0,
  }
  const countByRecipe = _.map(
    _.assignWith(
      countByRecipeDefault,
      _.chain(sales)
        .groupBy('recipeId')
        .mapValues((sales) => _.sumBy(sales, 'qty'))
        .value(),
      (a, b) => a + b
    ),
    (count) => count
  )

  const salesCopy = _.map(
    sales,
    ({ productId: product, name, qty, unitPrice, price }) => {
      const expiresOn = addDays(parseISO(product.producedOn), product.duration)

      return [
        getProductLabel(product),
        name,
        dateFormat(expiresOn, 'dd/MM/yyyy'),
        qty,
        fmt(unitPrice),
        fmt(price),
      ].join(DELIMITER)
    }
  )

  const total = _.sumBy(sales, 'price')

  return [
    invoice,
    tradeDoc,
    dateFormat(parseISO(soldOn), 'dd/MM/yyyy'),
    buyer?.companyName,
    buyer?.bulstat,
    buyer?.taxNumber,
    buyer?.location,
    buyer?.manager,
    sales[0].paymentMethod,
    buyer?.deliveryAddress,
    fmt(total),
    countByRecipe.join(DELIMITER),
    ...salesCopy,
  ].join(NEW_LINE)
}

export const displayProductCost = (cost, suffix = '') => {
  if (isNaN(cost)) {
    return '~'
  }
  return format('.2f')(cost) + suffix
}

export const displayCurrency = (amount, currency = 'лв.') => {
  return _.isNil(amount) ? '~' : `${format('.2f')(amount)} ${currency}`
}

export const withParamsAndNavigate = C =>
  props => <C params={useParams()} navigate={useNavigate()} {...props} />
