import _ from 'lodash'
import { Table, Button, Popup, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { removeCompany } from '../../actions/companies'
import '../resource/ResourceListing.css'
import Truncate from '../common/Truncate'
import { COLORS } from '../../utils'

const CompanyListing = ({ listing }) => {
  const remove = useSelector((state) => state.companies.remove)
  const priceTiers = useSelector((state) => state.priceTiers.items)
  const dispatch = useDispatch()

  return (
    <div className='y-scrollable'>
      <Table
        selectable
        compact
        size='small'
        unstackable
        singleLine
        color={COLORS.COMPANY}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>име</Table.HeaderCell>
            <Table.HeaderCell>име на фирма</Table.HeaderCell>
            <Table.HeaderCell>ценови клас</Table.HeaderCell>
            <Table.HeaderCell>контакт</Table.HeaderCell>
            <Table.HeaderCell>седалище</Table.HeaderCell>
            <Table.HeaderCell>бележки</Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            listing,
            ({
              id,
              name,
              companyName,
              priceTier,
              originOrg,
              contact,
              location,
              notes,
            }) => {
              return (
                <Table.Row key={id}>
                  <Table.Cell singleLine>
                    <Link to={`/companies/${id}`}>
                      {originOrg ? (
                        <Popup
                          inverted
                          position='right center'
                          size='small'
                          trigger={<Icon name='star' />}
                          content='Вътрешен доставчик за наличности на вторични продукти.'
                        />
                      ) : null}
                      {name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Truncate text={companyName} />
                  </Table.Cell>
                  <Table.Cell>{priceTiers[priceTier]?.name}</Table.Cell>
                  <Table.Cell>
                    <Truncate text={contact} length={25} />
                  </Table.Cell>
                  <Table.Cell>
                    <Truncate text={location} length={25} />
                  </Table.Cell>
                  <Table.Cell>
                    <Truncate text={notes} length={25} />
                  </Table.Cell>
                  <Table.Cell textAlign='center' className='remove-cell'>
                    <Button
                      basic
                      compact
                      size='mini'
                      icon='trash'
                      disabled={false}
                      className='show-on-hover'
                      loading={remove.isFetching && remove.id === id}
                      onClick={() => {
                        dispatch(removeCompany(id))
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            }
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

export default CompanyListing
