import _ from 'lodash'

import {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  REMOVE_COMPANY_REQUEST,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_FAILURE,
  LOAD_COMPANIES_REQUEST,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_FAILURE,
  formTypes,
  SET_COMPANY_FILTER,
  LOAD_SUP_RESOURCES_REQUEST,
  LOAD_SUP_RESOURCES_FAILURE,
  LOAD_SUP_RESOURCES_SUCCESS,
} from '../actions/companies'

import {
  SYNC_ADD_COMPANY,
  SYNC_UPDATE_COMPANY,
  SYNC_REMOVE_COMPANY,
} from '../actions/sync'

export const INITIAL_STATE = {
  add: {
    isFetching: false,
    error: null,
  },
  update: {
    isFetching: false,
    error: null,
  },
  remove: {
    isFetching: false,
    error: null,
  },
  load: {
    isFetching: false,
    error: null,
  },
  form: {
    name: '',
    companyName: '',
    bulstat: '',
    certificate: '',
    location: '',
    contact: '',
    manager: '',
    taxNumber: '',
    deliveryAddress: '',
    paymentMethod: '',
    email: '',
    notes: '',
  },
  filter: {
    offset: 0,
    limit: 15,
    showAll: true,
    showBuyers: true,
    showSuppliers: false,
    priceTier: null,
  },
  items: {},

  loadSupResources: {
    listing: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 10,
    },
    status: {
      isFetching: false,
      error: null,
    },
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_COMPANY_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case REMOVE_COMPANY_REQUEST:
      return {
        ...state,
        remove: {
          isFetching: true,
          error: null,
          id: action.companyId,
        },
      }
    case REMOVE_COMPANY_FAILURE:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: action.error,
        },
      }
    case REMOVE_COMPANY_SUCCESS:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: null,
        },
        items: _.omit(state.items, state.remove.id),
      }

    case LOAD_COMPANIES_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    // form actions
    case formTypes.COMPANY_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }
    case formTypes.COMPANY_FORM_RESET:
      return {
        ...state,
        form: INITIAL_STATE.form,
      }

    // filter
    case SET_COMPANY_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      }

    // sync
    case SYNC_ADD_COMPANY:
    case SYNC_UPDATE_COMPANY:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    case SYNC_REMOVE_COMPANY:
      return {
        ...state,
        items: _.omit(state.items, action.payload.id),
      }

    case LOAD_SUP_RESOURCES_REQUEST:
      return {
        ...state,
        loadSupResources: {
          ...state.loadSupResources,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case LOAD_SUP_RESOURCES_FAILURE:
      return {
        ...state,
        loadSupResources: {
          ...state.loadSupResources,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case LOAD_SUP_RESOURCES_SUCCESS:
      return {
        ...state,
        loadSupResources: {
          ...state.loadSupResources,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          filter: action.filter,
        },
      }

    default:
      return state
  }
}
