import _ from 'lodash'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Menu,
  Segment,
  Message,
  Grid,
  Header,
  Label,
  Placeholder,
  GridColumn,
} from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { format } from 'd3-format'

import { COLORS, getProductLabel, getLabel, displayCurrency } from '../../utils'
import { fetchRevision } from '../../actions/revisions'
import { displayUnit } from '../../common/unit-utils'
import RoleFilter from '../common/RoleFilter'

export default () => {
  const { id } = useParams()
  const { revision, isFetching, error } = useSelector(
    (state) => state.revisions.fetch
  )
  const resources = useSelector((state) => state.resources.items)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRevision(id))
  }, [dispatch, id])

  if (error) {
    return <Message negative content={error} />
  }

  return (
    <>
      <Helmet title='Ревизия' />

      <Menu attached='top' borderless inverted color={COLORS.REVISION}>
        <Menu.Item header content='Ревизия' />
        <Menu.Menu position='right'>
          <Menu.Item icon='edit' as={Link} to={`/revisions/${id}/edit`} />
        </Menu.Menu>
      </Menu>
      {isFetching ? renderLoading() : renderRevision(revision, resources)}
    </>
  )
}

const renderLoading = () => {
  return (
    <Grid doubling columns={4} padded='vertically'>
      {_.times(2, (index) => {
        return (
          <Grid.Row key={index}>
            {_.times(4, (index) => {
              return (
                <Grid.Column key={index}>
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>
              )
            })}
          </Grid.Row>
        )
      })}
    </Grid>
  )
}

const renderRevision = (revision, resources) => {
  if (!revision) return null

  const {
    product,
    stock,
    revisedAt,
    createdBy,
    oldQty,
    newQty,
    cost,
    notes,
  } = revision
  const unit = _.get(product || stock, 'unit')
  const name =
    _.get(product, 'name') || _.get(resources, [stock.resource, 'name'])
  const diff = newQty - oldQty

  return (
    <>
      <Segment attached>
        <Grid doubling columns={4} padded='vertically'>
          <Grid.Row>
            <Grid.Column>
              <Header content={name} subheader='име' />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={
                  product ? (
                    <Label
                      color={COLORS.PRODUCT}
                      style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                      tag
                      as={Link}
                      to={`/products/${product._id}`}
                      content={getProductLabel(product)}
                    />
                  ) : stock ? (
                    <Label
                      color={COLORS.STOCK}
                      style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                      tag
                      as={Link}
                      to={`/stocks/${stock._id}`}
                      content={getLabel(stock)}
                    />
                  ) : null
                }
                subheader={product ? 'продукт' : 'наличност'}
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={moment(revisedAt).format('DD/MM/YY HH:mm')}
                subheader='ревизирано на'
              />
            </Grid.Column>

            <GridColumn>
              <Header
                content={_.get(createdBy, 'username')}
                subheader='ревизирал'
              />
            </GridColumn>
          </Grid.Row>

          <Grid.Row>
            <GridColumn>
              <Header
                content={`${format('.4~f')(oldQty)} ${displayUnit(unit)}`}
                subheader='преди'
              />
            </GridColumn>

            <Grid.Column>
              <Header
                content={`${format('.4~f')(newQty)} ${displayUnit(unit)}`}
                subheader='след'
              />
            </Grid.Column>

            <GridColumn>
              <Header
                color={diff < 0 ? 'red' : 'green'}
                content={`${format('.4~f')(diff)} ${displayUnit(unit)}`}
                subheader='разлика'
              />
            </GridColumn>

            <GridColumn>
              <RoleFilter>
                <Header
                  color={diff < 0 ? 'red' : 'green'}
                  content={displayCurrency(cost)}
                  subheader='цена'
                />
              </RoleFilter>
            </GridColumn>
          </Grid.Row>

          {notes && (
            <Grid.Row>
              <Grid.Column>
                <Header content={notes} subheader='бележки' />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </>
  )
}
