import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Dropdown, Menu, Icon, Divider } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { logout } from '../actions/auth'
import SearchBox from './SearchBox'
import { isPermitted } from '../common/roles'
import { getUserRole, COLORS } from '../utils'
import { Media } from '../MediaContext'

const SECTIONS_MAP = [
  ['/recipes', 'Рецепти', 'book', COLORS.RECIPE, 'manager'],
  ['/products', 'Продукти', 'tags', COLORS.PRODUCT, 'manager'],
  ['/stocks', 'Склад', 'boxes', COLORS.STOCK, 'manager'],
  ['/resources', 'Суровини', 'tree', COLORS.RESOURCE, 'manager'],
  ['/companies', 'Фирми', 'truck', COLORS.COMPANY, 'manager'],
  ['/revisions', 'Ревизии', 'balance', COLORS.REVISION, 'manager'],
  ['/journal', 'Дневник', 'pencil', COLORS.STOCK, 'admin'],
  ['/sales', 'Продажби', 'calculator', COLORS.SALE, 'manager'],
  ['/reports', 'Отчети', 'chart bar outline', COLORS.REPORT, 'admin'],
  // ['/expenses', 'Разходи', 'payment', COLORS.EXPENSE, 'admin'],
]

const NEW_MAP = [
  ['/sales/new', 'Продажби', COLORS.SALE, 'add', 'manager'],
  ['/products/new', 'Продукт', COLORS.PRODUCT, 'add', 'manager'],
  ['/stocks/delivery/new', 'Наличности', COLORS.STOCK, 'add', 'manager'],
  ['/recipes/new', 'Рецепта', COLORS.RECIPE, 'add', 'admin'],
  ['/companies/new', 'Фирма', COLORS.COMPANY, 'add', 'admin'],
  ['/resources/new', 'Суровина', COLORS.RESOURCE, 'add', 'admin'],
  [null, null, null, null, 'manager'], // separator
  ['/revisions/new', 'Ревизия', COLORS.REVISION, 'add', 'manager'],
  [
    '/sales/price-tiers',
    'Ценови класове',
    COLORS.SALE,
    'calculator',
    'manager',
  ],
]

const TopHeader = () => {
  const dispatch = useDispatch()

  const role = useSelector((state) => getUserRole(state.auth))
  const user = useSelector((state) => state.auth.user)
  const activeOrg = useSelector((state) => state.auth.activeOrg)

  const sectionsMap = _.filter(SECTIONS_MAP, ([, , , , requires]) =>
    isPermitted({ has: role, requires })
  )
  const newMap = _.filter(NEW_MAP, ([, , , , requires]) =>
    isPermitted({ has: role, requires })
  )

  return (
    <React.Fragment>
      <Menu attached='bottom'>
        <Menu.Item header as={Link} to='/'>
          <Media greaterThan='mobile'>Pantry</Media>
          <Media at='mobile'>
            <Icon name='leaf' />
          </Media>
        </Menu.Item>

        {!user ? (
          <Menu.Menu position='right'>
            <Menu.Item as={Link} to='/login'>
              Вход
            </Menu.Item>
          </Menu.Menu>
        ) : (
          <React.Fragment>
            <Media greaterThan='mobile'>
              <Menu.Item>
                <SearchBox />
              </Menu.Item>
            </Media>

            <Menu.Menu position='right'>
              <Dropdown
                item
                icon={
                  <span>
                    <Icon name='add' /> <Icon name='dropdown' fitted />
                  </span>
                }
              >
                <Dropdown.Menu>
                  {_.map(newMap, ([to, name, color, icon]) =>
                    !name ? (
                      <Dropdown.Divider key='divider' />
                    ) : (
                      <Dropdown.Item
                        key={to}
                        as={NavLink}
                        to={to}
                        text={name}
                        icon={<Icon name={icon} color={color} />}
                      />
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                item
                icon={null}
                text={`${activeOrg} / ${user.username}`}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon='cogs'
                    content='Настройки'
                    as={Link}
                    to='/settings'
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    icon='log out'
                    text='Изход'
                    onClick={() => dispatch(logout())}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </React.Fragment>
        )}
      </Menu>

      {user ? (
        <>
          {/* mobile */}
          <Media at='mobile'>
            <Menu borderless>
              <Menu.Item>
                <SearchBox />
              </Menu.Item>

              <Menu.Menu position='right'>
                <Dropdown item icon={<Icon name='content' />}>
                  <Dropdown.Menu>
                    {_.map(sectionsMap, ([to, name, icon]) => (
                      <Dropdown.Item
                        key={to}
                        as={NavLink}
                        to={to}
                        text={name}
                        icon={icon}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </Media>

          {/* tablet */}
          <Media at='tablet'>
            <Menu pointing borderless size='small'>
              {_.map(sectionsMap, ([to, name, icon, color]) => (
                <Menu.Item
                  key={to}
                  as={NavLink}
                  to={to}
                  title={name}
                  icon={icon}
                  color={color}
                />
              ))}
            </Menu>
          </Media>

          {/* desktop */}
          <Media greaterThan='tablet'>
            <Menu pointing secondary size='small'>
              {_.map(sectionsMap, ([to, name, , color]) => (
                <Menu.Item
                  key={to}
                  as={NavLink}
                  to={to}
                  content={name}
                  color={color}
                />
              ))}
            </Menu>
          </Media>

          <Divider hidden style={{ marginTop: 0 }} />
        </>
      ) : null}
    </React.Fragment>
  )
}

export default TopHeader
