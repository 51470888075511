import _ from 'lodash'

import {
  ADD_PRICE_TIER_REQUEST,
  ADD_PRICE_TIER_SUCCESS,
  ADD_PRICE_TIER_FAILURE,
  UPDATE_PRICE_TIER_REQUEST,
  UPDATE_PRICE_TIER_SUCCESS,
  UPDATE_PRICE_TIER_FAILURE,
  REMOVE_PRICE_TIER_REQUEST,
  REMOVE_PRICE_TIER_SUCCESS,
  REMOVE_PRICE_TIER_FAILURE,
  LOAD_PRICE_TIERS_REQUEST,
  LOAD_PRICE_TIERS_SUCCESS,
  LOAD_PRICE_TIERS_FAILURE,
} from '../actions/price-tiers'

import {
  SYNC_ADD_PRICE_TIER,
  SYNC_UPDATE_PRICE_TIER,
  SYNC_REMOVE_PRICE_TIER,
} from '../actions/sync'

export const INITIAL_STATE = {
  add: {
    isFetching: false,
    error: null,
  },
  update: {
    isFetching: false,
    error: null,
  },
  remove: {
    isFetching: false,
    error: null,
  },
  load: {
    isFetching: false,
    error: null,
  },
  items: {},
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_PRICE_TIER_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_PRICE_TIER_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_PRICE_TIER_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case UPDATE_PRICE_TIER_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_PRICE_TIER_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_PRICE_TIER_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    case REMOVE_PRICE_TIER_REQUEST:
      return {
        ...state,
        remove: {
          isFetching: true,
          error: null,
          id: action.priceTierId,
        },
      }
    case REMOVE_PRICE_TIER_FAILURE:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: action.error,
        },
      }
    case REMOVE_PRICE_TIER_SUCCESS:
      return {
        ...state,
        remove: {
          isFetching: false,
          error: null,
        },
        items: _.omit(state.items, state.remove.id),
      }

    case LOAD_PRICE_TIERS_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_PRICE_TIERS_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_PRICE_TIERS_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    // sync
    case SYNC_ADD_PRICE_TIER:
    case SYNC_UPDATE_PRICE_TIER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    case SYNC_REMOVE_PRICE_TIER:
      return {
        ...state,
        items: _.omit(state.items, action.payload.id),
      }

    default:
      return state
  }
}
