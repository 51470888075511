import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Form,
  Confirm,
  Button,
  Menu,
  Dropdown,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'
import validator from 'validator'

import {
  update,
  remove,
  resetPrep,
  formActions,
  UPDATE_RECIPE_SUCCESS,
  REMOVE_RECIPE_SUCCESS,
} from '../../actions/recipes'
import { INITIAL_STATE } from '../../reducers/recipes'
import RecipeForm from '../RecipeForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class EditRecipe extends Component {
  static propTypes = {
    recipe: PropTypes.object,
    form: PropTypes.object.isRequired,
    updateStatus: PropTypes.object.isRequired,
    removeStatus: PropTypes.object.isRequired,

    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    resetPrep: PropTypes.func.isRequired,

    // form actions
    setForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }

  state = {
    confirmDelete: false,
    confirmSave: false,
  }

  handleUpdateRecipe = () => {
    const {
      form,
      recipe: { id },
    } = this.props

    if (this.isValidRecipe()) {
      const update = { ...form }
      if (!update.density) {
        delete update.density
        delete update.densityUnit
      }
      this.props.update(id, update).then((action) => {
        if (action.type === UPDATE_RECIPE_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(`/recipes/${id}`)
          this.props.resetPrep(id)
        }
      })
    }
  }

  handleRemoveRecipe = () => {
    const {
      remove,
      recipe: { id },
    } = this.props
    remove(id).then((action) => {
      if (action.type === REMOVE_RECIPE_SUCCESS) {
        this.props.navigate(`/recipes`)
      }
    })
  }

  isValidRecipe = () => {
    const {
      name,
      ingredients,
      quantity,
      unit,
      density,
      densityUnit,
    } = this.props.form
    return (
      _.trim(name) &&
      (_.isEmpty(density) ||
        (validator.isFloat(density + '', { gt: 0 }) && densityUnit)) &&
      !_.isEmpty(ingredients) &&
      validator.isFloat(quantity + '', { gt: 0 }) &&
      unit
    )
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, this.uneditedForm)

  isDirtyIngredient = () =>
    !_.isEqual(this.props.form.ingredient, this.uneditedForm.ingredient)

  componentDidMount() {
    const { recipe, setForm } = this.props
    if (!recipe) {
      return
    }
    this.uneditedForm = {
      ...INITIAL_STATE.form,
      ...recipe,
      ingredients: _.map(recipe.ingredients, (v) => ({
        ...v,
        id: _.uniqueId(),
      })),
    }
    setForm(this.uneditedForm)
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  render() {
    if (!this.props.recipe) {
      return <Header>Няма такава рецепта.</Header>
    }
    const { confirmDelete, confirmSave } = this.state
    const { recipe, form, updateStatus, removeStatus } = this.props
    return (
      <div>
        <Helmet title={recipe.name} />
        <Menu attached='top' inverted color={COLORS.RECIPE} borderless>
          <Menu.Item header icon='edit' content={form.name || recipe.name} />
          <Menu.Menu position='right'>
            <Dropdown item icon='ellipsis vertical'>
              <Dropdown.Menu>
                <Dropdown.Item
                  content='Изтрий'
                  icon='trash'
                  onClick={() => this.setState({ confirmDelete: true })}
                />
                <Confirm
                  content='Рецептата ще бъде изтрита безвъзвратно. Сигурни ли сте?'
                  cancelButton='Отмени'
                  confirmButton={<Button negative content='Изтрий' />}
                  size='tiny'
                  open={confirmDelete}
                  onCancel={() => this.setState({ confirmDelete: false })}
                  onConfirm={() => {
                    this.setState({ confirmDelete: false })
                    this.handleRemoveRecipe()
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
        <Segment attached>
          <RecipeForm
            loading={updateStatus.isFetching || removeStatus.isFetching}
            error={updateStatus.error || removeStatus.error}
            curRecipeId={recipe.id}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='save'
                  content='Запази'
                  type='button'
                  disabled={!this.isValidRecipe() || !this.isDirty()}
                  onClick={() => {
                    if (!this.isDirtyIngredient()) {
                      this.handleUpdateRecipe()
                    } else {
                      this.setState({ confirmSave: true })
                    }
                  }}
                />
                <Form.Button
                  content='Отказ'
                  type='button'
                  basic
                  onClick={() => {
                    this.props.navigate(`/recipes/${recipe.id}`)
                  }}
                />
                <Confirm
                  content='Имате съставка под редакция. Игнорирай?'
                  cancelButton='Не'
                  confirmButton='Да'
                  size='tiny'
                  open={confirmSave}
                  onCancel={() => this.setState({ confirmSave: false })}
                  onConfirm={() => {
                    this.setState({ confirmSave: false })
                    this.handleUpdateRecipe()
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (state, ownProps) => ({
    recipe: state.recipes.items[ownProps.params.id],
    form: state.recipes.form,
    updateStatus: state.recipes.update,
    removeStatus: state.recipes.remove,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        update,
        remove,
        resetPrep,
        ...formActions,
      },
      dispatch
    )
)(EditRecipe))
