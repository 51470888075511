import _ from 'lodash'
import { createSelector } from 'reselect'

import { convertUnits, friendlyQtyUnit } from './common/unit-utils'

const getProducts = (state) => state.products.items
const getRecipes = (state) => state.recipes.items

const getProductsByRecipe = createSelector([getProducts], (products) =>
  _.groupBy(products, 'recipeId')
)

export const getProductTotals = createSelector(
  [getProductsByRecipe, getRecipes],
  (productsByRecipe, recipes) => {
    return _.mapValues(recipes, ({ id, unit }) => {
      return friendlyQtyUnit({
        qty: _.sumBy(productsByRecipe[id], (product) => {
          try {
            return product.curQty * convertUnits(product.unit, unit)
          } catch (e) {
            return NaN
          }
        }),
        unit,
      })
    })
  }
)
