import _ from 'lodash'
import {
  Segment,
  Header,
  Button,
  List,
  Grid,
  Label,
  Table,
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'

import { setOrg } from '../actions/auth'
import { useDispatch, useSelector } from 'react-redux'

const Settings = () => {
  const dispatch = useDispatch()
  const { username, firstName, lastName, email, membership } = useSelector(
    (state) => state.auth.user
  )
  const activeOrg = useSelector((state) => state.auth.activeOrg)
  const loadingOrg = useSelector((state) => state.auth.loadingOrg)

  return (
    <>
      <Helmet title='Настройки' />

      <Header as='h3' content='Настройки' attached='top' />

      <Segment attached='bottom'>
        <Grid stackable columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Header as='h4' content='Профил' />
              <List>
                <List.Item icon='id badge' content={username} />
                <List.Item icon='user' content={`${firstName} ${lastName}`} />
                <List.Item icon='mail' content={email} />
              </List>
            </Grid.Column>

            <Grid.Column>
              <Header as='h4' content='Организации' />
              <Button.Group vertical>
                {_.map(membership, ({ org: { orgname }, role }) => {
                  const active = activeOrg === orgname
                  return (
                    <Button
                      key={orgname}
                      icon={active ? 'check square outline' : 'square outline'}
                      positive={active}
                      labelPosition='left'
                      loading={active && loadingOrg}
                      content={`${orgname} / ${role}`}
                      onClick={() => !active && dispatch(setOrg(orgname))}
                    />
                  )
                })}
              </Button.Group>
            </Grid.Column>

            <Grid.Column>
              <Header as='h4' content='Клавишни комбинации' />
              <Table unstackable size='small' compact basic='very'>
                <Table.Body>
                  {_.map(
                    [
                      [['/'], 'търси'],
                      [['N R', 'Н Р'], 'нова рецепта'],
                      [['N D', 'Н Н'], 'нова наличност'],
                      [['P R', 'П Р'], 'нова продукт'],
                      [['N S', 'Н П'], 'нова продажба'],
                      [['S R', 'В Р'], 'виж рецепти'],
                      [['S P', 'В П'], 'виж продукти'],
                      [['S C', 'В О'], 'виж отчети'],
                    ],
                    ([combos, desc], index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell textAlign='right'>
                            {_.map(combos, (combo, index) => (
                              <Label key={index} content={combo} circular />
                            ))}
                          </Table.Cell>
                          <Table.Cell textAlign='left'>{desc}</Table.Cell>
                        </Table.Row>
                      )
                    }
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
}

export default Settings
