import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Table,
  Label,
  Progress,
  Segment,
  Loader,
  Button,
} from 'semantic-ui-react'
import { format } from 'd3-format'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  displayUnit,
  displayResourceUnit,
  getUnitPrice,
} from '../../common/unit-utils'
import { getLabel, COLORS } from '../../utils'
import { addRevisions } from '../../actions/revisions'
import Truncate from '../common/Truncate'

const StockListing = ({
  listing,
  loading,
  columns = ['current', 'resource', 'price', 'desc', 'delivery'],
}) => {
  const resources = useSelector((state) => state.resources.items)
  const companies = useSelector((state) => state.companies.items)
  const addRevisionStatus = useSelector((state) => state.revisions.add)
  const dispatch = useDispatch()

  const reviseToZero = (stock) => {
    window.confirm('Нулирай количество?') &&
      dispatch(
        addRevisions({
          items: [
            {
              stock: stock.id,
              newQty: 0,
              notes: 'нулиране',
              curQty: stock.curQty,
              unit: stock.unit,
            },
          ],
          revisedAt: moment(),
        })
      )
  }

  columns = new Set(columns)
  return (
    <div className='y-scrollable'>
      <Table
        selectable
        compact
        singleLine
        unstackable
        color={COLORS.STOCK}
        size='small'
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>наличност №</Table.HeaderCell>
            {columns.has('resource') && (
              <Table.HeaderCell>суровина</Table.HeaderCell>
            )}
            {columns.has('arrival') && (
              <Table.HeaderCell>приемане</Table.HeaderCell>
            )}
            {columns.has('current') && (
              <Table.HeaderCell textAlign='right'>остава</Table.HeaderCell>
            )}
            {columns.has('supplier') && (
              <Table.HeaderCell>доставчик</Table.HeaderCell>
            )}
            {columns.has('receipt') && (
              <Table.HeaderCell>документ</Table.HeaderCell>
            )}
            {columns.has('batchId') && (
              <Table.HeaderCell>партиден №</Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign='right'>количество</Table.HeaderCell>
            <Table.HeaderCell />
            {columns.has('price') && (
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
            )}
            {columns.has('unitPrice') && (
              <Table.HeaderCell textAlign='right'>ед. цена</Table.HeaderCell>
            )}
            <Table.HeaderCell>годност</Table.HeaderCell>
            {columns.has('bioCert') && (
              <Table.HeaderCell>био серт.</Table.HeaderCell>
            )}
            {columns.has('desc') && (
              <Table.HeaderCell>описание</Table.HeaderCell>
            )}
            {columns.has('delivery') && (
              <Table.HeaderCell textAlign='right'>доставка</Table.HeaderCell>
            )}
            {columns.has('revise-to-zero') && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>

        {loading && (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={8 + columns.size}>
                <Segment basic padded>
                  <Loader active />
                </Segment>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}

        {!loading && (
          <Table.Body>
            {_.isEmpty(listing) && (
              <Table.Row>
                <Table.Cell colSpan={10} textAlign='center' disabled>
                  <em>Няма наличност</em>
                </Table.Cell>
              </Table.Row>
            )}
            {_.map(listing, (item) => {
              const resource = resources[item.resource]
              const supplier = companies[item.supplier]
              const unitPrice = getUnitPrice(
                item.price,
                item.quantity * item.batches,
                item.unit,
                resource.unit
              )
              const isExpired =
                item.curQty > 0 && item.expiry && moment(item.expiry).isBefore()
              const isRevisionInProgress =
                addRevisionStatus.isFetching &&
                _.find(addRevisionStatus.payload.items, { stock: item.id })
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Label
                      color={COLORS.STOCK}
                      style={{ opacity: item.curQty > 0 ? 1 : 0.5 }}
                      as={Link}
                      to={`/stocks/${item.id}`}
                      tag
                      content={getLabel(item)}
                    />
                  </Table.Cell>
                  {columns.has('resource') && (
                    <Table.Cell>
                      <Link to={`/resources/${resource.id}`}>
                        {resource.name}
                      </Link>
                    </Table.Cell>
                  )}
                  {columns.has('arrival') && (
                    <Table.Cell>
                      {moment(item.arrival).format('DD/MM/YY - HH:mm')}
                    </Table.Cell>
                  )}
                  {columns.has('current') && (
                    <Table.Cell textAlign='right'>
                      <Progress
                        value={item.curQty}
                        total={item.batches * item.quantity}
                        indicating
                        error={item.curQty < 0}
                        size='tiny'
                        label={`${format('.3~f')(item.curQty)} ${displayUnit(
                          item.unit
                        )}`}
                      />
                    </Table.Cell>
                  )}
                  {columns.has('supplier') && (
                    <Table.Cell>
                      <Link to={`/companies/${supplier.id}`}>
                        <Truncate
                          text={supplier.companyName || supplier.name}
                        />
                      </Link>
                    </Table.Cell>
                  )}
                  {columns.has('receipt') && (
                    <Table.Cell>
                      <Link to={`/stocks/delivery/${item.deliveryId}`}>
                        <Truncate text={item.receipt || '~'} />
                      </Link>
                    </Table.Cell>
                  )}
                  {columns.has('batchId') && (
                    <Table.Cell>{item.batchId}</Table.Cell>
                  )}
                  <Table.Cell textAlign='right'>
                    {item.batches} x {item.quantity}
                  </Table.Cell>
                  <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                    {displayUnit(item.unit)}
                  </Table.Cell>
                  {columns.has('price') && (
                    <Table.Cell textAlign='right'>{`${format('.2f')(
                      item.price
                    )} лв.`}</Table.Cell>
                  )}
                  {columns.has('unitPrice') && (
                    <Table.Cell textAlign='right'>
                      {`${format('.2f')(unitPrice)} лв. ${displayResourceUnit(
                        resource.unit
                      )}`}
                    </Table.Cell>
                  )}
                  <Table.Cell negative={isExpired}>
                    {item.expiry && moment(item.expiry).format('DD/MM/YY')}
                  </Table.Cell>
                  {columns.has('bioCert') && (
                    <Table.Cell>{item.bioCert}</Table.Cell>
                  )}
                  {columns.has('desc') && (
                    <Table.Cell title={item.desc}>
                      {_.truncate(item.desc)}
                    </Table.Cell>
                  )}
                  {columns.has('delivery') && (
                    <Table.Cell textAlign='right'>
                      <Link to={`/stocks/delivery/${item.deliveryId}`}>
                        {supplier.name} -{' '}
                        {moment(item.arrival).format('DD/MM/YY')}
                      </Link>
                    </Table.Cell>
                  )}
                  {columns.has('revise-to-zero') && (
                    <Table.Cell textAlign='center'>
                      {item.curQty !== 0 && (
                        <Button
                          loading={isRevisionInProgress}
                          compact
                          circular
                          size='mini'
                          icon='remove'
                          onClick={() => reviseToZero(item)}
                          title='Ревизирай на нула'
                        />
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table>
    </div>
  )
}

StockListing.propTypes = {
  listing: PropTypes.array.isRequired,
  columns: PropTypes.array,
  loading: PropTypes.bool,
}

export default StockListing
