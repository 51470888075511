import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Checkbox, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'd3-format'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isFloat } from 'validator'

import Truncate from '../common/Truncate'
import RoleFilter from '../common/RoleFilter'
import { togglePrepCheck, togglePrepExpand } from '../../actions/recipes'
import {
  ingredientCost,
  displayUnit,
  altUnitQty,
} from '../../common/unit-utils'
import { COLORS } from '../../utils'

class RecipeIngredients extends Component {
  static propTypes = {
    prep: PropTypes.object,
    resources: PropTypes.object.isRequired,
    recipes: PropTypes.object.isRequired,
    totalCost: PropTypes.number.isRequired,
    // prep actions
    togglePrepCheck: PropTypes.func.isRequired,
    togglePrepExpand: PropTypes.func.isRequired,
  }

  render() {
    const {
      prep: { dose: doseValue, ingredients },
      totalCost,
      togglePrepExpand,
      togglePrepCheck,
      recipes,
      resources,
    } = this.props

    const dose = isFloat(doseValue + '', { gt: 0 }) ? parseFloat(doseValue) : 1

    return (
      <Table
        basic='very'
        compact
        collapsing
        selectable
        unstackable
        singleLine
        className='recipe-form-ingredients'
      >
        <Table.Header>
          <Table.Row className='ingredient'>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            {dose !== 1 ? (
              <Table.HeaderCell textAlign='right'>x {dose}</Table.HeaderCell>
            ) : null}
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(ingredients, (ingredient, index) => {
            const {
              resourceId,
              recipeId,
              quantity,
              unit,
              notes,
              checked,
              expanded,
              levels,
            } = ingredient
            const cost = ingredientCost(ingredient, resources, recipes)
            const resourceOrRecipe = resourceId
              ? resources[resourceId]
              : recipes[recipeId]
            const { altUnit, altQty } = altUnitQty(
              unit,
              quantity,
              resourceOrRecipe
            )

            const completed = checked
            return (
              <Table.Row key={index} className='ingredient'>
                <Table.Cell textAlign='center'>
                  <Checkbox
                    checked={completed}
                    onClick={() => togglePrepCheck(index)}
                  />
                </Table.Cell>

                <Table.Cell
                  textAlign='right'
                  className={classNames({ completed, secondary: expanded })}
                  onClick={() => togglePrepCheck(index)}
                  style={{ paddingRight: 5 }}
                >
                  {format('.3~f')(quantity)}
                </Table.Cell>

                <Table.Cell
                  textAlign='left'
                  className={classNames({ completed, secondary: expanded })}
                  style={{ paddingLeft: 0 }}
                  onClick={() => togglePrepCheck(index)}
                >
                  {displayUnit(unit)}
                </Table.Cell>

                {dose !== 1 ? (
                  <React.Fragment>
                    <Table.Cell
                      textAlign='right'
                      className={classNames({ completed, secondary: expanded })}
                      onClick={() => togglePrepCheck(index)}
                      style={{ paddingRight: 5 }}
                    >
                      {format('.3~f')(quantity * dose)}
                    </Table.Cell>

                    <Table.Cell
                      textAlign='left'
                      className={classNames({ completed, secondary: expanded })}
                      style={{ paddingLeft: 0 }}
                      onClick={() => togglePrepCheck(index)}
                    >
                      {displayUnit(unit)}
                    </Table.Cell>
                  </React.Fragment>
                ) : null}

                {/* Alternative qty and unit */}
                <Table.Cell
                  textAlign='right'
                  className={classNames('secondary', { completed })}
                  style={{ padding: 0 }}
                  onClick={() => togglePrepCheck(index)}
                >
                  {altUnit &&
                    altQty &&
                    `(${format('.2~f')(dose * altQty)} ${displayUnit(
                      altUnit
                    )})`}
                </Table.Cell>

                <Table.Cell
                  className={classNames({ completed, secondary: expanded })}
                >
                  <span style={{ paddingLeft: `${_.size(levels)}em` }}>
                    {recipeId ? (
                      <Icon
                        link
                        name={expanded ? 'chevron down' : 'chevron right'}
                        onClick={() => togglePrepExpand(index)}
                      />
                    ) : (
                      <Icon />
                    )}
                    {recipeId ? (
                      <Link
                        to={`/recipes/${recipeId}`}
                        state={{ dosage: { qty: quantity * dose, unit } }}
                      >
                        <Icon name='external' color={COLORS.RECIPE} />
                      </Link>
                    ) : (
                      <Link to={`/resources/${resourceId}`}>
                        <Icon name='external' color={COLORS.RESOURCE} />
                      </Link>
                    )}
                    <strong onClick={() => togglePrepCheck(index)}>
                      <Truncate text={resourceOrRecipe.name} length={40} />
                    </strong>
                  </span>
                </Table.Cell>

                <Table.Cell
                  className={classNames({ completed })}
                  onClick={() => togglePrepCheck(index)}
                >
                  <em>
                    <Truncate text={notes} length={35} />
                  </em>
                </Table.Cell>

                <Table.Cell
                  textAlign='right'
                  className={classNames('secondary', { tertiary: expanded })}
                  onClick={() => togglePrepCheck(index)}
                >
                  <RoleFilter>{`${format('.2f')(cost * dose)} лв.`}</RoleFilter>
                </Table.Cell>

                <Table.Cell
                  textAlign='right'
                  className={classNames('secondary', { tertiary: expanded })}
                  onClick={() => togglePrepCheck(index)}
                >
                  <RoleFilter>
                    {`${format('.2f')((cost / totalCost) * 100)} %`}
                  </RoleFilter>
                </Table.Cell>

                <Table.Cell
                  className={classNames('secondary', { completed })}
                  onClick={() => togglePrepCheck(index)}
                >
                  <Truncate text={resourceOrRecipe.desc} length={20} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

export default connect(
  (state, { recipeId }) => ({
    prep: state.recipes.preps[recipeId],
    resources: state.resources.items,
    recipes: state.recipes.items,
  }),
  (dispatch, { recipeId }) =>
    bindActionCreators(
      {
        togglePrepCheck: (index) => togglePrepCheck(recipeId, index),
        togglePrepExpand: (index) => togglePrepExpand(recipeId, index),
      },
      dispatch
    )
)(RecipeIngredients)
