import React from 'react'
import { Header } from 'semantic-ui-react'

export default ({ message = 'Няма такава страница.' }) => {
  return (
    <Header
      size='huge'
      textAlign='center'
      content='¯\_(ツ)_/¯'
      subheader={message}
    />
  )
}
