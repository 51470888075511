import _ from 'lodash'

export const displayUnit = (unit) =>
  ({
    count: 'бр.',
    hg: '100 g', // hectogram
    dl: '100 ml', // deciliter
    // kg: 'кг',
    // g: 'г',
    // l: 'л',
    // ml: 'мл',
    // Tbsp: 'с.л.',
    // tsp: 'ч.л.',
    // cup: 'ч.ч.'
  }[unit] || unit)

export const getSalePriceUnit = (unit) =>
  ({
    g: 'hg',
    ml: 'dl',
  }[unit] || unit)

export const displayResourceUnit = (unit) =>
  ({
    kg: '/ kg',
    l: '/ l',
    hg: '/ 100 g', // hectogram
    dl: '/ 100 ml', // deciliter
    count: '/ бр.',
  }[unit] || unit)

export const getValidIngredientUnits = (resourceOrRecipe) => {
  if (!resourceOrRecipe || !resourceOrRecipe.unit) {
    return []
  }

  if (resourceOrRecipe.density && resourceOrRecipe.densityUnit) {
    return {
      kg: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
      l: ['ml', 'cup', 'l', 'Tbsp', 'tsp', 'g', 'kg'],
      hg: ['tsp', 'Tbsp', 'g', 'cup', 'ml', 'kg', 'l'],
      dl: ['tsp', 'Tbsp', 'ml', 'cup', 'g', 'l', 'kg'],
      count: ['count'],
      // TODO verify order below
      g: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
      ml: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
      Tbsp: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
      tsp: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
      cup: ['g', 'cup', 'kg', 'Tbsp', 'tsp', 'ml', 'l'],
    }[resourceOrRecipe.unit]
  } else {
    return {
      kg: ['g', 'kg'],
      l: ['ml', 'cup', 'l', 'Tbsp', 'tsp'],
      hg: ['g', 'kg'],
      dl: ['tsp', 'Tbsp', 'ml', 'cup', 'l'],
      count: ['count'],
      // TODO verify order below
      g: ['g', 'kg'],
      ml: ['ml', 'cup', 'l', 'Tbsp', 'tsp'],
      Tbsp: ['ml', 'cup', 'l', 'Tbsp', 'tsp'],
      tsp: ['ml', 'cup', 'l', 'Tbsp', 'tsp'],
      cup: ['ml', 'cup', 'l', 'Tbsp', 'tsp'],
    }[resourceOrRecipe.unit]
  }
}

export const getValidStockUnits = (resource) => {
  if (!resource || !resource.unit) {
    return []
  }
  return {
    kg: ['kg', 'g'],
    hg: ['g', 'kg'],
    l: ['l', 'ml'],
    dl: ['ml', 'l'],
    count: ['count'],
  }[resource.unit]
}

export const STOCK_QUANTITY_UNITS = ['kg', 'g', 'l', 'ml', 'count']
export const RESOURCE_UNITS = ['kg', 'hg', 'l', 'dl', 'count']
export const QUANTITY_UNITS = [
  'kg',
  'g',
  'l',
  'ml',
  'Tbsp',
  'tsp',
  'cup',
  'count',
]
export const DENSITY_UNITS = ['g/cup', 'g/Tbsp', 'kg/l', 'kg/m³']

const TYPE = {
  count: 'count',
  // weight
  kg: 'weight',
  hg: 'weight',
  g: 'weight',
  // volume
  l: 'volume',
  dl: 'volume',
  ml: 'volume',
  Tbsp: 'volume',
  tsp: 'volume',
  cup: 'volume',
  'm³': 'volume',
}

const NORM = {
  // count
  count: 1,
  // weight (kg)
  kg: 1,
  g: 0.001,
  hg: 0.1,
  // volume (l)
  l: 1,
  dl: 0.1,
  ml: 0.001,
  Tbsp: 0.015,
  tsp: 0.005,
  cup: 0.25,
  'm³': 1000,
}
Object.assign(NORM, {
  // density (kg/l)
  'kg/l': 1,
  'g/cup': NORM['g'] / NORM['cup'],
  'g/Tbsp': NORM['g'] / NORM['Tbsp'],
  'kg/m³': NORM['kg'] / NORM['m³'],
})

export const QTY_PRECISION = {
  kg: 3, // 0.001
  g: 1, // 0.1
  l: 3, // 0.001
  ml: 1, // 0.1
  count: 3, // 0.001
}

export const unitType = (unit) => TYPE[unit]

export const convertUnits = (fromUnit, toUnit, toDensity, toDensityUnit) => {
  const [fromType, toType] = [TYPE[fromUnit], TYPE[toUnit]]
  if (!fromType) {
    throw new Error(`Unknown unit ${fromUnit}`)
  }
  if (!toType) {
    throw new Error(`Unknown units ${toUnit}`)
  }
  if (fromType === toType) {
    return NORM[fromUnit] / NORM[toUnit]
  }
  if (fromType === 'count' || toType === 'count') {
    throw new Error(`Incompatible units ${fromUnit} -> ${toUnit}.`)
  }
  if (!toDensity || !toDensityUnit) {
    throw new Error(
      `Incompatible units ${fromUnit} -> ${toUnit} without density info.`
    )
  }
  if (!(toDensityUnit in NORM)) {
    throw new Error(`Unknown density unit ${toDensityUnit}.`)
  }
  toDensity *= NORM[toDensityUnit]
  if (fromType === 'volume') {
    return (NORM[fromUnit] * toDensity) / NORM[toUnit]
  }
  return NORM[fromUnit] / toDensity / NORM[toUnit]
}

export const ingredientCost = (ingredient, resources, recipes) => {
  const resource = ingredient.resourceId && resources[ingredient.resourceId]
  const recipe = ingredient.recipeId && recipes[ingredient.recipeId]

  let { unitPrice, unit, density, densityUnit, gtn } = resource || recipe
  gtn = gtn || 1

  if (recipe) {
    unitPrice =
      ingredientsCost(recipe.ingredients, resources, recipes) / recipe.quantity
  }

  const { quantity } = ingredient
  return (
    gtn *
    unitPrice *
    quantity *
    convertUnits(ingredient.unit, unit, density, densityUnit)
  )
}

export const ingredientsCost = (ingredients, resources, recipes) => {
  return _.reduce(
    ingredients,
    (total, ingredient) =>
      total + ingredientCost(ingredient, resources, recipes),
    0
  )
}

export const altUnit = (unit, resourceOrRecipe) => {
  const map = {
    'kg/l': { weight: 'ml', volume: 'g' },
    'kg/m³': { weight: 'ml', volume: 'g' },
    'g/cup': { weight: 'cup', volume: 'g' },
    'g/Tbsp': { weight: 'Tbsp', volume: 'g' },
  }
  const densityUnit = _.get(resourceOrRecipe, 'densityUnit')
  return _.get(map[densityUnit], TYPE[unit])
}

export const altUnitQty = (unit, qty, resourceOrRecipe) => {
  const { density, densityUnit } = resourceOrRecipe
  const toUnit = altUnit(unit, resourceOrRecipe)
  if (!toUnit || !density || !densityUnit) {
    return {}
  }
  return {
    altUnit: toUnit,
    altQty: qty * convertUnits(unit, toUnit, density, densityUnit),
  }
}

export const getUnitPrice = (price, qty, fromUnit, toUnit) => {
  return price / (qty * convertUnits(fromUnit, toUnit))
}

export const getStockUnitPrice = (
  { price, quantity, batches, unit }, // stock
  toUnit
) => {
  return price / (quantity * batches * convertUnits(unit, toUnit))
}

export const friendlyQtyUnit = ({ qty, unit }) => {
  if (unit === 'hg') {
    if (qty >= 10) {
      return { qty: qty / 10, unit: 'kg' }
    } else {
      return { qty: qty * 100, unit: 'g' }
    }
  }
  if (unit === 'dl') {
    if (qty >= 10) {
      return { qty: qty / 10, unit: 'l' }
    } else {
      return { qty: qty * 100, unit: 'ml' }
    }
  }
  if (unit === 'kg' && qty < 1) {
    return { qty: qty * 1000, unit: 'g' }
  }
  if (unit === 'ml' && qty < 1) {
    return { qty: qty * 1000, unit: 'ml' }
  }
  return { qty, unit }
}

// secondary unit mapping
const secMap = {
  weight: [100, 'g'],
  volume: [100, 'ml'],
  count: [1, 'count'],
}
export const secondaryUnits = ({ cost, qty, unit }) => {
  const [toQty, toUnit] = secMap[unitType(unit)]

  const secCostRatio = (convertUnits(unit, toUnit) * qty) / toQty

  return {
    cost: cost / secCostRatio,
    qty: toQty,
    unit: toUnit,
  }
}

export const productIngredientCost = (
  ingredient,
  resources,
  stocks,
  products
) => {
  if (
    !ingredient ||
    !(ingredient.stockId || ingredient.productId) ||
    !ingredient.unit ||
    !ingredient.qty
  ) {
    return NaN
  }

  if (ingredient.productId) {
    const product = products[ingredient.productId]
    if (!product) {
      return NaN
    }

    const cost = productCost(product.ingredients, resources, stocks, products)
    const unitPrice = cost / product.qty
    return (
      unitPrice * ingredient.qty * convertUnits(ingredient.unit, product.unit)
    )
  }

  const stock = stocks[ingredient.stockId]
  if (!stock) {
    return NaN
  }
  const resource = resources[stock.resource]

  let { density, densityUnit, gtn } = resource
  gtn = gtn || 1

  const unitPrice = getStockUnitPrice(stock, stock.unit)

  return (
    gtn *
    unitPrice *
    ingredient.qty *
    convertUnits(ingredient.unit, stock.unit, density, densityUnit)
  )
}

export const productCost = (ingredients, resources, stocks, products) => {
  return _.reduce(
    ingredients,
    (total, ingredient) =>
      total + productIngredientCost(ingredient, resources, stocks, products),
    0
  )
}

// non-recursive stocks-only cost, for reporting needs
export const productFlatCost = (ingredients, resources, stocks) => {
  return _.reduce(
    ingredients,
    (total, ingredient) =>
      total +
      (ingredient.stockId
        ? productIngredientCost(ingredient, resources, stocks)
        : 0),
    0
  )
}

export const productCostAsync = async (
  ingredients,
  fetcher,
  cache = { products: {}, stocks: {}, resources: {} }
) => {
  const fetchCostCache = async (ingredients) => {
    if (_.isEmpty(ingredients)) {
      return
    }

    // collect cache-misses
    const [productIds, stockIds] = [new Set(), new Set()]
    _.forEach(ingredients, ({ productId, stockId }) => {
      if (productId && !_.has(cache.products, productId)) {
        productIds.add(productId.toString())
      }
      if (stockId && !_.has(cache.stocks, stockId)) {
        stockIds.add(stockId.toString())
      }
    })

    // fetch cache-misses
    const results = await fetcher([...productIds], [...stockIds])

    // cache new results
    _.assign(cache.products, results.products)
    _.assign(cache.stocks, results.stocks)
    _.assign(cache.resources, results.resources)

    // recursive breadth-first search
    const subIngredients = []
    _.forEach(results.products, ({ ingredients }) =>
      subIngredients.push(...ingredients)
    )

    await fetchCostCache(subIngredients)
  }

  await fetchCostCache(ingredients)

  return {
    cost: productCost(
      ingredients,
      cache.resources,
      cache.stocks,
      cache.products
    ),
    flatCost: productFlatCost(ingredients, cache.resources, cache.stocks),
  }
}
