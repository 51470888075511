import _ from 'lodash'
import {
  QUERY_SALES_REQUEST,
  QUERY_SALES_FAILURE,
  QUERY_SALES_SUCCESS,
  QUERY_INVOICES_REQUEST,
  QUERY_INVOICES_FAILURE,
  QUERY_INVOICES_SUCCESS,
  FETCH_SALE_REQUEST,
  FETCH_SALE_FAILURE,
  FETCH_SALE_SUCCESS,
  UPDATE_SALE_REQUEST,
  UPDATE_SALE_FAILURE,
  UPDATE_SALE_SUCCESS,
  ADD_SALES_REQUEST,
  ADD_SALES_SUCCESS,
  ADD_SALES_FAILURE,
  SALES_FORM_ADD_ITEM,
  SALES_FORM_SET,
  SALES_FORM_SET_ITEM,
  SALES_FORM_REMOVE_ITEM,
  SALES_FORM_EDIT_ITEM,
  RESET_SALES_FORM,
  REMOVE_SALE_REQUEST,
  REMOVE_SALE_FAILURE,
  REMOVE_SALE_SUCCESS,
  SALES_FORM_UPDATE_ITEM,
  SALES_FORM_MOVE_ITEM,
} from '../actions/sales'
import update from 'immutability-helper'

export const INITIAL_STATE = {
  query: {
    listing: [],
    total: 0,
    sum: 0,
    filter: {
      offset: 0,
      limit: 20,
      fromDate: '',
      toDate: '',
      name: '',
      types: [],
    },
    status: {
      isFetching: false,
      error: null,
    },
  },

  queryByInvoice: {
    listing: [],
    total: 0,
    sum: 0,
    filter: {
      offset: 0,
      limit: 20,
      fromDate: '',
      toDate: '',
      name: '',
      types: [],
    },
    status: {
      isFetching: false,
      error: null,
    },
  },

  fetch: { isFetching: false, error: null, sale: null },
  add: { isFetching: false, error: null },
  update: { isFetching: false, error: null },
  remove: { isFetching: false, error: null },

  form: {
    buyer: null,
    type: 'клиент',
    paymentMethod: '',
    invoice: '',
    tradeDoc: '',
    priceTier: '',
    soldOnDate: '',
    soldOnTime: '',
    items: [],
    item: {
      productId: null,
      stockId: undefined,
      qty: '',
      unitPrice: '',
      price: '',
      notes: '',
      unit: null,
    },
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCH_SALE_REQUEST:
      return {
        ...state,
        fetch: {
          isFetching: true,
          error: null,
        },
      }
    case FETCH_SALE_FAILURE:
      return {
        ...state,
        fetch: {
          isFetching: false,
          error: action.error,
        },
      }
    case FETCH_SALE_SUCCESS:
      return {
        ...state,
        fetch: {
          isFetching: false,
          error: null,
          sale: action.sale,
        },
      }

    case UPDATE_SALE_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_SALE_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_SALE_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
      }

    case REMOVE_SALE_REQUEST:
      return {
        ...state,
        remove: { isFetching: true, error: null },
      }
    case REMOVE_SALE_FAILURE:
      return {
        ...state,
        remove: { isFetching: false, error: action.error },
      }
    case REMOVE_SALE_SUCCESS:
      return {
        ...state,
        remove: { isFetching: false, error: null },
      }

    case QUERY_SALES_REQUEST:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case QUERY_SALES_FAILURE:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case QUERY_SALES_SUCCESS:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          sum: action.sum,
          filter: action.filter,
        },
      }

    case QUERY_INVOICES_REQUEST:
      return {
        ...state,
        queryByInvoice: {
          ...state.queryByInvoice,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case QUERY_INVOICES_FAILURE:
      return {
        ...state,
        queryByInvoice: {
          ...state.queryByInvoice,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case QUERY_INVOICES_SUCCESS:
      return {
        ...state,
        queryByInvoice: {
          ...state.queryByInvoice,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          sum: action.sum,
          filter: action.filter,
        },
      }

    case ADD_SALES_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_SALES_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_SALES_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
      }

    case SALES_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }

    case RESET_SALES_FORM:
      return {
        ...state,
        form: { ...INITIAL_STATE.form },
      }

    case SALES_FORM_SET_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          item: {
            ...state.form.item,
            ...action.item,
          },
        },
      }

    case SALES_FORM_ADD_ITEM:
      const lastItem = state.form.item
      return {
        ...state,
        form: {
          ...state.form,
          items: [
            ...state.form.items,
            {
              ...state.form.item, // client-side only - allows editing
              key: _.uniqueId(),
            },
          ],
          item: {
            ...INITIAL_STATE.form.item,
            type: lastItem.type,
            productId: lastItem.productId ? null : undefined,
            stockId: lastItem.stockId ? null : undefined,
          },
        },
      }
    case SALES_FORM_REMOVE_ITEM:
      return update(state, {
        form: { items: { $splice: [[action.index, 1]] } },
      })

    case SALES_FORM_EDIT_ITEM:
      return update(state, {
        form: {
          item: { $set: state.form.items[action.index] },
        },
      })

    case SALES_FORM_UPDATE_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          items: _.map(state.form.items, (item) => {
            if (item.key === state.form.item.key) {
              return state.form.item
            }
            return item
          }),
          item: INITIAL_STATE.form.item,
        },
      }

    case SALES_FORM_MOVE_ITEM:
      const { fromIndex, toIndex } = action
      const moved = state.form.items.slice()
      moved.splice(toIndex, 0, moved.splice(fromIndex, 1)[0])

      return {
        ...state,
        form: {
          ...state.form,
          items: moved,
        },
      }

    default:
      return state
  }
}
