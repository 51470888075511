import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

const Prompt = ({
    when,
    message = 'Промените не са съхранени! Сигурни ли сте?' }) => {

    useBeforeunload((e) => !when() || e.preventDefault())
    usePrompt({ when, message })

    return null
}

export default Prompt