import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Header, Form, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'
import validator from 'validator'

import {
  update,
  formActions,
  UPDATE_RESOURCE_SUCCESS,
} from '../../actions/resources'
import ResourceForm from './ResourceForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class EditResource extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    // form actions
    setForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }

  handleUpdateResource = () => {
    const {
      form,
      resource: { id },
    } = this.props

    if (this.isValidResource()) {
      this.props.update(id, form).then((action) => {
        if (action.type === UPDATE_RESOURCE_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(`/resources/${id}`)
        }
      })
    }
  }

  isValidResource = () => {
    const { name, unitPrice, unit, density, densityUnit, gtn } = this.props.form
    return (
      _.trim(name) &&
      (_.isEmpty(gtn) || validator.isFloat(gtn + '', { min: 1 })) &&
      (_.isEmpty(density) ||
        (validator.isFloat(density + '', { gt: 0 }) && densityUnit)) &&
      validator.isFloat(unitPrice + '', { gt: 0 }) &&
      unit
    )
  }

  componentDidMount() {
    const { resource, setForm, form } = this.props
    this.uneditedForm = {
      ...form,
      ..._.mapValues(_.omit(resource, 'id', 'createdAt'), (v) =>
        v !== null ? v : ''
      ),
    }
    setForm(this.uneditedForm)
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, this.uneditedForm)

  render() {
    if (!this.props.resource) {
      return <Header>Няма такава суровина.</Header>
    }
    const { resource, form } = this.props
    return (
      <div>
        <Helmet title={resource.name} />

        <Menu attached='top' inverted color={COLORS.RESOURCE} borderless>
          <Menu.Item header icon='edit' content={form.name || resource.name} />
        </Menu>

        <Segment attached>
          <ResourceForm
            loading={this.props.isFetching}
            error={this.props.error}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='save'
                  content='Запази'
                  type='button'
                  disabled={!this.isValidResource() || !this.isDirty()}
                  onClick={this.handleUpdateResource}
                />
                <Form.Button
                  content='Отказ'
                  type='button'
                  basic
                  onClick={() => {
                    this.props.navigate(`/resources/${resource.id}`)
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (state, ownProps) => ({
    resource: state.resources.items[ownProps.params.id],
    form: state.resources.form,
    isFetching: state.resources.update.isFetching,
    error: state.resources.update.error,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        update,
        ...formActions,
      },
      dispatch
    )
)(EditResource))
