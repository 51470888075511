import _ from 'lodash'
import { Table, Segment, Loader, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { COLORS } from '../../utils'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { format } from 'd3-format'

const defaultColumns = new Set([
    'date',
    'invoice',
    'tradeDoc',
    'type',
    'buyer',
    'items',
    'paymentMethod',
    'total',
    // 'editLink',
])

export default ({ listing, loading, columns = defaultColumns }) => {
    const companies = useSelector(({ companies }) => companies.items)

    return (
        <div className='y-scrollable'>
            <Table
                selectable
                compact
                unstackable
                color={COLORS.SALE}
                size='small'
                singleLine
            >
                <Table.Header>
                    <Table.Row>
                        {columns.has('date') && <Table.HeaderCell textAlign='right'>дата</Table.HeaderCell>}
                        {columns.has('invoice') && (<Table.HeaderCell textAlign='right'>фактура</Table.HeaderCell>)}
                        {columns.has('tradeDoc') && (<Table.HeaderCell textAlign='right'>търг. док.</Table.HeaderCell>)}
                        {columns.has('buyer') && (<Table.HeaderCell>купувач</Table.HeaderCell>)}
                        {columns.has('total') && (<Table.HeaderCell textAlign='right'>сума</Table.HeaderCell>)}
                        {columns.has('paymentMethod') && (<Table.HeaderCell>начин на плащане</Table.HeaderCell>)}
                        {columns.has('type') && <Table.HeaderCell>вид</Table.HeaderCell>}
                        {columns.has('items') && (<Table.HeaderCell textAlign='right'>артикули</Table.HeaderCell>)}
                        {columns.has('editLink') && <Table.HeaderCell />}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {loading ? (
                        <Table.Row>
                            <Table.Cell colSpan={10}>
                                <Segment basic padded>
                                    <Loader active />
                                </Segment>
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        _.map(listing, (receipt) => {
                            const {
                                _id: receiptId,
                                soldOn,
                                type,
                                total,
                                invoice,
                                tradeDoc,
                                buyer,
                                paymentMethod,
                                items,
                            } = receipt

                            return (
                                <Table.Row key={receiptId}>
                                    {columns.has('date') && (
                                        <Table.Cell textAlign='right'>
                                            <Link to={`/sales/receipt/${receiptId}`}>
                                                {moment(soldOn).locale('bg').format('D MMM YYYY HH:mm')}
                                            </Link>
                                        </Table.Cell>
                                    )}
                                    {columns.has('invoice') && (
                                        <Table.Cell textAlign='right'>
                                            <Link to={`/sales/receipt/${receiptId}/print`}>
                                                {invoice || '<фактура>'}
                                            </Link>
                                        </Table.Cell>
                                    )}
                                    {columns.has('tradeDoc') && (
                                        <Table.Cell textAlign='right'>
                                            <Link to={`/sales/receipt/${receiptId}/print-tradedoc`}>
                                                {tradeDoc || '~'}
                                            </Link>
                                        </Table.Cell>
                                    )}
                                    {columns.has('buyer') && (
                                        <Table.Cell>
                                            {buyer && (
                                                <Link
                                                    title={companies[buyer]?.name}
                                                    to={`/companies/${buyer}`}
                                                >
                                                    {_.truncate(companies[buyer]?.companyName)}
                                                </Link>
                                            )}
                                        </Table.Cell>
                                    )}
                                    {columns.has('total') && (
                                        <Table.Cell textAlign='right'>
                                            <b>{_.isNil(total) ? '~' : `${format('.2f')(total)}`}</b>
                                        </Table.Cell>
                                    )}
                                    {columns.has('paymentMethod') && (
                                        <Table.Cell>{paymentMethod}</Table.Cell>
                                    )}
                                    {columns.has('type') && <Table.Cell>{type}</Table.Cell>}
                                    {columns.has('items') && (
                                        <Table.Cell textAlign='right'>{items}</Table.Cell>
                                    )}
                                    {columns.has('editLink') && (
                                        <Table.Cell textAlign='center' className='hidden-tbl-cell'>
                                            <Link
                                                to={`/sales/receipt/${receiptId}/edit`}
                                                className='show-on-hover'
                                            >
                                                <Icon name='edit' color='grey' />
                                            </Link>
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            )
                        })
                    )}
                </Table.Body>
            </Table>
        </div>
    )
}
