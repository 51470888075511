import { combineReducers } from 'redux'

import auth from './auth'
import resources from './resources'
import recipes from './recipes'
import products from './products'
import companies from './companies'
import stocks from './stocks'
import sales from './sales'
import revisions from './revisions'
import priceTiers from './price-tiers'

import { RESET } from '../actions/auth'

const makeRootReducer = () => {
  const rootReducer = combineReducers({
    auth,
    resources,
    recipes,
    products,
    companies,
    stocks,
    revisions,
    sales,
    priceTiers,
  })

  return (state, action) => {
    // reset global state (for example on logout)
    if (action.type === RESET) {
      state = undefined
    }
    return rootReducer(state, action)
  }
}

export const rootReducer = makeRootReducer()
