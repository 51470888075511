import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Segment, Header, Menu, Grid, Divider, Label } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'd3-format'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { createSelector } from 'reselect'

import {
  displayUnit,
  displayResourceUnit,
  getSalePriceUnit,
} from '../../common/unit-utils'
import { getStockTotals } from '../../stock-utils'
import StockListing from '../stocks/StockListing'
import RecipeListing from '../recipe/RecipeListing'
import PaginationControls from '../common/PaginationControls'
import RoleFilter from '../common/RoleFilter'
import { queryStocks } from '../../actions/stocks'
import { COLORS } from '../../utils'

const Resource = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [historyFilter, setHistoryFilter] = useState({ offset: 0, limit: 10 })

  const resource = useSelector(state => state.resources.items[params.id])
  const recipeListing = useSelector(state => getRecipeListing(state, params))
  const stockCurrentListing = useSelector(state => getStockCurrentListing(state, params))
  const stockHistoryListing = useSelector(state => getStockHistoryListing(state, params, historyFilter))
  const stockHistoryTotal = useSelector(state => getStockHistoryTotal(state, params))
  const totalStock = useSelector(state => _.get(getStockTotals(state), params.id))

  useEffect(() => {
    if (resource?.slug && resource.slug !== params.slug) {
      navigate(resource.slug, { replace: true })
    }
  }, [navigate, resource.slug, params.slug])

  useEffect(() => {
    dispatch(queryStocks(
      { offset: 0, limit: 10, resource: params.id, order: 'desc' },
      { cache: true }))
  }, [dispatch, params.id])

  if (!resource) {
    return <Header textAlign='center'>Няма такава суровина.</Header>
  }

  const {
    id,
    name,
    desc,
    unitPrice,
    unit,
    density,
    densityUnit,
    allergen,
    gtn,
    salePrice,
  } = resource

  return (
    <div className='resource-view'>
      <Helmet title={name} />

      <Menu
        attached='top'
        inverted
        color={COLORS.RESOURCE}
        borderless
        className='wrap'
      >
        <Menu.Item header content={name} />
        <Menu.Menu position='right'>
          <Menu.Item icon='edit' as={Link} to={`/resources/${id}/edit`} />
        </Menu.Menu>
      </Menu>

      <Segment attached>
        {allergen ? (
          <Label basic ribbon='right'>
            Алерген
          </Label>
        ) : null}

        <Grid columns={4} doubling>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Header.Content>{name}</Header.Content>
                <Header.Subheader>суровина</Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <RoleFilter>
                <Header>
                  <Header.Content>
                    {format('.2f')(unitPrice)} лв. {displayResourceUnit(unit)}
                  </Header.Content>
                  <Header.Subheader>цена</Header.Subheader>
                </Header>
              </RoleFilter>
            </Grid.Column>

            <Grid.Column>
              <Header>
                <Header.Content>
                  {_.isNil(salePrice)
                    ? '~'
                    : `${format('.2f')(salePrice)} лв./${displayUnit(
                      getSalePriceUnit(unit)
                    )}`}
                </Header.Content>
                <Header.Subheader>продажна цена</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Label color={totalStock.qty > 0 ? 'green' : 'red'} size='big'>
                {format('.3~f')(totalStock.qty)}
                <Label.Detail>{displayUnit(totalStock.unit)}</Label.Detail>
              </Label>
            </Grid.Column>

            <Grid.Column>
              <Header>
                <Header.Content>
                  {density ? `${density} ${displayUnit(densityUnit)}` : '~'}
                </Header.Content>
                <Header.Subheader>плътност</Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Header>
                <Header.Content>{format('.3~f')(gtn)}</Header.Content>
                <Header.Subheader>бруто/нето</Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Header>
                <Header.Content>{desc || '~'}</Header.Content>
                <Header.Subheader>описание</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Divider hidden />

      <Menu secondary pointing>
        {_.map(
          [
            ['', 'Наличности'],
            ['history', 'История'],
            ['recipes', 'Рецепти'],
          ],
          ([to, text], index) => (
            <NavLink to={to} key={index} end>
              {({ isActive }) => (<Menu.Item content={text} active={isActive} />)}
            </NavLink>
          )
        )}
      </Menu>

      <Routes>
        <Route
          path='/'
          element={
            <StockListing
              listing={stockCurrentListing}
              columns={['current', 'unitPrice', 'desc', 'delivery']}
            />}
        />
        <Route
          path='history'
          element={
            <>
              <PaginationControls
                {...{
                  ...historyFilter,
                  total: stockHistoryTotal,
                  setFilter: (filter) =>
                    setHistoryFilter({ ...historyFilter, ...filter }),
                }}
              />
              <StockListing
                listing={stockHistoryListing}
                columns={['current', 'unitPrice', 'desc', 'delivery', 'revise-to-zero']}
              />
            </>}
        />
        <Route
          path='recipes'
          element={<RecipeListing listing={recipeListing} />} />
      </Routes>
    </div>
  )
}

const getStocks = (state) => state.stocks.items
const getResourceId = (state, params) => params.id
const getFilter = (state, params, filter) => filter

const getStocksByResource = createSelector(
  [getStocks, getResourceId],
  (items, resourceId) => _.chain(items)
    .filter(({ resource }) => resource === resourceId)
    .sortBy(({ seqId }) => -seqId)
    .value()
)

const getStockCurrentListing = createSelector(
  [getStocksByResource],
  (stocks) => _.filter(stocks, ({ curQty }) => curQty > 0)
)

const getStockHistoryListing = createSelector(
  [getStocksByResource, getFilter],
  (stocks, { offset, limit }) => _.chain(stocks)
    .drop(offset)
    .take(limit)
    .value()
)

const getStockHistoryTotal = createSelector(
  [getStocksByResource],
  (stocks) => _.size(stocks)
)

const getRecipeListing = createSelector(
  (state) => state.recipes.items,
  (state, params) => params.id,
  (items, resourceId) => {
    return _.chain(items)
      .filter(({ ingredients }) => _.some(ingredients, { resourceId }))
      .sortBy(({ name }) => -name)
      .value()
  }
)

export default Resource
