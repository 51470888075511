import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Table } from 'semantic-ui-react'

import { add, removePriceTier, update } from '../../actions/price-tiers'
import { update as updateRecipe } from '../../actions/recipes'
import { Link } from 'react-router-dom'
import { COLORS } from '../../utils'

const PriceTiers = () => {
  const recipes = useSelector((state) =>
    _.chain(state.recipes.items).filter('salePrice').sortBy('name').value()
  )
  const tiers = useSelector((state) => state.priceTiers.items)
  const dispatch = useDispatch()

  const [newTier, setNewTier] = useState(null)
  const [editedTier, setEditedTier] = useState(null)
  const [editedRecipeTier, setEditedRecipeTier] = useState(null)

  return (
    <div className='y-scrollable'>
      <Table celled color={COLORS.SALE} unstackable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Продукт</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>[цена]</Table.HeaderCell>
            {_.map(tiers, ({ id, name }) => {
              // const canBeDeleted = _.some(recipes, ({ salePrices }) =>
              //   _.some(salePrices, { tier: id })
              // )
              return (
                <Table.HeaderCell
                  key={id}
                  textAlign='right'
                  onClick={() => setEditedTier(id)}
                >
                  {editedTier === id ? (
                    <Input
                      autoFocus
                      defaultValue={name}
                      onKeyDown={({ key, target }) => {
                        if (key === 'Enter') {
                          if (target.value === '') {
                            dispatch(removePriceTier(id))
                          } else {
                            dispatch(update(id, { name: target.value }))
                          }
                          setEditedTier(null)
                        }
                        if (key === 'Escape') {
                          setEditedTier(null)
                        }
                      }}
                    />
                  ) : (
                    name
                  )}
                </Table.HeaderCell>
              )
            })}
            <Table.HeaderCell textAlign='center' collapsing>
              {newTier === null ? (
                <Button size='mini' icon='add' onClick={() => setNewTier('')} />
              ) : (
                <Input
                  autoFocus
                  onKeyDown={({ key, target }) => {
                    if (key === 'Enter') {
                      dispatch(add({ name: target.value }))
                      setNewTier(null)
                    }
                    if (key === 'Escape') {
                      setNewTier(null)
                    }
                  }}
                />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(recipes, (recipe) => {
            return (
              <Table.Row key={recipe.id}>
                <Table.Cell>
                  <Link to={`/recipes/${recipe.id}`}>{recipe.name}</Link>
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  <em>{recipe.salePrice.toFixed(2)}</em>
                </Table.Cell>
                {_.map(tiers, (tier) => {
                  const isEdited = _.isEqual(editedRecipeTier, [
                    recipe.id,
                    tier.id,
                  ])
                  const salePrice = _.get(
                    _.find(recipe.salePrices, { tier: tier.id }),
                    'price'
                  )
                  return (
                    <Table.Cell
                      selectable
                      style={{ paddingRight: '1em' }}
                      key={tier.id}
                      textAlign='right'
                      onClick={() => setEditedRecipeTier([recipe.id, tier.id])}
                    >
                      {isEdited && (
                        <Input
                          defaultValue={salePrice}
                          autoFocus
                          onKeyDown={({ key, target }) => {
                            if (key === 'Enter') {
                              dispatch(
                                updateRecipe(recipe.id, {
                                  salePrices: [
                                    ..._.reject(recipe.salePrices, {
                                      tier: tier.id,
                                    }),
                                    ...(target.value === ''
                                      ? []
                                      : [
                                          {
                                            tier: tier.id,
                                            price: target.value,
                                          },
                                        ]),
                                  ],
                                })
                              )
                              setEditedRecipeTier(null)
                            }
                            if (key === 'Escape') {
                              setEditedRecipeTier(null)
                            }
                          }}
                        />
                      )}
                      {!isEdited && salePrice && (
                        <strong>{salePrice.toFixed(2)}</strong>
                      )}
                    </Table.Cell>
                  )
                })}
                <Table.Cell />
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default PriceTiers
