import io from 'socket.io-client'

import { sync } from './actions/sync'

export const configureSocket = (store) => {
  const socket = io()

  socket.on('connect', () => {
    // passed as "x-socketid" header on API requests
    global.socketId = socket.id
  })

  socket.on('sync', (update) => {
    store.dispatch(sync(update))
  })
}
