import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Message,
  Dropdown,
  Header,
  Icon,
  Table,
  Segment,
  Label,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'd3-format'

import { DateInput, TimeInput } from 'semantic-ui-calendar-react'

import { formActions } from '../../actions/stocks'
import { clickToFocus, focus, getLabel, COLORS } from '../../utils'
import { displayUnit, getValidStockUnits } from '../../common/unit-utils'
import DecimalInput from '../common/DecimalInput'
import validator from 'validator'

export class StockForm extends Component {
  static propTypes = {
    // is editing an existing delivery (disable some fields)
    editMode: PropTypes.bool,

    loading: PropTypes.bool,
    error: PropTypes.string,
    buttonGroup: PropTypes.node.isRequired,
    // connect
    form: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    companies: PropTypes.object.isRequired,
    // form actions
    setForm: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    copyItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  }

  state = {
    bioCertOptions: [
      { text: '<няма>', value: '' },
      { text: 'BG-BIO-18', value: 'BG-BIO-18' },
      { text: 'BG-BIO-22', value: 'BG-BIO-22' },
      { text: 'ФП', value: 'ФП' },
    ],
  }

  handleAddBioCertItem = (e, { value }) => {
    this.setState({
      bioCertOptions: [...this.state.bioCertOptions, { text: value, value }],
    })
  }

  getResourceOptions = () => {
    return _.map(this.props.resources, ({ id, name, desc }) => ({
      key: id,
      value: id,
      text: name,
      content: (
        <Header size='tiny'>
          <Icon name='circle' color={COLORS.RESOURCE} />
          <Header.Content>{name}</Header.Content>
          <Header.Subheader>
            {_.truncate(desc, { length: 65 })}
          </Header.Subheader>
        </Header>
      ),
    }))
  }

  getSupplierOptions = () => {
    return _.map(this.props.companies, ({ id, name, notes }) => ({
      key: id,
      value: id,
      text: name,
      content: (
        <Header size='tiny'>
          <Icon name='circle' color={COLORS.COMPANY} />
          <Header.Content>{name}</Header.Content>
          <Header.Subheader>
            {_.truncate(notes, { length: 65 })}
          </Header.Subheader>
        </Header>
      ),
    }))
  }

  getStockUnitOptions = () => {
    const {
      form: {
        item: { resource },
      },
      resources,
    } = this.props
    return _.map(getValidStockUnits(resources[resource]), (u) => ({
      key: u,
      value: u,
      text: displayUnit(u),
    }))
  }

  handleFormChange = (e, { name, value }) => {
    this.props.setForm({ [name]: value })
  }

  handleFormItemChange = (e, { name, value }) => {
    this.setFormItem({ [name]: value })
  }

  setFormItem = (update) => {
    const { item } = this.props.form
    this.props.setForm({ item: { ...item, ...update } })
  }

  handleAddOrUpdateItem = () => {
    if (this.isValidItem()) {
      const { item } = this.props.form
      item.expiry =
        item.expiry && moment(item.expiry, 'D-M-YYYY').format('DD-MM-YYYY')
      if (!item.key) {
        this.props.addItem()
      } else {
        this.props.updateItem()
      }
      _.defer(() => focus('[name=resource]>input'))
    }
  }

  isValidItem = () => {
    const {
      item: { resource, batches, quantity, price, expiry },
    } = this.props.form
    return (
      resource &&
      validator.isInt(batches + '', { gt: 0 }) &&
      validator.isFloat(quantity + '', { gt: 0 }) &&
      validator.isFloat(price + '', { gt: 0 }) &&
      (!expiry || moment(expiry, 'D-M-YYYY').isValid())
    )
  }

  render() {
    const {
      // per delivery
      supplier,
      arrivalDate,
      arrivalTime,
      receipt,
      // delivery items and form item
      items,
      item,
    } = this.props.form
    const { setForm, resources, editMode } = this.props

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        loading={this.props.loading}
        error={!!this.props.error}
      >
        <Form.Group widths='equal'>
          <Form.Select
            label='Доставчик'
            required
            searchInput={{ autoFocus: true }}
            name='supplier'
            placeholder='доставчик'
            search
            openOnFocus={false}
            noResultsMessage='Няма открити доставчици.'
            options={this.getSupplierOptions()}
            value={supplier}
            onChange={(e, { value }) => {
              // prevent re-render on scrolling
              if (!e.code || e.code === 'Enter') {
                setForm({ supplier: value })
              }
              if (e.code === 'Enter') {
                _.defer(() => focus('[name=receipt]'))
              }
            }}
          />

          <Form.Input
            label='Документ'
            placeholder='фактура или стокова разписка'
            name='receipt'
            value={receipt}
            onChange={this.handleFormChange}
          />

          <Form.Field required>
            <label>Дата</label>
            <DateInput
              disabled={false && editMode}
              closable
              placeholder='дата'
              name='arrivalDate'
              value={arrivalDate}
              onChange={this.handleFormChange}
            />
          </Form.Field>

          <Form.Field required>
            <label>Час</label>
            <TimeInput
              disabled={false && editMode}
              closable
              popupPosition='bottom right'
              placeholder='час'
              name='arrivalTime'
              value={arrivalTime}
              onChange={this.handleFormChange}
            />
          </Form.Field>
        </Form.Group>

        {/*  items */}
        <div className='y-scrollable'>
          <Table
            compact
            size='small'
            unstackable
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>суровина</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>
                  количество
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
                <Table.HeaderCell>партиден №</Table.HeaderCell>
                <Table.HeaderCell>производител</Table.HeaderCell>
                <Table.HeaderCell>произход</Table.HeaderCell>
                <Table.HeaderCell>годност</Table.HeaderCell>
                <Table.HeaderCell>съхранение</Table.HeaderCell>
                <Table.HeaderCell>био серт.</Table.HeaderCell>
                <Table.HeaderCell>описание</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.isEmpty(items) ? (
                <Table.Row>
                  <Table.Cell colSpan={10} textAlign='center'>
                    Доставката е празна
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {_.map(items, (item, index) => {
                const resource = this.props.resources[item.resource]
                const isExpired =
                  item.curQty > 0 &&
                  item.expiry &&
                  moment(item.expiry, 'DD-MM-YYYY').isBefore()

                return (
                  <Table.Row
                    key={item.key}
                    disabled={item.key === this.props.form.item.key}
                  >
                    <Table.Cell>
                      {item.id ? (
                        <Label
                          color={COLORS.STOCK}
                          style={{ opacity: item.curQty > 0 ? 1 : 0.5 }}
                          size='small'
                          tag
                          content={getLabel(item)}
                        />
                      ) : null}
                    </Table.Cell>
                    <Table.Cell>{resource.name}</Table.Cell>
                    <Table.Cell textAlign='right'>
                      {item.batches} x {item.quantity}
                    </Table.Cell>
                    <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                      {displayUnit(item.unit)}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {`${format('.2f')(item.price)} лв.`}
                    </Table.Cell>
                    <Table.Cell>{item.batchId}</Table.Cell>
                    <Table.Cell>{item.producer}</Table.Cell>
                    <Table.Cell>{item.origin}</Table.Cell>
                    <Table.Cell negative={isExpired}>
                      {item.expiry &&
                        moment(item.expiry, 'DD-MM-YYYY').format('DD/MM/YY')}
                    </Table.Cell>
                    <Table.Cell>{item.storage}</Table.Cell>
                    <Table.Cell>{item.bioCert}</Table.Cell>
                    <Table.Cell>{item.desc}</Table.Cell>
                    <Table.Cell>
                      <Icon
                        name='edit'
                        link
                        onClick={() => this.props.copyItem(index)}
                      />
                      {!item.id ? (
                        <Icon
                          name='trash'
                          link
                          onClick={() => this.props.removeItem(index)}
                        />
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

            {!_.isEmpty(items) ? (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.HeaderCell textAlign='right'>
                    {`${format('.2f')(
                      _.sumBy(items, (i) => parseFloat(i.price))
                    )} лв.`}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            ) : null}
          </Table>
        </div>

        {/*  item */}
        <Segment secondary basic>
          <Form.Group widths='equal'>
            <Form.Select
              disabled={editMode && !!item.id}
              label='Суровина'
              required
              name='resource'
              placeholder='суровина'
              search
              openOnFocus={false}
              noResultsMessage='Няма открити суровини.'
              options={this.getResourceOptions()}
              value={item.resource}
              onChange={(e, { value }) => {
                // prevent re-render on scrolling
                if (!e.code || e.code === 'Enter') {
                  this.setFormItem({
                    resource: value,
                    unit: _.get(
                      getValidStockUnits(resources[value]),
                      '[0]',
                      ''
                    ),
                  })
                }
                if (e.code === 'Enter') {
                  _.defer(() => focus('[name=batches]'))
                }
              }}
            />

            <Form.Input
              label='Брой'
              placeholder='брой'
              required
              name='batches'
              value={item.batches}
              type='number'
              min={1}
              onChange={this.handleFormItemChange}
            />

            <Form.Field className='required'>
              <label>Разфасофка</label>
              <DecimalInput
                placeholder='разфасофка'
                name='quantity'
                value={item.quantity}
                fluid
                labelPosition='right'
                onChange={this.handleFormItemChange}
                onKeyPress={({ key }) =>
                  key === 'Enter' && clickToFocus('#unit-dropdown')
                }
                label={
                  <Dropdown
                    id='unit-dropdown'
                    placeholder='м. ед.'
                    value={item.unit}
                    disabled={!item.resource}
                    options={this.getStockUnitOptions()}
                    onChange={(e, { value }) => {
                      this.setFormItem({ unit: value })
                      e.code === 'Enter' && focus('[name=price]')
                    }}
                  />
                }
              />
            </Form.Field>

            <Form.Field className='required'>
              <label>Цена</label>
              <DecimalInput
                placeholder='Обща цена'
                required
                name='price'
                value={item.price}
                onChange={this.handleFormItemChange}
                fluid
                labelPosition='right'
                label='лв.'
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input
              label='Партиден №'
              placeholder='външен партиден №'
              name='batchId'
              value={item.batchId}
              onChange={this.handleFormItemChange}
            />

            <Form.Input
              label='Производител'
              placeholder='производител'
              name='producer'
              value={item.producer}
              onChange={this.handleFormItemChange}
            />

            <Form.Input
              label='Произход'
              placeholder='страна на произход'
              name='origin'
              value={item.origin}
              onChange={this.handleFormItemChange}
            />

            <Form.Field>
              <label>Срок на годност</label>
              <DateInput
                animation=''
                placeholder='срок на годност'
                closable
                name='expiry'
                value={item.expiry}
                onChange={this.handleFormItemChange}
                onBlur={() => {
                  const m = moment(item.expiry, 'D-M-YYYY')
                  if (m.isValid()) {
                    this.setFormItem({ expiry: m.format('DD-MM-YYYY') })
                  }
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input
              label='Съхранение'
              placeholder='условия на съхранение'
              name='storage'
              value={item.storage}
              onChange={this.handleFormItemChange}
            />

            <Form.Select
              label='Био сертификат'
              placeholder='био сертификат'
              name='bioCert'
              search
              allowAdditions
              openOnFocus={false}
              additionLabel='Добави: '
              onAddItem={this.handleAddBioCertItem}
              options={this.state.bioCertOptions}
              value={item.bioCert}
              onChange={this.handleFormItemChange}
            />

            <Form.Input
              label='Описание'
              placeholder='описание'
              name='desc'
              value={item.desc}
              onChange={this.handleFormItemChange}
            />
          </Form.Group>

          {!item.key ? (
            <Form.Button
              type='button'
              size='small'
              icon='add'
              content='Добави към доставка'
              onClick={this.handleAddOrUpdateItem}
              disabled={!this.isValidItem()}
            />
          ) : (
            <Form.Button
              type='button'
              size='small'
              icon='edit'
              content='Редактирай'
              onClick={this.handleAddOrUpdateItem}
              disabled={!this.isValidItem()}
            />
          )}
        </Segment>

        {this.props.buttonGroup}
        <Message error content={this.props.error} />
      </Form>
    )
  }
}

export default connect(
  (state) => ({
    form: state.stocks.form,
    resources: state.resources.items,
    companies: state.companies.items,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        ...formActions,
      },
      dispatch
    )
)(StockForm)
