import _ from 'lodash'
import { isAfter, parse, startOfToday } from 'date-fns'

export const PAYMENT_METHODS = ['в брой', 'наложен платеж', 'по сметка']

/**
 * VAT: Value Added Tax
 */
export const VATActiveFrom = parse('2024-07-16', 'yyyy-MM-dd', new Date())

export const isVATActive = (fromDate) => {
  return isAfter(fromDate || startOfToday(), VATActiveFrom)
}

export const VATRate = 0.2

export const withoutVAT = (amount) => _.round(amount / (1 + VATRate), 2)

export const extractVAT = (amountAfterVAT) =>
  _.round((amountAfterVAT * VATRate) / (1 + VATRate), 2)
