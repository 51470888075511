import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Segment, Menu, Button } from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

import SalesForm from './SalesForm'
import {
  ADD_SALES_SUCCESS,
  addSales,
  setSalesForm,
  resetSalesForm,
} from '../../actions/sales'
import { INITIAL_STATE } from '../../reducers/sales'
import { COLORS } from '../../utils'

export default () => {
  const navigate = useNavigate()
  const {
    form,
    add: { isFetching, error },
  } = useSelector((state) => state.sales)
  const dispatch = useDispatch()

  const isSaved = useRef(false)
  const [uneditedForm] = useState(() => {
    const now = moment()
    return {
      ...INITIAL_STATE.form,
      soldOnDate: now.format('DD-MM-YYYY'),
      soldOnTime: now.format('HH:mm'),
    }
  })

  // effects
  useEffect(() => {
    dispatch(setSalesForm(uneditedForm))

    // unmount cleanup
    return () => {
      dispatch(resetSalesForm())
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)
  const isDirtyItem = () => {
    const [a, b] = [form.item, INITIAL_STATE.form.item]
    return (
      // skip preset values - productId, stockId, type
      !_.isNil(a.productId) ||
      !_.isNil(a.stockId) ||
      a.qty !== b.qty ||
      a.price !== b.price ||
      a.unitPrice !== b.unitPrice ||
      a.notes !== b.notes
    )
  }

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  const handleAddSales = () => {
    dispatch(
      addSales({
        items: form.items,
        buyer: form.buyer,
        type: form.type,
        invoice: form.invoice,
        tradeDoc: form.tradeDoc,
        paymentMethod: form.paymentMethod,
        soldOn: moment(
          [form.soldOnDate, form.soldOnTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
      })
    ).then((action) => {
      if (action.type === ADD_SALES_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        const receiptId = _.get(action.payload, [0, 'receiptId'])
        navigate(`/sales/receipt/${receiptId}`)
      }
    })
  }

  return (
    <>
      <Helmet title='Нови продажби - Pantry' />
      <Menu
        attached='top'
        borderless
        inverted
        color={COLORS.SALE}
        style={{ opacity: 0.75 }}
      >
        <Menu.Item header content='Нови продажби' />
      </Menu>

      <Segment attached>
        <SalesForm
          loading={isFetching}
          error={error}
          buttonGroup={
            <div>
              <Button
                positive
                disabled={!isFormValid(form) || isDirtyItem()}
                loading={isFetching}
                content='Съхрани'
                icon='save'
                onClick={handleAddSales}
              />
              {/* <Button
                content='Изчисти'
                icon='refresh'
                disabled={!isDirty()}
                negative
                onClick={() => {
                  window.confirm('Изчисти?') &&
                    dispatch(setSalesForm(uneditedForm))
                }}
              /> */}
            </div>
          }
        />
      </Segment>
    </>
  )
}

const isFormValid = (form) => {
  return (
    !_.isEmpty(form.items) &&
    moment(form.soldOnDate, 'D-M-YYYY').isValid() &&
    moment(form.soldOnTime, 'H:m').isValid()
  )
}
