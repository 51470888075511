import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Menu, Form, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'

import { add, formActions, ADD_COMPANY_SUCCESS } from '../../actions/companies'
import { INITIAL_STATE } from '../../reducers/companies'
import CompanyForm from './CompanyForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class NewCompany extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    add: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    // form actions
    resetForm: PropTypes.func.isRequired,
  }

  state = {
    confirmReset: false,
  }

  handleAdd = () => {
    if (this.isValid()) {
      let company = { ...this.props.form }
      this.props.add(company).then((action) => {
        if (action.type === ADD_COMPANY_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(`/companies/${action.payload.id}`)
        }
      })
    }
  }

  isValid = () => {
    let { name } = this.props.form
    return _.trim(name)
  }

  componentDidMount() {
    this.props.resetForm()
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, INITIAL_STATE.form)

  render() {
    const { confirmReset } = this.state
    return (
      <div>
        <Helmet title='Нова фирма' />

        <Menu
          attached='top'
          borderless
          inverted
          color={COLORS.COMPANY}
          style={{ opacity: 0.75 }}
        >
          <Menu.Item header content='Нова фирма' />
        </Menu>

        <Segment attached>
          <CompanyForm
            loading={this.props.isFetching}
            error={this.props.error}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='add'
                  content='Добави'
                  type='button'
                  disabled={!this.isValid()}
                  onClick={this.handleAdd}
                />
                <Form.Button
                  content='Изчисти'
                  type='button'
                  basic
                  disabled={!this.isDirty()}
                  onClick={() => this.setState({ confirmReset: true })}
                />
                <Confirm
                  content='Изчисти рецепта?'
                  cancelButton='Отказ'
                  confirmButton='Изчисти'
                  size='tiny'
                  open={confirmReset}
                  onCancel={() => this.setState({ confirmReset: false })}
                  onConfirm={() => {
                    this.setState({ confirmReset: false })
                    this.props.resetForm()
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (state) => ({
    form: state.companies.form,
    isFetching: state.companies.add.isFetching,
    error: state.companies.add.error,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        add,
        ...formActions,
      },
      dispatch
    )
)(NewCompany))
