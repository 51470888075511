import _ from 'lodash'
import React from 'react'
import {
  Table,
  Label,
  Icon,
  Loader,
  Segment,
  Progress,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { format } from 'd3-format'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { displayUnit, secondaryUnits } from '../../common/unit-utils'
import { productCost } from '../../common/unit-utils'
import { getProductLabel, displayProductCost, COLORS } from '../../utils'
import RoleFilter from '../common/RoleFilter'

export default ({ listing, loading }) => {
  const products = useSelector((state) => state.products.items)
  const resources = useSelector((state) => state.resources.items)
  const recipes = useSelector((state) => state.recipes.items)
  const stocks = useSelector((state) => state.stocks.items)

  if (loading) {
    return (
      <Segment basic>
        <Loader active />
      </Segment>
    )
  }

  return (
    <div className='y-scrollable'>
      <Table
        selectable
        compact
        unstackable
        color={COLORS.PRODUCT}
        size='small'
        singleLine
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>продукт №</Table.HeaderCell>
            <Table.HeaderCell>час</Table.HeaderCell>
            <Table.HeaderCell>наименование</Table.HeaderCell>
            <Table.HeaderCell>остава</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' colSpan={2}>
              количество
            </Table.HeaderCell>
            <RoleFilter>
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' colSpan={2}>
                норм. цена
              </Table.HeaderCell>
            </RoleFilter>
            <Table.HeaderCell>описание</Table.HeaderCell>
            <Table.HeaderCell>от</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.isEmpty(listing) && (
            <Table.Row>
              <Table.Cell colSpan={16} textAlign='center' disabled>
                <em>Няма продукти</em>
              </Table.Cell>
            </Table.Row>
          )}
          {_.map(listing, (product) => {
            const {
              id,
              name,
              desc,
              curQty,
              qty,
              unit,
              ingredients,
              recipeId,
              producedOn,
              createdBy,
            } = product
            const cost = productCost(ingredients, resources, stocks, products)
            const secCost = secondaryUnits({ cost, qty, unit })

            return (
              <Table.Row key={id}>
                <Table.Cell>
                  <Label
                    color={COLORS.PRODUCT}
                    style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                    tag
                    as={Link}
                    to={`/products/${id}`}
                    content={getProductLabel(product)}
                  />
                </Table.Cell>
                <Table.Cell>{moment(producedOn).format('HH:mm')}</Table.Cell>
                <Table.Cell>
                  {name}{' '}
                  {recipeId ? (
                    <Link to={`/recipes/${recipeId}`}>
                      <Icon
                        color={COLORS.RECIPE}
                        name='chain'
                        title={_.get(recipes, [recipeId, 'name'])}
                      />
                    </Link>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  <Progress
                    value={curQty}
                    total={qty}
                    indicating
                    error={curQty < 0}
                    size='tiny'
                    label={`${format('.3~f')(curQty)} ${displayUnit(unit)}`}
                  />
                </Table.Cell>
                <Table.Cell textAlign='right'>{format('.3~f')(qty)}</Table.Cell>
                <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                  {displayUnit(unit)}
                </Table.Cell>

                <RoleFilter>
                  <Table.Cell textAlign='right'>
                    {displayProductCost(cost, ' лв.')}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {displayProductCost(secCost.cost)}
                  </Table.Cell>
                  <Table.Cell textAlign='left' style={{ paddingLeft: 0 }}>
                    {`/ ${secCost.qty} ${displayUnit(secCost.unit)}`}
                  </Table.Cell>
                </RoleFilter>

                <Table.Cell>{_.truncate(desc)}</Table.Cell>
                <Table.Cell>{_.get(createdBy, 'username')}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}
