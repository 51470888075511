import _ from 'lodash'
import React, { useState } from 'react'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react'
import {
  Form,
  Grid,
  Table,
  Segment,
  Button,
  Dropdown,
  Header,
  Label,
  Icon,
  Dimmer,
  Message,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'd3-format'
import validator from 'validator'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { COLORS, getProductLabel, getLabel, focus } from '../../utils'
import {
  setRevisionsFormItem,
  addRevisionsFormItem,
  updateRevisionsFormItem,
  setRevisionsForm,
  editRevisionsFormItem,
  removeRevisionsFormItem,
  moveRevisionsFormItem,
  calcQtyBefore,
} from '../../actions/revisions'
import { displayUnit } from '../../common/unit-utils'
import DecimalInput from '../common/DecimalInput'
import DraggableRow from '../RecipeForm/DraggableRow'
import RoleFilter from '../common/RoleFilter'

export default ({ edit, loading, error, buttonGroup }) => {
  const {
    form,
    calcQtyBefore: { isFetching },
  } = useSelector((state) => state.revisions)
  const products = useSelector((state) => state.products.items)
  const stocks = useSelector((state) => state.stocks.items)
  const resources = useSelector((state) => state.resources.items)
  const dispatch = useDispatch()

  // helper variables
  const isProduct = _.isUndefined(form.item.stock)
  const revisionTime = moment(
    `${form.revisedAtDate} ${form.revisedAtTime}`,
    'DD-MM-YYYY HH:mm',
    true
  )
  const isValidTime = revisionTime.isValid()

  return (
    <>
      <Form loading={loading}>
        <Grid doubling>
          <Grid.Row>
            <Grid.Column computer={4} mobile={8}>
              <Form.Field required>
                <label>Дата</label>
                <DateInput
                  readOnly={!_.isEmpty(form.items)}
                  fluid
                  closable
                  placeholder='дата'
                  value={form.revisedAtDate}
                  onChange={(e, { value }) =>
                    dispatch(setRevisionsForm({ revisedAtDate: value }))
                  }
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column computer={4} mobile={8}>
              <Form.Field required>
                <label>Час</label>
                <TimeInput
                  readOnly={!_.isEmpty(form.items)}
                  fluid
                  closable
                  popupPosition='bottom right'
                  placeholder='час'
                  value={form.revisedAtTime}
                  onChange={(e, { value }) =>
                    dispatch(setRevisionsForm({ revisedAtTime: value }))
                  }
                />
              </Form.Field>
            </Grid.Column>

            {!edit && (
              <Grid.Column computer={8} mobile={16}>
                <Form.Input
                  label='Общи бележки'
                  placeholder='Общи бележки'
                  value={form.notes}
                  onChange={(e, { value }) => {
                    dispatch(setRevisionsForm({ notes: value }))
                  }}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Form>

      {!edit && (
        <div className='y-scrollable'>
          <Table selectable unstackable compact singleLine size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>етикет</Table.HeaderCell>
                <Table.HeaderCell>наименование</Table.HeaderCell>
                <Table.HeaderCell>бележки</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>преди</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>след</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Δ</Table.HeaderCell>
                <Table.HeaderCell />
                <RoleFilter>
                  <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
                </RoleFilter>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(form.items, (item, index) => {
                const product = item.product && products[item.product]
                const stock = item.stock && stocks[item.stock]
                const id = item.product || item.stock
                const name = product
                  ? product.name
                  : _.get(resources[stock.resource], 'name')
                const delta = item.newQty - item.curQty
                const cost =
                  delta *
                  (product
                    ? product.cost / product.qty
                    : stock.price / (stock.quantity * stock.batches))

                return (
                  <DraggableRow
                    key={id}
                    index={index}
                    moveIngredient={(fromIndex, toIndex) =>
                      dispatch(moveRevisionsFormItem(fromIndex, toIndex))
                    }
                    disabled={item.key === form.item.key}
                  >
                    <Table.Cell>
                      {product ? (
                        <Label
                          color={COLORS.PRODUCT}
                          style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                          tag
                          as={Link}
                          to={`/products/${product.id}`}
                          content={getProductLabel(product)}
                        />
                      ) : (
                        <Label
                          color={COLORS.STOCK}
                          style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                          tag
                          as={Link}
                          to={`/stocks/${stock.id}`}
                          content={getLabel(stock)}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{item.notes}</Table.Cell>
                    <Table.Cell textAlign='right'>{item.curQty}</Table.Cell>
                    <Table.Cell textAlign='right'>
                      <strong>{item.newQty}</strong>
                    </Table.Cell>
                    <Table.Cell
                      textAlign='right'
                      negative={delta < 0}
                      positive={delta > 0}
                    >
                      {format('.3~f')(delta)}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: 0 }}
                      negative={delta < 0}
                      positive={delta > 0}
                    >
                      {displayUnit(item.unit)}
                    </Table.Cell>
                    <RoleFilter>
                      <Table.Cell
                        textAlign='right'
                        negative={cost < 0}
                        positive={cost > 0}
                      >{`${format('.2f')(cost)} лв.`}</Table.Cell>
                    </RoleFilter>
                    <Table.Cell textAlign='right'>
                      <Icon
                        color='grey'
                        title='Редактирай'
                        name='edit'
                        link
                        onClick={() => {
                          dispatch(editRevisionsFormItem(index))
                        }}
                      />
                      &nbsp;
                      <Icon
                        color='grey'
                        title='Изтрий'
                        name='remove'
                        link
                        onClick={() => {
                          dispatch(removeRevisionsFormItem(index))
                        }}
                      />
                    </Table.Cell>
                  </DraggableRow>
                )
              })}
            </Table.Body>
          </Table>
        </div>
      )}

      <Dimmer.Dimmable as={Segment} secondary basic dimmed={!isValidTime}>
        <Dimmer inverted active={!isValidTime} />
        <Form loading={loading}>
          <Grid doubling>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Form.Field>
                  <label>Категория</label>
                  <Button.Group fluid>
                    <Button
                      disabled={edit || form.item.key}
                      type='button'
                      color={isProduct ? COLORS.PRODUCT : null}
                      content='продукт'
                      onClick={() => {
                        dispatch(
                          setRevisionsFormItem({
                            product: null,
                            stock: undefined,
                          })
                        )
                      }}
                    />
                    <Button
                      disabled={edit || form.item.key}
                      type='button'
                      color={!isProduct ? COLORS.STOCK : null}
                      content='наличност'
                      onClick={() => {
                        dispatch(
                          setRevisionsFormItem({
                            stock: null,
                            product: undefined,
                          })
                        )
                      }}
                    />
                  </Button.Group>
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={8} mobile={16}>
                <Form.Input
                  label='Бележки'
                  value={form.item.notes}
                  placeholder='бележки'
                  onChange={(e, { value }) => {
                    dispatch(setRevisionsFormItem({ notes: value }))
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Form.Field>
                  <label>{isProduct ? 'Продукт' : 'Наличност'}</label>
                  {isProduct ? (
                    <ProductSelect
                      disabled={form.item.key || edit}
                      id='item-select-dropdown'
                      value={form.item.product}
                      exclude={
                        form.item.key
                          ? []
                          : _.flatMap(
                              form.items,
                              ({ product }) => product || []
                            )
                      }
                      onChange={({ key }, { value, product }) => {
                        dispatch(
                          setRevisionsFormItem({
                            product: value,
                            stock: undefined,
                            unit: product.unit,
                            curQty: product.curQty,
                          })
                        )
                        dispatch(
                          calcQtyBefore(
                            'product',
                            value,
                            revisionTime.toISOString()
                          )
                        )
                        key === 'Enter' &&
                          _.defer(() => focus('#quantity-input'))
                      }}
                    />
                  ) : (
                    <StockSelect
                      disabled={form.item.key || edit}
                      id='item-select-dropdown'
                      value={form.item.stock}
                      exclude={_.flatMap(
                        form.items,
                        ({ stock }) => stock || []
                      )}
                      onChange={({ key }, { value, stock }) => {
                        dispatch(
                          setRevisionsFormItem({
                            stock: value,
                            product: undefined,
                            unit: stock.unit,
                            curQty: stock.curQty,
                          })
                        )
                        dispatch(
                          calcQtyBefore(
                            'stock',
                            value,
                            revisionTime.toISOString()
                          )
                        )
                        key === 'Enter' &&
                          _.defer(() => focus('#quantity-input'))
                      }}
                    />
                  )}
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={4} mobile={8}>
                <Form.Field>
                  <label>Kоличество преди</label>
                  {(form.item.stock || form.item.product) && (
                    <Label size='large'>
                      {isFetching ? (
                        <Icon name='spinner' loading />
                      ) : (
                        format('.3~f')(form.item.curQty)
                      )}
                      <Label.Detail>{displayUnit(form.item.unit)}</Label.Detail>
                    </Label>
                  )}
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={4} mobile={8}>
                <Form.Field required>
                  <label>Количество след</label>
                  <DecimalInput
                    fluid
                    id='quantity-input'
                    label={displayUnit(form.item.unit)}
                    labelPosition='right'
                    placeholder='ново количество'
                    value={form.item.newQty}
                    onChange={(e, { value }) => {
                      dispatch(setRevisionsFormItem({ newQty: value }))
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            {!edit && (
              <Grid.Row>
                <Grid.Column>
                  {!form.item.key ? (
                    <Form.Button
                      disabled={!isValidItem(form.item)}
                      icon='add'
                      content='Добави ревизия'
                      onClick={() => {
                        dispatch(addRevisionsFormItem())
                        focus('#item-select-dropdown > input')
                      }}
                    />
                  ) : (
                    <Button
                      disabled={!isValidItem(form.item)}
                      icon='edit'
                      content='Редактирай ревизия'
                      onClick={() => {
                        dispatch(updateRevisionsFormItem())
                        focus('#item-select-dropdown > input')
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Form>
      </Dimmer.Dimmable>

      <Message negative hidden={!error} content={error} />
      {buttonGroup}
    </>
  )
}

export const isValidItem = ({ product, stock, newQty }) => {
  return (
    (product || stock) &&
    // newQty
    validator.isFloat(newQty + '', { min: 0 })
  )
}

const SEQ_ID_PATTERN = /^(\d{1,4})$/i

const ProductSelect = React.memo(
  function ({ exclude, value, onChange, ...props }) {
    const products = useSelector((state) => state.products.items)
    const [query, setQuery] = useState()

    return (
      <Dropdown
        selection
        search
        selectOnBlur={true}
        openOnFocus={false}
        selectOnNavigation={false}
        value={value}
        placeholder='продукт'
        noResultsMessage='Няма продукт'
        lazyLoad
        onSearchChange={(e, { searchQuery }) => {
          searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
          setQuery(searchQuery && Number(searchQuery))
        }}
        options={_.chain(products)
          .filter(
            ({ curQty, id, seqId }) =>
              id === value ||
              ((curQty > 0 || seqId === query) && !_.includes(exclude, id))
          )
          .orderBy('producedOn', 'asc')
          .map((product) => {
            const { qty, unit, name } = product
            const label = getProductLabel(product)
            const text = ` ${name} [${format('.3~f')(qty)} ${displayUnit(
              unit
            )}]`

            return {
              key: product.id,
              value: product.id,
              text: label + text,
              content: (
                <Header
                  size='tiny'
                  color={COLORS.PRODUCT}
                  content={label}
                  subheader={text}
                />
              ),
            }
          })
          .value()}
        onChange={(e, { value }) =>
          onChange(e, { value, product: products[value] })
        }
        {...props}
      />
    )
  },
  (prev, next) => {
    return prev.value === next.value && prev.exclude === next.exclude
  }
)

const StockSelect = React.memo(
  function ({ exclude, value, onChange, ...props }) {
    const stocks = useSelector((state) => state.stocks.items)
    const resources = useSelector((state) => state.resources.items)
    const [query, setQuery] = useState()

    return (
      <Dropdown
        selection
        search
        selectOnBlur={true}
        openOnFocus={false}
        value={value}
        selectOnNavigation={false}
        placeholder='наличност'
        noResultsMessage='Няма наличност'
        lazyLoad
        onSearchChange={(e, { searchQuery }) => {
          searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
          setQuery(searchQuery && Number(searchQuery))
        }}
        options={_.chain(stocks)
          .filter(
            ({ curQty, id, seqId }) =>
              id === value ||
              ((curQty > 0 || seqId === query) && !_.includes(exclude, id))
          )
          .orderBy('arrival', 'asc')
          .map((stock) => {
            const { quantity, batches, unit, resource, desc } = stock
            const name = resources[resource].name
            const label = getLabel(stock)
            const text = ` ${name} [${format('.3~f')(
              quantity * batches
            )} ${displayUnit(unit)}]`
            return {
              key: stock.id,
              value: stock.id,
              text: label + text,
              content: (
                <Header size='tiny' color={COLORS.STOCK}>
                  <Header.Content>{label}</Header.Content>
                  <Header.Subheader>
                    {text}
                    <br />
                    <em>{_.truncate(desc, { length: 56 })}</em>
                  </Header.Subheader>
                </Header>
              ),
            }
          })
          .value()}
        onChange={(e, { value }) =>
          onChange(e, { value, stock: stocks[value] })
        }
        {...props}
      />
    )
  },
  (prev, next) => {
    return prev.value === next.value
  }
)
