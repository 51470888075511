import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Icon, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'd3-format'
import { Link } from 'react-router-dom'

import { removeResource } from '../../actions/resources'
import { displayUnit, displayResourceUnit } from '../../common/unit-utils'
import { getStockTotals } from '../../stock-utils'
import './ResourceListing.css'
import { COLORS } from '../../utils'
import RoleFilter from '../common/RoleFilter'

export class ResourceListing extends Component {
  static propTypes = {
    listing: PropTypes.array.isRequired,
    resources: PropTypes.object.isRequired,
    recipes: PropTypes.object.isRequired,
    removeResource: PropTypes.func.isRequired,
    stockTotals: PropTypes.object.isRequired,
  }

  countRecipes = (resourceId) => {
    const { items, form } = this.props.recipes

    const total = _.reduce(
      items,
      (sum, { ingredients }) =>
        sum + (_.some(ingredients, { resourceId }) ? 1 : 0),
      0
    )

    // check currently edited recipe
    return total + (_.some(form.ingredients, { resourceId }) ? 1 : 0)
  }

  render() {
    const {
      listing,
      resources: { remove },
      removeResource,
      stockTotals,
    } = this.props
    return (
      <div className='y-scrollable'>
        <Table
          selectable
          compact
          size='small'
          singleLine
          unstackable
          color={COLORS.RESOURCE}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>наличност</Table.HeaderCell>
              <Table.HeaderCell>суровина</Table.HeaderCell>
              <Table.HeaderCell>описание</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign='right'>плътност</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>бруто/нето</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>алерген</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>в рецепти</Table.HeaderCell>
              <RoleFilter>
                <Table.HeaderCell />
              </RoleFilter>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(
              listing,
              ({
                id,
                name,
                desc,
                unit,
                unitPrice,
                density,
                densityUnit,
                allergen,
                gtn,
              }) => {
                const recipeCount = this.countRecipes(id)
                const total = stockTotals[id]
                return (
                  <Table.Row key={id}>
                    <Table.Cell textAlign='center'>
                      <Label basic color={total.qty > 0 ? 'green' : 'red'}>
                        {format('.2~f')(total.qty)} {displayUnit(total.unit)}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/resources/${id}`}>{name}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      <em>{desc}</em>
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {format('.2f')(unitPrice)} лв.
                    </Table.Cell>
                    <Table.Cell>{displayResourceUnit(unit)}</Table.Cell>
                    <Table.Cell textAlign='right'>
                      {density
                        ? `${density} ${displayUnit(densityUnit)}`
                        : null}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {format('.2~f')(gtn)}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {allergen ? <Icon name='check' /> : null}
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {recipeCount || null}
                    </Table.Cell>

                    <RoleFilter>
                      <Table.Cell textAlign='center' className='remove-cell'>
                        <Button
                          basic
                          compact
                          size='mini'
                          icon='trash'
                          disabled={recipeCount > 0}
                          className='show-on-hover'
                          loading={remove.isFetching && remove.id === id}
                          onClick={() => {
                            removeResource(id)
                          }}
                        />
                      </Table.Cell>
                    </RoleFilter>
                  </Table.Row>
                )
              }
            )}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => ({
    resources: state.resources,
    recipes: state.recipes,
    stockTotals: getStockTotals(state),
  }),
  (dispatch) => bindActionCreators({ removeResource }, dispatch)
)(ResourceListing)
