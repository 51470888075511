import { createMedia } from '@artsy/fresnel'

// Replacement for react-semantic-ui <Responsive/>:
// https://react.semantic-ui.com/migration-guide/#responsive-component-was-removed
// https://github.com/artsy/fresnel#basic-example

export const { Media, MediaContextProvider } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
  },
})
