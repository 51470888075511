import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'semantic-ui-react'
import { format } from 'd3-format'

const PaginationControls = ({
  offset = 0,
  limit = 0,
  total = 0,
  setFilter,
  limitOptions = [10, 15, 20, 25],
  loading,
}) => {
  const max = Math.floor(Math.max(0, total - 1) / limit) * limit

  const fromStr = format('03')(total ? offset + 1 : 0)
  const toStr = format('03')(Math.min(offset + limit, total))
  const totalStr = format('03')(total)
  return (
    <Button.Group size='small'>
      <Button
        compact
        icon='left double angle'
        disabled={offset === 0 || loading}
        onClick={() => setFilter({ offset: 0 })}
      />
      <Button
        compact
        icon='left angle'
        disabled={offset === 0 || loading}
        onClick={() => setFilter({ offset: Math.max(0, offset - limit) })}
      />
      <Dropdown
        compact
        button
        icon={null}
        text={`${fromStr}-${toStr} от ${totalStr}`}
        loading={loading}
        disabled={total === 0}
      >
        <Dropdown.Menu>
          <Dropdown.Header content='Покажи по' />
          {_.map(limitOptions, (size) => (
            <Dropdown.Item
              key={size}
              text={size}
              onClick={() => setFilter({ limit: size, offset: 0 })}
              active={size === limit}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Button
        compact
        icon='right angle'
        disabled={offset === max || loading}
        onClick={() => setFilter({ offset: Math.min(max, offset + limit) })}
      />
      <Button
        compact
        icon='right double angle'
        disabled={offset === max || loading}
        onClick={() => setFilter({ offset: max })}
      />
    </Button.Group>
  )
}

PaginationControls.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  setFilter: PropTypes.func.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
}

export default PaginationControls
