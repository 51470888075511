import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import { Segment, Button, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet-async'

import RecipeListing from './RecipeListing'
import PaginationControls from '../common/PaginationControls'
import NotFound from '../common/NotFound'

import { setRecipeFilter } from '../../actions/recipes'
import { isPermitted } from '../../common/roles'
import { getRoleColor, getRoleLabel, getRoleTitle } from '../../utils'
import RoleFilter from '../common/RoleFilter'

class Recipes extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired,

    total: PropTypes.number.isRequired,
    listing: PropTypes.array.isRequired,
  }

  render() {
    const { setFilter, filter, isEmpty, total, listing } = this.props

    if (isEmpty) {
      return (
        <Segment basic textAlign='center'>
          <RoleFilter
            fallback={<NotFound message='Нямате достъп до рецепти.' />}
          >
            <Button
              positive
              icon='add'
              as={Link}
              to='/recipes/new'
              content='Добави рецепта'
            />
          </RoleFilter>
        </Segment>
      )
    }

    return (
      <>
        <Helmet title='Рецепти - Pantry' />
        <div className='flexwrap'>
          <div>
            <PaginationControls {...{ ...filter, total, setFilter }} />
          </div>

          <Input
            size='small'
            icon='search'
            action={{
              icon: 'remove',
              disabled: _.isEmpty(filter.query),
              onClick: () => setFilter({ query: '', offset: 0 }),
            }}
            iconPosition='left'
            placeholder='Търси в рецепти'
            value={filter.query}
            onChange={(e, { value }) => setFilter({ query: value, offset: 0 })}
          />

          <RoleFilter>
            <Button.Group size='small'>
              <Button
                content={getRoleLabel('manager')}
                title={getRoleTitle('manager')}
                color={
                  filter.acl === 'manager' ? getRoleColor(filter.acl) : null
                }
                onClick={() =>
                  setFilter({
                    acl: filter.acl === 'manager' ? null : 'manager',
                  })
                }
              />
              <Button
                content={getRoleLabel('user')}
                title={getRoleTitle('user')}
                color={filter.acl === 'user' ? getRoleColor(filter.acl) : null}
                onClick={() =>
                  setFilter({ acl: filter.acl === 'user' ? null : 'user' })
                }
              />
            </Button.Group>
          </RoleFilter>
        </div>
        <RecipeListing {...{ listing }} />
      </>
    )
  }
}

const getItems = (state) => state.recipes.items
const getQuery = (state) => state.recipes.filter.query
const getOffset = (state) => state.recipes.filter.offset
const getLimit = (state) => state.recipes.filter.limit
const getACL = (state) => state.recipes.filter.acl

const getFilteredByAcl = createSelector(
  [getACL, getItems],
  (aclFilter, items) =>
    _.filter(items, ({ acl }) =>
      isPermitted({ requires: acl || 'admin', has: aclFilter || 'admin' })
    )
)

const getFuse = createSelector(
  [getFilteredByAcl],
  (items) =>
    new Fuse(_.values(items), {
      shouldSort: true,
      location: 0,
      distance: 100,
      threshold: 0.4,
      minMatchCharLength: 2,
      useExtendedSearch: true,
      keys: [
        {
          name: 'name',
          weight: 0.8,
        },
        {
          name: 'desc',
          weight: 0.2,
        },
      ],
    })
)

const getFilteredByQuery = createSelector(
  [getFilteredByAcl, getQuery, getFuse],
  (items, query, fuse) => {
    const normalized = _.trim(query)
    if (!_.isEmpty(normalized)) {
      return _.map(fuse.search(normalized), 'item')
    }
    return _.sortBy(items, [
      ({ salePrice }) => salePrice ? 0 : 1,
      ({ name }) => name,
      // ({ createdAt }) => -new Date(createdAt).getTime(),
    ])
  }
)

const getListing = createSelector(
  [getFilteredByQuery, getOffset, getLimit],
  (filtered, offset, limit) => _.slice(filtered, offset, offset + limit)
)

const getTotal = createSelector([getFilteredByQuery], (filtered) =>
  _.size(filtered)
)

export default connect(
  (state) => ({
    isEmpty: _.isEmpty(state.recipes.items),
    filter: state.recipes.filter,
    total: getTotal(state),
    listing: getListing(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setFilter: setRecipeFilter,
      },
      dispatch
    )
)(Recipes)
