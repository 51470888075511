import _ from 'lodash'
import { createSelector } from 'reselect'

import { convertUnits, friendlyQtyUnit } from './common/unit-utils'

const getStocks = (state) => state.stocks.items
const getResources = (state) => state.resources.items

const getStocksByResource = createSelector([getStocks], (stocks) =>
  _.groupBy(stocks, 'resource')
)

export const getStockTotals = createSelector(
  [getStocksByResource, getResources],
  (stocksByResource, resources) => {
    return _.mapValues(resources, ({ id, unit }) => {
      return friendlyQtyUnit({
        qty: _.sumBy(stocksByResource[id], (stock) => {
          // avoid negative curQty
          return Math.max(0, stock.curQty) * convertUnits(stock.unit, unit)
        }),
        unit,
      })
    })
  }
)
