import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import {
  Segment,
  Menu,
  Button,
  Message,
  Dropdown,
  Confirm,
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

import RevisionsForm, { isValidItem } from './RevisionsForm'
import {
  UPDATE_REVISION_SUCCESS,
  FETCH_REVISION_SUCCESS,
  fetchRevision,
  updateRevision,
  removeRevision,
  setRevisionsForm,
  resetRevisionsForm,
  REMOVE_REVISION_SUCCESS,
} from '../../actions/revisions'
import { COLORS } from '../../utils'

export default () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { form, fetch, update, remove } = useSelector(
    (state) => state.revisions
  )
  const dispatch = useDispatch()

  const isSaved = useRef(false)
  const [uneditedForm, setUneditedForm] = useState()
  const [confirmDelete, setConfirmDelete] = useState(false)

  // effects
  useEffect(() => {
    dispatch(fetchRevision(id)).then((action) => {
      if (action.type === FETCH_REVISION_SUCCESS) {
        const { revision } = action
        if (!revision) {
          return
        }
        const filledForm = {
          revisedAtDate: moment(revision.revisedAt).format('DD-MM-YYYY'),
          revisedAtTime: moment(revision.revisedAt).format('HH:mm'),
          items: [],
          notes: revision.notes,
          item: {
            product: _.get(revision.product, '_id'),
            stock: _.get(revision.stock, '_id'),
            notes: revision.notes,
            unit: _.get(revision.product || revision.stock, 'unit'),
            newQty: revision.newQty,
            curQty: revision.oldQty,
          },
        }
        setUneditedForm(filledForm)
        dispatch(setRevisionsForm(filledForm))
      }
    })

    // unmount cleanup
    return () => {
      dispatch(resetRevisionsForm())
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  const handleUpdateRevision = () => {
    dispatch(
      updateRevision(id, {
        ...form.item,
        revisedAt: moment(
          [form.revisedAtDate, form.revisedAtTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
      })
    ).then((action) => {
      if (action.type === UPDATE_REVISION_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        navigate(`/revisions/${id}`)
      }
    })
  }

  const handleRemoveRevision = () => {
    dispatch(removeRevision(id)).then((action) => {
      if (action.type === REMOVE_REVISION_SUCCESS) {
        isSaved.current = true
        navigate('/revisions')
      }
    })
  }

  return (
    <>
      <Helmet title='Ревизия - редакция' />

      <Menu attached='top' borderless inverted color={COLORS.REVISION}>
        <Menu.Item icon='edit' header content='Ревизия' />

        <Menu.Menu position='right'>
          <Dropdown item icon='ellipsis vertical'>
            <Dropdown.Menu>
              <Dropdown.Item
                content='Изтрий'
                icon='trash'
                onClick={() => setConfirmDelete(true)}
              />
              <Confirm
                content='Ревизията ще бъде изтрита безвъзвратно. Сигурни ли сте?'
                cancelButton='Отмени'
                confirmButton={<Button negative content='Изтрий' />}
                size='tiny'
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => {
                  setConfirmDelete(false)
                  handleRemoveRevision()
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      {fetch.error ? (
        <Message negative content={fetch.error} />
      ) : (
        <Segment attached loading={fetch.isFetching || remove.isFetching}>
          <RevisionsForm
            edit
            loading={update.isFetching}
            error={update.error}
            buttonGroup={
              <div>
                <Button
                  positive
                  disabled={!isDirty() || !isFormValid(form)}
                  loading={update.isFetching}
                  content='Съхрани промени'
                  icon='save'
                  onClick={handleUpdateRevision}
                />
                <Button
                  content='Изчисти промени'
                  icon='refresh'
                  disabled={!isDirty()}
                  negative
                  onClick={() => {
                    dispatch(setRevisionsForm(uneditedForm))
                  }}
                />
              </div>
            }
          />
        </Segment>
      )}
    </>
  )
}

const isFormValid = (form) => {
  return (
    moment(form.revisedAtDate, 'D-M-YYYY').isValid() &&
    moment(form.revisedAtTime, 'H:m').isValid() &&
    isValidItem(form.item)
  )
}
