import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { displayUnit } from '../../common/unit-utils'
import moment from 'moment'
import { getRoleLabel, getRoleColor, getRoleTitle, COLORS } from '../../utils'
import RoleFilter from '../common/RoleFilter'
import { getProductTotals } from '../../recipe-utils'
import { format } from 'd3-format'

export class RecipeListing extends Component {
  static propTypes = {
    listing: PropTypes.array.isRequired,
    recipes: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    productTotals: PropTypes.object.isRequired,
  }

  countRecipes = (recipeId) =>
    _.reduce(
      this.props.recipes,
      (sum, { ingredients }) =>
        sum + (_.some(ingredients, { recipeId }) ? 1 : 0),
      0
    )

  render() {
    const { resources, recipes, listing, productTotals } = this.props
    return (
      <div className='y-scrollable'>
        <Table
          selectable
          unstackable
          compact
          size='small'
          singleLine
          color={COLORS.RECIPE}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>наличност</Table.HeaderCell>
              <Table.HeaderCell>рецепта</Table.HeaderCell>
              <Table.HeaderCell textAlign='right' colSpan={2}>
                количество
              </Table.HeaderCell>
              <Table.HeaderCell>съставки</Table.HeaderCell>
              <Table.HeaderCell>описание</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>в рецепти</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>дата</Table.HeaderCell>
              <RoleFilter>
                <Table.HeaderCell
                  textAlign='center'
                  icon='share alternate'
                  title='достъп'
                />
              </RoleFilter>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.isEmpty(listing) && (
              <Table.Row>
                <Table.Cell colSpan={16} textAlign='center' disabled>
                  <em>Няма рецепти</em>
                </Table.Cell>
              </Table.Row>
            )}
            {_.map(
              listing,
              ({
                id,
                name,
                desc,
                ingredients,
                quantity,
                unit,
                createdAt,
                acl,
                salePrice
              }) => {
                const recipeCount = this.countRecipes(id)
                const total = productTotals[id]
                const ingredientList = _.map(
                  _.slice(ingredients, 0, 5),
                  ({ resourceId, recipeId }) => {
                    const r = resourceId
                      ? resources[resourceId]
                      : recipes[recipeId]
                    return r.name
                  }
                ).join(', ')
                return (
                  <Table.Row key={id}>
                    <Table.Cell textAlign='center'>
                      {salePrice &&
                        <Label basic color={total.qty > 0 ? 'green' : 'red'}>
                          {format('.2~f')(total.qty)} {displayUnit(total.unit)}
                        </Label>}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/recipes/${id}`}>{name}</Link>
                    </Table.Cell>
                    <Table.Cell textAlign='right'>{quantity}</Table.Cell>
                    <Table.Cell style={{ paddingLeft: 0 }}>
                      {displayUnit(unit)}
                    </Table.Cell>
                    <Table.Cell>{_.truncate(ingredientList)}</Table.Cell>
                    <Table.Cell>
                      <em>{_.truncate(desc, { length: 25 })}</em>
                    </Table.Cell>
                    <Table.Cell textAlign='right'>
                      {recipeCount || null}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(createdAt).format('DD.MM.YYYY')}
                    </Table.Cell>

                    <RoleFilter>
                      <Table.Cell className='hidden-cell'>
                        {acl && (
                          <Label
                            circular
                            color={getRoleColor(acl)}
                            content={getRoleLabel(acl)}
                            title={getRoleTitle(acl)}
                          />
                        )}
                      </Table.Cell>
                    </RoleFilter>
                  </Table.Row>
                )
              }
            )}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default connect((state) => ({
  recipes: state.recipes.items,
  resources: state.resources.items,
  productTotals: getProductTotals(state),
}))(RecipeListing)
