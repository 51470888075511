import _ from 'lodash'

import { api } from '../utils'
import { ADD_STOCK_SUCCESS } from './stocks'

// add product

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE'

export const addProduct = (product) => (dispatch, getState) => {
  if (getState().products.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_PRODUCT_REQUEST })

  return api
    .withOrg(getState)('/product', { method: 'POST', body: product })
    .then((res) => {
      if (!_.isEmpty(res.byProductStock)) {
        dispatch({
          type: ADD_STOCK_SUCCESS,
          payload: { stocks: [res.byProductStock] },
        })
      }
      return dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: res.product,
      })
    })
    .catch((error) => dispatch({ type: ADD_PRODUCT_FAILURE, error }))
}

// update product

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

export const updateProduct = (id, product) => (dispatch, getState) => {
  if (getState().products.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_PRODUCT_REQUEST })

  return api
    .withOrg(getState)(`/product/${id}`, { method: 'POST', body: product })
    .then((res) => {
      if (!_.isEmpty(res.byProductStock)) {
        dispatch({
          type: ADD_STOCK_SUCCESS,
          payload: { stocks: [res.byProductStock] },
        })
      }
      return dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: res.product,
      })
    })
    .catch((error) => dispatch({ type: UPDATE_PRODUCT_FAILURE, error }))
}

// load products

export const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST'
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAILURE = 'LOAD_PRODUCTS_FAILURE'

export const loadProducts = () => (dispatch, getState) => {
  const { isFetching } = getState().products.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_PRODUCTS_REQUEST })

  return (
    api
      .withOrg(getState)('/products')
      .then((res) =>
        dispatch({
          type: LOAD_PRODUCTS_SUCCESS,
          payload: res.products,
        })
      )
      // reset filter
      .then(() => dispatch(setProductFilter({ offset: 0 })))
      .catch((error) => dispatch({ type: LOAD_PRODUCTS_FAILURE, error }))
  )
}

// form actions

export const SET_PRODUCT_FORM = 'SET_PRODUCT_FORM'
export const setProductForm = (form) => ({ type: SET_PRODUCT_FORM, form })

export const SET_PRODUCT_FORM_INGREDIENT = 'SET_PRODUCT_FORM_INGREDIENT'
export const setProductFormIngredient = (index, ingredient) => ({
  type: SET_PRODUCT_FORM_INGREDIENT,
  index,
  ingredient,
})

export const SET_PRODUCT_FORM_BYPRODUCT = 'SET_PRODUCT_FORM_BYPRODUCT'
export const setProductFormByProduct = (update) => ({
  type: SET_PRODUCT_FORM_BYPRODUCT,
  update,
})

export const ADD_PRODUCT_FORM_INGREDIENT = 'ADD_PRODUCT_FORM_INGREDIENT'
export const addProductFormIngredient = (ingredient) => ({
  type: ADD_PRODUCT_FORM_INGREDIENT,
  ingredient,
})

export const REMOVE_PRODUCT_FORM_INGREDIENT = 'REMOVE_PRODUCT_FORM_INGREDIENT'
export const removeProductFormIngredient = (index) => ({
  type: REMOVE_PRODUCT_FORM_INGREDIENT,
  index,
})

export const RESET_PRODUCT_FORM = 'RESET_PRODUCT_FORM'
export const resetProductForm = () => ({ type: RESET_PRODUCT_FORM })

// filter
export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER'
export const setProductFilter = (filter) => ({
  type: SET_PRODUCT_FILTER,
  filter,
})

// query products

export const QUERY_PRODUCTS_REQUEST = 'QUERY_PRODUCTS_REQUEST'
export const QUERY_PRODUCTS_SUCCESS = 'QUERY_PRODUCTS_SUCCESS'
export const QUERY_PRODUCTS_FAILURE = 'QUERY_PRODUCTS_FAILURE'

// cache: populate items object regardless of curQty
export const queryProducts = (filter, { cache } = {}) => (
  dispatch,
  getState
) => {
  const {
    status: { isFetching },
  } = getState().products.query
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: QUERY_PRODUCTS_REQUEST })

  return api
    .withOrg(getState)('/products/query', {
      query: filter,
    })
    .then((res) =>
      dispatch({
        type: QUERY_PRODUCTS_SUCCESS,
        listing: res.listing,
        total: res.total,
        filter,
        cache,
      })
    )
    .catch((error) => dispatch({ type: QUERY_PRODUCTS_FAILURE, error }))
}

// product usage

export const PRODUCT_USAGE_REQUEST = 'PRODUCT_USAGE_REQUEST'
export const PRODUCT_USAGE_SUCCESS = 'PRODUCT_USAGE_SUCCESS'
export const PRODUCT_USAGE_FAILURE = 'PRODUCT_USAGE_FAILURE'

export const productUsage = (productId) => (dispatch, getState) => {
  const {
    status: { isFetching },
  } = getState().products.usage
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: PRODUCT_USAGE_REQUEST })

  return api
    .withOrg(getState)(`/products/${productId}/usage`)
    .then((res) =>
      dispatch({
        type: PRODUCT_USAGE_SUCCESS,
        log: res.log,
      })
    )
    .catch((error) => dispatch({ type: PRODUCT_USAGE_FAILURE, error }))
}
