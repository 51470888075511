import _ from 'lodash'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Menu,
  Segment,
  Message,
  Grid,
  Header,
  Label,
  Placeholder,
  GridColumn,
} from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import {
  COLORS,
  getProductLabel,
  getLabel,
  displayCurrency,
  getUserRole,
} from '../../utils'
import { fetchSale } from '../../actions/sales'
import { displayUnit, getSalePriceUnit } from '../../common/unit-utils'
import { Role } from '../../common/roles'

export default () => {
  const { id } = useParams()
  const { sale, isFetching, error } = useSelector((state) => state.sales.fetch)
  const { username, role } = useSelector((state) => ({
    username: state.auth.user.username,
    role: getUserRole(state.auth),
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSale(id))
  }, [dispatch, id])

  if (error) {
    return <Message negative content={error} />
  }

  const canEdit =
    role === Role.ADMIN ||
    _.isEqual(username, _.get(sale, ['createdBy', 'username']))

  return (
    <>
      <Helmet title='Продажба' />
      <Menu attached='top' borderless inverted color={COLORS.SALE}>
        <Menu.Item header content='Продажба' />
        <Menu.Menu position='right'>
          {canEdit && sale && (
            <Menu.Item
              icon='edit'
              as={Link}
              to={`/sales/receipt/${sale.receiptId}/edit`}
            />
          )}
        </Menu.Menu>
      </Menu>
      {isFetching ? renderLoading() : renderSale(sale)}
    </>
  )
}

const renderLoading = () => {
  return (
    <Grid doubling columns={4} padded='vertically'>
      {_.times(2, (index) => {
        return (
          <Grid.Row key={index}>
            {_.times(4, (index) => {
              return (
                <Grid.Column key={index}>
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>
              )
            })}
          </Grid.Row>
        )
      })}
    </Grid>
  )
}

const renderSale = (sale) => {
  if (!sale) return null

  const { productId: product, stockId: stock } = sale
  const unit = _.get(product || stock, 'unit')

  return (
    <>
      <Segment attached>
        <Grid doubling columns={4} padded='vertically'>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Header.Content>
                  {stock && (
                    <Link to={`/resources/${stock.resource}`}>{sale.name}</Link>
                  )}
                  {product &&
                    ((product.recipeId && (
                      <Link to={`/recipes/${product.recipeId}`}>
                        {sale.name}
                      </Link>
                    )) ||
                      sale.name)}
                </Header.Content>
                <Header.Subheader>име</Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Header
                content={
                  product ? (
                    <Label
                      color={COLORS.PRODUCT}
                      style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                      tag
                      as={Link}
                      to={`/products/${product._id}`}
                      content={getProductLabel(product)}
                    />
                  ) : stock ? (
                    <Label
                      color={COLORS.STOCK}
                      style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                      tag
                      as={Link}
                      to={`/stocks/${stock._id}`}
                      content={getLabel(stock)}
                    />
                  ) : null
                }
                subheader={product ? 'продукт' : 'наличност'}
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={moment(sale.soldOn).format('DD/MM/YY HH:mm')}
                subheader='продадено на'
              />
            </Grid.Column>

            <GridColumn>
              <Header
                content={_.get(sale.createdBy, 'username')}
                subheader='продал'
              />
            </GridColumn>
          </Grid.Row>

          <Grid.Row>
            <GridColumn>
              <Header content={sale.type} subheader='вид' />
            </GridColumn>

            <GridColumn>
              <Header
                content={`${sale.qty} ${displayUnit(unit)}`}
                subheader='количество'
              />
            </GridColumn>

            <Grid.Column>
              <Header
                content={`${displayCurrency(sale.unitPrice)} / ${displayUnit(
                  getSalePriceUnit(unit)
                )}`}
                subheader='продажна цена'
              />
            </Grid.Column>

            <GridColumn>
              <Header content={displayCurrency(sale.price)} subheader='цена' />
            </GridColumn>
          </Grid.Row>

          <Grid.Row>
            <GridColumn>
              <Header
                content={
                  (sale.buyer && (
                    <Link to={`/companies/${sale.buyer._id}`}>
                      {sale.buyer.name}
                    </Link>
                  )) ||
                  '~'
                }
                subheader='фирма'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={
                  <Link to={`/sales/receipt/${sale.receiptId}`}>
                    {sale.invoice || '<фактура>'}
                  </Link>
                }
                subheader='фактура'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={sale.tradeDoc || '~'}
                subheader='търговски документ'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={sale.paymentMethod || '~'}
                subheader='начин на плащане'
              />
            </GridColumn>
          </Grid.Row>

          {sale.notes && (
            <Grid.Row>
              <Grid.Column>
                <Header content={sale.notes} subheader='бележки' />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </>
  )
}
