import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import { Segment, Button, Input, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import copy from 'copy-text-to-clipboard'
import Fuse from 'fuse.js'
import { format } from 'd3-format'
import { startOfMonth, format as formatDate } from 'date-fns'

import StockListing from './StockListing'
import PaginationControls from '../common/PaginationControls'
import RoleFilter from '../common/RoleFilter'
import { setStockFilter } from '../../actions/stocks'
import { getLabelsCopy } from '../../utils'

class Stocks extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired,

    total: PropTypes.number.isRequired,
    listing: PropTypes.array.isRequired,
    fullListing: PropTypes.array.isRequired,
    resources: PropTypes.object.isRequired,
    totalCost: PropTypes.number,
  }

  render() {
    const {
      setFilter,
      filter,
      isEmpty,
      total,
      listing,
      fullListing,
      resources,
      totalCost,
      org,
    } = this.props

    if (isEmpty) {
      return (
        <Segment basic textAlign='center'>
          <Button
            positive
            icon='add'
            as={Link}
            to='/stocks/delivery/new'
            content='Добави наличности'
          />
        </Segment>
      )
    }

    const endDate = formatDate(startOfMonth(new Date()), 'dd-MM-yyyy')

    return (
      <>
        <Helmet title='Склад - Pantry' />
        <div className='flexwrap'>
          <div>
            <PaginationControls {...{ ...filter, total, setFilter }} />
          </div>
          <Input
            size='small'
            icon='search'
            action={{
              icon: 'remove',
              disabled: _.isEmpty(filter.query),
              onClick: () => setFilter({ query: '', offset: 0 }),
            }}
            iconPosition='left'
            placeholder='Търси в наличности'
            value={filter.query}
            onChange={(e, { value }) => setFilter({ query: value, offset: 0 })}
          />

          <Button
            content='всички'
            positive={filter.showAll}
            size='small'
            onClick={() =>
              setFilter({
                showAll: !filter.showAll,
                showOverdrawn: false,
                offset: 0,
              })
            }
          />

          <RoleFilter>
            <Button
              icon='exclamation'
              positive={filter.showOverdrawn}
              size='small'
              onClick={() =>
                setFilter({
                  showOverdrawn: !filter.showOverdrawn,
                  showAll: false,
                  offset: 0,
                })
              }
            />

            <Button
              compact
              size='small'
              title='Копирай етикети'
              icon='copy'
              onClick={() => copy(getLabelsCopy(fullListing, resources))}
            />
            <Button
              basic
              href={`/api/report/resources.csv?org=${org}&endDate=${endDate}&excludeExpired=true`}
              target='_blank'
              download='resources.csv'
              title='Счетоводен отчет'
              size='mini'
              icon='download'
            />

            <Label
              as='span'
              size='large'
              style={{ marginLeft: 'auto', marginRight: '0.5em' }}
              content={format(',.2f')(totalCost) + ' лв.'}
            />
          </RoleFilter>
        </div>

        <StockListing {...{ listing }} />
      </>
    )
  }
}

const getQuery = (state) => state.stocks.filter.query
const getOffset = (state) => state.stocks.filter.offset
const getLimit = (state) => state.stocks.filter.limit
const getShowAll = (state) => state.stocks.filter.showAll
const getShowOverdrawn = (state) => state.stocks.filter.showOverdrawn

const getResources = (state) => state.resources.items
const getItems = (state) =>
  _.chain(state.stocks.items)
    .orderBy(['arrival', 'seqId'], ['desc', 'desc'])
    .value()

const getFuse = createSelector(
  [getItems, getResources],
  (items, resources) =>
    new Fuse(
      _.map(items, (stock) => ({
        ...stock,
        name: resources[stock.resource].name,
      })),
      {
        shouldSort: false,
        location: 0,
        distance: 100,
        threshold: 0.3,
        minMatchCharLength: 1,
        useExtendedSearch: true,
        keys: [
          {
            name: 'name',
            weight: 0.8,
          },
          {
            name: 'desc',
            weight: 0.2,
          },
        ],
      }
    )
)

const SEQ_ID_PATTERN = /^(\d{1,4})$/i
const OBJECT_ID_PATTERN = /^([0-9a-fA-F]{24})$/

const getQueryResults = createSelector(
  [getItems, getQuery, getFuse],
  (items, query, fuse) => {
    const normalized = _.trim(query)

    // search by seqId
    const seqIdMatch = normalized.match(SEQ_ID_PATTERN)
    if (seqIdMatch) {
      return _.filter(items, { seqId: Number(seqIdMatch[1]) })
    }

    // search by ObjectId
    const idMatch = normalized.match(OBJECT_ID_PATTERN)
    if (idMatch) {
      return _.filter(items, { id: _.toLower(idMatch[1]) })
    }

    if (!_.isEmpty(normalized)) {
      return _.map(fuse.search(normalized), 'item')
    }
    return items
  }
)

const getFiltered = createSelector(
  [getQueryResults, getShowAll, getShowOverdrawn],
  (items, showAll, showOverdrawn) =>
    showOverdrawn
      ? _.filter(items, ({ curQty }) => curQty < 0)
      : showAll
      ? items
      : _.filter(items, ({ curQty }) => curQty > 0)
)

const getListing = createSelector(
  [getFiltered, getOffset, getLimit],
  (filtered, offset, limit) => _.slice(filtered, offset, offset + limit)
)

const getTotal = createSelector([getFiltered], (filtered) => _.size(filtered))

const getTotalCost = createSelector([getFiltered], (filtered) =>
  _.sumBy(
    // skip negative curQty (overdrawn products)
    _.filter(filtered, ({ curQty }) => curQty > 0),
    ({ price, curQty, quantity, batches }) =>
      (price * curQty) / (quantity * batches)
  )
)

export default connect(
  (state) => ({
    isEmpty: _.isEmpty(state.stocks.items),
    filter: state.stocks.filter,
    total: getTotal(state),
    listing: getListing(state),
    fullListing: getFiltered(state),
    resources: state.resources.items,
    totalCost: getTotalCost(state),
    org: state.auth.activeOrg,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setFilter: setStockFilter,
      },
      dispatch
    )
)(Stocks)
