import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import { Segment, Button, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { Helmet } from 'react-helmet-async'

import ResourceListing from './ResourceListing'
import PaginationControls from '../common/PaginationControls'

import { setResourceFilter } from '../../actions/resources'

class Resources extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired,

    total: PropTypes.number.isRequired,
    listing: PropTypes.array.isRequired,
  }

  render() {
    const { setFilter, filter, isEmpty, total, listing } = this.props

    if (isEmpty) {
      return (
        <Segment basic textAlign='center'>
          <Button
            positive
            icon='add'
            as={Link}
            to='/resources/new'
            content='Добави суровина'
          />
        </Segment>
      )
    }

    return (
      <>
        <Helmet title='Суровини - Pantry' />
        <div className='flexwrap'>
          <div>
            <PaginationControls {...{ ...filter, total, setFilter }} />
          </div>
          <Input
            size='small'
            icon='search'
            action={{
              icon: 'remove',
              disabled: _.isEmpty(filter.query),
              onClick: () => setFilter({ query: '', offset: 0 }),
            }}
            iconPosition='left'
            placeholder='Търси в суровини'
            value={filter.query}
            onChange={(e, { value }) => setFilter({ query: value, offset: 0 })}
          />
        </div>
        <ResourceListing {...{ listing }} />
      </>
    )
  }
}

const getItems = (state) => state.resources.items
const getQuery = (state) => state.resources.filter.query
const getOffset = (state) => state.resources.filter.offset
const getLimit = (state) => state.resources.filter.limit

const getFuse = createSelector(
  [getItems],
  (items) =>
    new Fuse(_.values(items), {
      shouldSort: true,
      location: 0,
      distance: 100,
      threshold: 0.4,
      minMatchCharLength: 2,
      useExtendedSearch: true,
      keys: [
        {
          name: 'name',
          weight: 0.8,
        },
        {
          name: 'desc',
          weight: 0.2,
        },
      ],
    })
)

const getFiltered = createSelector(
  [getItems, getQuery, getFuse],
  (items, query, fuse) => {
    const normalized = _.trim(query)
    if (!_.isEmpty(normalized)) {
      return _.map(fuse.search(normalized), 'item')
    }
    return _.sortBy(items, ({ name }) => name)
  }
)

const getListing = createSelector(
  [getFiltered, getOffset, getLimit],
  (filtered, offset, limit) => _.slice(filtered, offset, offset + limit)
)
const getTotal = createSelector([getFiltered], (filtered) => _.size(filtered))

export default connect(
  (state) => ({
    isEmpty: _.isEmpty(state.resources.items),
    filter: state.resources.filter,
    total: getTotal(state),
    listing: getListing(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setFilter: setResourceFilter,
      },
      dispatch
    )
)(Resources)
