import { useDispatch, useSelector } from 'react-redux'

import StockListing from './StockListing'
import PaginationControls from '../common/PaginationControls'
import { queryStocks } from '../../actions/stocks'
import { Helmet } from 'react-helmet-async'
import { useEffect } from 'react'

const StockJournal = ({
  limit = 15,
  columns = ['resource', 'supplier', 'arrival', 'receipt', 'batchId'],
}) => {
  const dispatch = useDispatch()
  const { listing, filter, total, status } = useSelector(
    (state) => state.stocks.query
  )

  useEffect(() => {
    dispatch(queryStocks({ offset: 0, limit, order: 'desc' }))
  }, [dispatch, limit])

  return (
    <>
      <Helmet title='Дневник - Pantry' />
      <PaginationControls
        {...{
          ...filter,
          total,
          setFilter: (newFilter) =>
            dispatch(queryStocks({ ...filter, ...newFilter })),
          loading: status.isFetching,
        }}
      />
      <StockListing
        listing={listing}
        columns={columns}
        loading={status.isFetching}
      />
    </>
  )
}

export default StockJournal
