import _ from 'lodash'
import { api } from '../utils'

import { loadRecipes } from './recipes'
import { loadResources } from './resources'
import { loadCompanies } from './companies'
import { loadStocks } from './stocks'
import { loadProducts } from './products'
import { loadPriceTiers } from './price-tiers'

// login

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const login = (credentials) => (dispatch, getState) => {
  if (getState().auth.login.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOGIN_REQUEST })

  return api('/auth/login', { method: 'POST', body: credentials })
    .then((res) => {
      const ret = dispatch({
        type: LOGIN_SUCCESS,
        user: res.user,
      })
      dispatch(bootstrap())
      return ret
    })
    .catch((error) => dispatch({ type: LOGIN_FAILURE, error }))
}

// fetch user

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const fetchUser = (credentials) => (dispatch, getState) => {
  if (getState().auth.fetchUser.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: FETCH_USER_REQUEST })

  return api('/auth/user')
    .then((res) =>
      dispatch({
        type: FETCH_USER_SUCCESS,
        user: res.user,
      })
    )
    .catch((error) => dispatch({ type: FETCH_USER_FAILURE, error }))
}

// bootstrap

export const BOOTSTRAP_START = 'BOOTSTRAP_START'
export const BOOTSTRAP_FINISH = 'BOOTSTRAP_FINISH'

export const bootstrap = () => (dispatch, getState) => {
  dispatch({ type: BOOTSTRAP_START })

  return Promise.resolve(getState().auth.user)
    .then((user) => {
      if (!user) {
        return dispatch(fetchUser())
      }
    })
    .then(() => {
      if (getState().auth.user) {
        const {
          resources: { items: resources },
          recipes: { items: recipes },
          companies: { items: companies },
          stocks: { items: stocks },
          products: { items: products },
        } = getState()
        return Promise.all([
          _.isEmpty(resources) && dispatch(loadResources()),
          _.isEmpty(recipes) && dispatch(loadRecipes()),
          _.isEmpty(companies) && dispatch(loadCompanies()),
          _.isEmpty(stocks) && dispatch(loadStocks()),
          _.isEmpty(products) && dispatch(loadProducts()),
          _.isEmpty(products) && dispatch(loadPriceTiers()),
        ])
      }
    })
    .then(() => dispatch({ type: BOOTSTRAP_FINISH }))
}

// logout

export const RESET = 'RESET'

export const logout = () => (dispatch) => {
  // delete server session but ignore result
  api('/auth/logout', { method: 'POST' })

  // handled at root reducer to reset global state
  return Promise.resolve(dispatch({ type: RESET }))
}

// set org

export const SET_ORG_START = 'SET_ORG_START'
export const SET_ORG_FINISH = 'SET_ORG_FINISH'

export const setOrg = (org) => (dispatch, getState) => {
  dispatch({ type: SET_ORG_START, org })

  return Promise.all([
    dispatch(loadResources()),
    dispatch(loadRecipes()),
    dispatch(loadCompanies()),
    dispatch(loadStocks()),
    dispatch(loadProducts()),
  ]).then(() => dispatch({ type: SET_ORG_FINISH }))
}
