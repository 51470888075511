import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Menu, Form, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'
import validator from 'validator'

import { add, formActions, ADD_RECIPE_SUCCESS } from '../../actions/recipes'
import { INITIAL_STATE } from '../../reducers/recipes'
import RecipeForm from '../RecipeForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class NewRecipe extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    add: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    // form actions
    resetForm: PropTypes.func.isRequired,
  }

  state = {
    confirmReset: false,
  }

  handleAddRecipe = () => {
    if (this.isValidRecipe()) {
      let recipe = { ...this.props.form }
      if (!recipe.density) {
        delete recipe.density
        delete recipe.densityUnit
      }
      this.props.add(recipe).then((action) => {
        if (action.type === ADD_RECIPE_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(`/recipes/${action.payload.id}`)
        }
      })
    }
  }

  isValidRecipe = () => {
    let {
      name,
      ingredients,
      quantity,
      unit,
      density,
      densityUnit,
    } = this.props.form
    return (
      _.trim(name) &&
      (_.isEmpty(density) ||
        (validator.isFloat(density + '', { gt: 0 }) && densityUnit)) &&
      !_.isEmpty(ingredients) &&
      validator.isFloat(quantity + '', { gt: 0 }) &&
      unit
    )
  }

  componentDidMount() {
    this.props.resetForm()
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, INITIAL_STATE.form)

  render() {
    const { confirmReset } = this.state
    return (
      <div>
        <Helmet title='Нова рецепта' />

        <Menu
          attached='top'
          borderless
          color={COLORS.RECIPE}
          inverted
          style={{ opacity: 0.75 }}
        >
          <Menu.Item header content='Нова рецепта' />
        </Menu>

        <Segment attached>
          <RecipeForm
            loading={this.props.isFetching}
            error={this.props.error}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='add'
                  content='Добави'
                  type='button'
                  disabled={!this.isValidRecipe()}
                  onClick={this.handleAddRecipe}
                />
                <Form.Button
                  content='Изчисти'
                  type='button'
                  basic
                  disabled={!this.isDirty()}
                  onClick={() => this.setState({ confirmReset: true })}
                />
                <Confirm
                  content='Изчисти рецепта?'
                  cancelButton='Отказ'
                  confirmButton='Изчисти'
                  size='tiny'
                  open={confirmReset}
                  onCancel={() => this.setState({ confirmReset: false })}
                  onConfirm={() => {
                    this.setState({ confirmReset: false })
                    this.props.resetForm()
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (state) => ({
    form: state.recipes.form,
    isFetching: state.recipes.add.isFetching,
    error: state.recipes.add.error,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        add,
        ...formActions,
      },
      dispatch
    )
)(NewRecipe))
