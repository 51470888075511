import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Segment, Menu, Button, Message } from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

import SalesForm from './SalesForm'
import {
  querySales,
  QUERY_SALES_SUCCESS,
  updateSale,
  UPDATE_SALE_SUCCESS,
  setSalesForm,
  resetSalesForm,
} from '../../actions/sales'
import { INITIAL_STATE } from '../../reducers/sales'
import { COLORS } from '../../utils'

export default () => {
  const navigate = useNavigate()
  const { receiptId } = useParams()
  const { form, fetch, update } = useSelector((state) => state.sales)
  const dispatch = useDispatch()

  const isSaved = useRef(false)
  const [uneditedForm, setUneditedForm] = useState()

  // effects
  useEffect(() => {
    dispatch(querySales({ receiptId })).then((action) => {
      if (action.type === QUERY_SALES_SUCCESS) {
        const { listing } = action
        if (_.isEmpty(listing)) {
          return
        }
        const { buyer, invoice, tradeDoc, paymentMethod, soldOn, type } = listing[0]
        const filledForm = {
          ...INITIAL_STATE.form,
          buyer: _.get(buyer, '_id'),
          type,
          invoice,
          tradeDoc,
          paymentMethod,
          soldOnDate: moment(soldOn).format('DD-MM-YYYY'),
          soldOnTime: moment(soldOn).format('HH:mm'),
          items: _.map(listing, (sale) => ({
            id: sale.id,
            key: _.uniqueId(), // for client-side identification only
            productId: _.get(sale.productId, '_id'),
            stockId: _.get(sale.stockId, '_id'),
            notes: sale.notes,
            qty: _.isNil(sale.qty) ? '' : sale.qty + '',
            unitPrice: _.isNil(sale.unitPrice) ? '' : sale.unitPrice + '',
            price: _.isNil(sale.price) ? '' : sale.price + '',
          })),
        }
        setUneditedForm(filledForm)
        dispatch(setSalesForm(filledForm))
      }
    })

    // unmount cleanup
    return () => {
      dispatch(resetSalesForm())
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)

  const isDirtyItem = () => {
    const [a, b] = [form.item, INITIAL_STATE.form.item]
    return (
      // skip preset values - productId, stockId, type
      !_.isNil(a.productId) ||
      !_.isNil(a.stockId) ||
      a.qty !== b.qty ||
      a.price !== b.price ||
      a.unitPrice !== b.unitPrice ||
      a.notes !== b.notes
    )
  }

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  const handleUpdateSale = () => {
    dispatch(
      updateSale(receiptId, {
        buyer: form.buyer,
        type: form.type,
        invoice: form.invoice,
        tradeDoc: form.tradeDoc,
        paymentMethod: form.paymentMethod,
        soldOn: moment(
          [form.soldOnDate, form.soldOnTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
        items: form.items,
      })
    ).then((action) => {
      if (action.type === UPDATE_SALE_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        navigate(`/sales/receipt/${receiptId}`)
      }
    })
  }

  return (
    <>
      <Helmet title='Продажба - редакция' />

      <Menu attached='top' borderless inverted color={COLORS.SALE}>
        <Menu.Item icon='edit' header content='Продажба - Фактура' />
      </Menu>

      {fetch.error ? (
        <Message negative content={fetch.error} />
      ) : (
        <Segment attached loading={fetch.isFetching}>
          <SalesForm
            isEdit
            loading={update.isFetching}
            error={update.error}
            buttonGroup={
              <div>
                <Button
                  positive
                  disabled={!isFormValid(form) || !isDirty() || isDirtyItem()}
                  loading={update.isFetching}
                  content='Съхрани промени'
                  icon='save'
                  onClick={handleUpdateSale}
                />
                <Button
                  content='Изчисти промени'
                  icon='refresh'
                  disabled={!isDirty()}
                  negative
                  onClick={() => {
                    dispatch(setSalesForm(uneditedForm))
                  }}
                />
              </div>
            }
          />
        </Segment>
      )}
    </>
  )
}

const isFormValid = (form) => {
  return (
    !_.isEmpty(form.items) &&
    moment(form.soldOnDate, 'D-M-YYYY').isValid() &&
    moment(form.soldOnTime, 'H:m').isValid()
  )
}
