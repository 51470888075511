import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'd3-format'
import moment from 'moment'
import { DateInput } from 'semantic-ui-calendar-react'

import PaginationControls from '../common/PaginationControls'
import RoleFilter from '../common/RoleFilter'
import { querySales, queryInvoices } from '../../actions/sales'
import { INITIAL_STATE } from '../../reducers/sales'
import { Label, Select, Input, Button } from 'semantic-ui-react'
import { SALE_TYPES } from './SalesForm'
import SaleListing from './SaleListing'
import SaleReceiptListing from './SaleReceiptListing'

export default () => {
  const org = useSelector((state) => state.auth.activeOrg)
  const dispatch = useDispatch()

  const [showByInvoice, setShowByInvoice] = useState(true)
  const query = useSelector((state) =>
    showByInvoice ? state.sales.queryByInvoice : state.sales.query
  )
  const runQuery = showByInvoice ? queryInvoices : querySales
  const initialQuery = showByInvoice
    ? INITIAL_STATE.queryByInvoice
    : INITIAL_STATE.query

  useEffect(() => {
    dispatch(runQuery(initialQuery.filter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showByInvoice])

  const [nameQuery, setNameQuery] = useState(initialQuery.filter.name)
  const [fromDateQuery, setFromDateQuery] = useState('')
  const [toDateQuery, setToDateQuery] = useState('')

  const { total, sum, listing, filter, status } = query

  return (
    <>
      <Helmet title='Продажби - Pantry' />

      <div className='flexwrap'>
        <Input
          placeholder='име'
          value={nameQuery}
          icon='search'
          iconPosition='left'
          loading={status.isFetching}
          action={{
            icon: 'remove',
            disabled: _.isEmpty(nameQuery),
            onClick: () => {
              setNameQuery('')
              dispatch(runQuery({ ...filter, name: '', offset: 0 }))
            },
          }}
          onChange={(e, { value }) => {
            setNameQuery(value)
          }}
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              dispatch(runQuery({ ...filter, name: nameQuery, offset: 0 }))
            }
          }}
        />

        <DateInput
          clearable
          placeholder='от дата'
          animation=''
          value={fromDateQuery}
          closable
          onChange={(e, { value }) => {
            setFromDateQuery(value)
            const date = moment(value, 'DD-MM-YYYY', true)
            if (
              (date.isValid() || _.isEmpty(value)) &&
              filter.fromDate !== value
            ) {
              dispatch(runQuery({ ...filter, fromDate: value, offset: 0 }))
            }
          }}
        />

        <DateInput
          clearable
          placeholder='до дата'
          animation=''
          value={toDateQuery}
          closable
          onChange={(e, { value }) => {
            setToDateQuery(value)
            const date = moment(value, 'DD-MM-YYYY', true)
            if (
              (date.isValid() || _.isEmpty(value)) &&
              filter.toDate !== value
            ) {
              dispatch(runQuery({ ...filter, toDate: value, offset: 0 }))
            }
          }}
        />

        <Select
          multiple
          clearable
          search
          value={filter.types || []}
          placeholder='вид'
          options={_.map(SALE_TYPES, (value) => ({ text: value, value }))}
          onChange={(e, { value }) => {
            dispatch(runQuery({ ...filter, types: value, offset: 0 }))
          }}
        />
      </div>

      <div className='flexwrap'>
        <div>
          <PaginationControls
            limit={filter.limit}
            offset={filter.offset}
            total={total}
            loading={status.isFetching}
            setFilter={(filter) =>
              dispatch(
                runQuery({
                  ...query.filter,
                  ...filter,
                })
              )
            }
          />
        </div>

        <Button
          icon='file alternate outline'
          size='mini'
          title='по фактура'
          positive={showByInvoice}
          onClick={() => setShowByInvoice(!showByInvoice)}
        />

        <Button
          basic
          href={`/api/report/invoices.csv?org=${org}&fromDate=${fromDateQuery}&toDate=${toDateQuery}`}
          target='_blank'
          download='invoices.csv'
          title='Счетоводен отчет'
          size='mini'
          icon='download'
        />

        <RoleFilter>
          <Label
            as='span'
            size='large'
            style={{ marginLeft: 'auto', marginRight: '0.5em' }}
            content={format(',.2f')(sum) + ' лв.'}
          />
        </RoleFilter>
      </div>

      {showByInvoice ? (
        <SaleReceiptListing listing={listing} loading={status.isFetching} />
      ) : (
        <SaleListing listing={listing} loading={status.isFetching} />
      )}
    </>
  )
}
