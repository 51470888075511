import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import App from './App'
import { configureStore } from './store'
import { configureSocket } from './socket'

import 'semantic-ui-css/semantic.css'
import './index.scss'

const store = configureStore()
configureSocket(store)

const root = createRoot(document.getElementById('root'))

const render = (Component) => {
  return root.render(
    <Provider store={store}>
      <RouterProvider router={createBrowserRouter([{
        path: '*',
        element: <Component />
      }])} />
    </Provider>
  )
}

render(App)

// hot reloading
// https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
