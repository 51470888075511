import _ from 'lodash'
import { getValidIngredientUnits, convertUnits } from '../../common/unit-utils'

// epsilon - qty precision
const ε = 1e-10
const isNotFinished = (qty) => {
  return Math.abs(qty) > ε
}

export const selectStock = (resourceId, qty, unit, stocks, resources) => {
  const selection = []
  const { density, densityUnit, gtn = 1 } = resources[resourceId]
  while (isNotFinished(qty)) {
    const stock = _.minBy(
      _.filter(
        stocks.avail,
        ({ id, resource, curQty }) =>
          resource === resourceId && curQty - _.get(stocks.spent, id, 0) > 0
      ),
      'arrival'
    )
    if (!stock) {
      // all gone
      selection.push({ resourceId, stockId: null, qty: _.round(qty, 2), unit })
      qty = 0
      continue
    }
    const needed =
      gtn * qty * convertUnits(unit, stock.unit, density, densityUnit)
    const left = stock.curQty - _.get(stocks.spent, stock.id, 0)
    let spent = _.min([needed, left])
    // record expenditure
    stocks.spent[stock.id] = _.get(stocks.spent, stock.id, 0) + spent

    // switch back to ingredient units
    spent = (spent * convertUnits(stock.unit, unit, density, densityUnit)) / gtn
    qty -= spent
    selection.push({
      resourceId,
      stockId: stock.id,
      qty: _.round(spent, 2),
      unit,
    })
  }
  return selection
}

export const selectProduct = (recipeId, qty, unit, products, recipes) => {
  const selection = []
  const { density, densityUnit } = recipes[recipeId]
  while (isNotFinished(qty)) {
    const product = _.minBy(
      _.filter(
        products.avail,
        ({ id, recipeId: productRecipeId, curQty, unit: productUnit }) =>
          productRecipeId === recipeId &&
          // must filter out incompatible units
          _.includes(
            getValidIngredientUnits({ unit, density, densityUnit }),
            productUnit
          ) &&
          curQty - _.get(products.spent, id, 0) > 0
      ),
      'producedOn'
    )
    if (!product) {
      // all gone
      selection.push({ recipeId, productId: null, qty: _.round(qty, 2), unit })
      qty = 0
      continue
    }
    const needed = qty * convertUnits(unit, product.unit, density, densityUnit)
    const left = product.curQty - _.get(products.spent, product.id, 0)
    let spent = _.min([needed, left])
    // record expenditure
    products.spent[product.id] = _.get(products.spent, product.id, 0) + spent

    // switch back to ingredient units
    spent = spent * convertUnits(product.unit, unit, density, densityUnit)
    qty -= spent
    selection.push({
      recipeId,
      productId: product.id,
      qty: _.round(spent, 2),
      unit,
    })
  }
  return selection
}

export const runRecipe = (
  { name, id, quantity, unit, byProduct, duration, salePrice },
  { dose, ingredients: prepIngredients },
  stocks,
  products,
  resources,
  recipes
) => {
  stocks = {
    avail: _.filter(stocks, ({ curQty }) => curQty > 0),
    spent: {},
  }
  products = {
    avail: _.filter(products, ({ curQty }) => curQty > 0),
    spent: {},
  }

  const productForm = {
    name,
    recipeId: id,
    qty: _.round(quantity * dose, 2),
    unit,
    desc: '',
    duration,
    salePrice,
    ingredients: _.flatMap(
      _.filter(prepIngredients, ({ expanded }) => !expanded),
      ({ resourceId, recipeId, quantity, unit }) => {
        return resourceId
          ? selectStock(resourceId, quantity * dose, unit, stocks, resources)
          : selectProduct(recipeId, quantity * dose, unit, products, recipes)
      }
    ),
    byProduct: byProduct
      ? {
          ...byProduct,
          qty: byProduct.qty * dose,
          costPct: 15,
        }
      : null,
  }

  return productForm
}
