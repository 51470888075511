import _ from 'lodash'
import update from 'immutability-helper'

import {
  FETCH_REVISION_REQUEST,
  FETCH_REVISION_FAILURE,
  FETCH_REVISION_SUCCESS,
  QUERY_REVISIONS_REQUEST,
  QUERY_REVISIONS_FAILURE,
  QUERY_REVISIONS_SUCCESS,
  UPDATE_REVISION_REQUEST,
  UPDATE_REVISION_FAILURE,
  UPDATE_REVISION_SUCCESS,
  ADD_REVISIONS_REQUEST,
  ADD_REVISIONS_SUCCESS,
  ADD_REVISIONS_FAILURE,
  REMOVE_REVISION_REQUEST,
  REMOVE_REVISION_FAILURE,
  REMOVE_REVISION_SUCCESS,
  REVISIONS_FORM_SET,
  REVISIONS_FORM_SET_ITEM,
  REVISIONS_FORM_ADD_ITEM,
  REVISIONS_FORM_REMOVE_ITEM,
  REVISIONS_FORM_UPDATE_ITEM,
  REVISIONS_FORM_EDIT_ITEM,
  RESET_REVISIONS_FORM,
  REVISIONS_FORM_MOVE_ITEM,
  CALC_QTY_BEFORE_REQUEST,
  CALC_QTY_BEFORE_FAILURE,
  CALC_QTY_BEFORE_SUCCESS,
} from '../actions/revisions'

export const INITIAL_STATE = {
  query: {
    listing: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 15,
      date: '',
      type: undefined, // product | stock
    },
    status: {
      isFetching: false,
      error: null,
    },
  },

  add: { isFetching: false, error: null },
  fetch: { isFetching: false, error: null },
  update: { isFetching: false, error: null },
  remove: { isFetching: false, error: null },
  calcQtyBefore: { isFetching: false, error: null },

  form: {
    revisedAtDate: '',
    revisedAtTime: '',
    notes: '',
    items: [],
    item: {
      product: null,
      stock: undefined,
      newQty: '',
      notes: '',
      curQty: null,
      unit: null,
    },
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCH_REVISION_REQUEST:
      return {
        ...state,
        fetch: {
          isFetching: true,
          error: null,
        },
      }
    case FETCH_REVISION_FAILURE:
      return {
        ...state,
        fetch: {
          isFetching: false,
          error: action.error,
        },
      }
    case FETCH_REVISION_SUCCESS:
      return {
        ...state,
        fetch: {
          isFetching: false,
          error: null,
          revision: action.revision,
        },
      }

    case QUERY_REVISIONS_REQUEST:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case QUERY_REVISIONS_FAILURE:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case QUERY_REVISIONS_SUCCESS:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          filter: action.filter,
        },
      }

    case ADD_REVISIONS_REQUEST:
      return {
        ...state,
        add: {
          payload: action.payload,
          isFetching: true,
          error: null,
        },
      }
    case ADD_REVISIONS_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_REVISIONS_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
      }

    case UPDATE_REVISION_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_REVISION_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_REVISION_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
      }

    case REMOVE_REVISION_REQUEST:
      return {
        ...state,
        remove: { isFetching: true, error: null },
      }
    case REMOVE_REVISION_FAILURE:
      return {
        ...state,
        remove: { isFetching: false, error: action.error },
      }
    case REMOVE_REVISION_SUCCESS:
      return {
        ...state,
        remove: { isFetching: false, error: null },
      }

    // form actions
    case REVISIONS_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }

    case RESET_REVISIONS_FORM:
      return {
        ...state,
        form: { ...INITIAL_STATE.form },
      }

    case REVISIONS_FORM_SET_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          item: {
            ...state.form.item,
            ...action.item,
          },
        },
      }

    case REVISIONS_FORM_ADD_ITEM:
      const lastItem = state.form.item
      return {
        ...state,
        form: {
          ...state.form,
          items: [
            ...state.form.items,
            {
              ...state.form.item, // client-side only - allows editing
              key: _.uniqueId(),
            },
          ],
          item: {
            ...INITIAL_STATE.form.item,
            product: lastItem.product ? null : undefined,
            stock: lastItem.stock ? null : undefined,
          },
        },
      }
    case REVISIONS_FORM_REMOVE_ITEM:
      return update(state, {
        form: { items: { $splice: [[action.index, 1]] } },
      })

    case REVISIONS_FORM_EDIT_ITEM:
      return update(state, {
        form: {
          item: { $set: state.form.items[action.index] },
        },
      })

    case REVISIONS_FORM_UPDATE_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          items: _.map(state.form.items, (item) => {
            if (item.key === state.form.item.key) {
              return state.form.item
            }
            return item
          }),
          item: INITIAL_STATE.form.item,
        },
      }

    case REVISIONS_FORM_MOVE_ITEM:
      const { fromIndex, toIndex } = action
      const moved = state.form.items.slice()
      moved.splice(toIndex, 0, moved.splice(fromIndex, 1)[0])

      return {
        ...state,
        form: {
          ...state.form,
          items: moved,
        },
      }

    case CALC_QTY_BEFORE_REQUEST:
      return {
        ...state,
        calcQtyBefore: {
          isFetching: true,
          error: null,
        },
      }
    case CALC_QTY_BEFORE_FAILURE:
      return {
        ...state,
        calcQtyBefore: {
          isFetching: false,
          error: action.error,
        },
      }
    case CALC_QTY_BEFORE_SUCCESS:
      return {
        ...state,
        calcQtyBefore: {
          isFetching: false,
          error: null,
        },
        form: {
          ...state.form,
          item: {
            ...state.form.item,
            curQty: action.payload.curQty,
          },
        },
      }

    default:
      return state
  }
}
