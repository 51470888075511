import _ from 'lodash'
import PropTypes from 'prop-types'
import { Form, Message } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { formActions } from '../../actions/companies'

const CompanyForm = ({ loading, error, buttonGroup }) => {
  const {
    name,
    companyName,
    bulstat,
    certificate,
    location,
    contact,
    manager,
    taxNumber,
    deliveryAddress,
    paymentMethod,
    email,
    notes,
    isSupplier,
    isBuyer,
    priceTier,
  } = useSelector((state) => state.companies.form)

  const priceTiers = useSelector((state) => state.priceTiers.items)

  const dispatch = useDispatch()
  const setForm = (form) => dispatch(formActions.setForm(form))

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
      }}
      loading={loading}
      error={!!error}
    >
      <Form.Group widths={3}>
        <Form.Input
          label='Име'
          required
          autoFocus
          value={name}
          onChange={(e, { value }) => setForm({ name: value })}
        />

        <Form.Input
          label='Име на фирма'
          value={companyName}
          onChange={(e, { value }) => setForm({ companyName: value })}
        />

        <Form.Input
          label='Булстат'
          value={bulstat}
          onChange={(e, { value }) => setForm({ bulstat: value })}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Input
          label='ДДС №'
          value={taxNumber}
          onChange={(e, { value }) => setForm({ taxNumber: value })}
        />
        <Form.Input
          label='Седалище'
          value={location}
          onChange={(e, { value }) => setForm({ location: value })}
        />
        <Form.Input
          label='МОЛ'
          value={manager}
          onChange={(e, { value }) => setForm({ manager: value })}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Input
          label='Начин на плащане'
          value={paymentMethod}
          onChange={(e, { value }) => setForm({ paymentMethod: value })}
        />
        <Form.Input
          label='Адрес за доставка'
          value={deliveryAddress}
          onChange={(e, { value }) => setForm({ deliveryAddress: value })}
        />
        <Form.Input
          label='УРЗХ'
          title='Удостоверение за регистрация по Закона за храните'
          value={certificate}
          onChange={(e, { value }) => setForm({ certificate: value })}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Input
          label='Имейл'
          value={email}
          onChange={(e, { value }) => setForm({ email: value })}
        />
        <Form.Input
          label='Контакт'
          value={contact}
          onChange={(e, { value }) => setForm({ contact: value })}
        />
        {isBuyer && (
          <Form.Dropdown
            label='Ценови клас'
            selection
            clearable
            value={priceTier}
            options={_.map(priceTiers, ({ id, name }) => ({
              value: id,
              text: name,
            }))}
            onChange={(e, { value }) => {
              setForm({ priceTier: value ? value : null })
            }}
          />
        )}
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Checkbox
          label='Доставчик'
          checked={isSupplier}
          onClick={() => setForm({ isSupplier: !isSupplier })}
        />
        <Form.Checkbox
          label='Клиент'
          checked={isBuyer}
          onClick={() => setForm({ isBuyer: !isBuyer })}
        />
      </Form.Group>

      <Form.TextArea
        label='Бележки'
        rows={5}
        value={notes}
        onChange={(e, { value }) => setForm({ notes: value })}
      />

      {buttonGroup}
      <Message error content={error} />
    </Form>
  )
}

CompanyForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  buttonGroup: PropTypes.node.isRequired,
}

export default CompanyForm
