import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Menu, Form, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'

import { add, formActions, ADD_STOCK_SUCCESS } from '../../actions/stocks'
import { INITIAL_STATE } from '../../reducers/stocks'
import StockForm from './StockForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class NewStockDelivery extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    add: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    // form actions
    resetForm: PropTypes.func.isRequired,
  }

  state = {
    confirmReset: false,
    confirmSave: false,
  }

  handleAddStock = () => {
    const { form } = this.props

    if (this.isValidStock()) {
      let stock = {
        supplier: form.supplier,
        receipt: form.receipt,
        arrival: moment(
          [form.arrivalDate, form.arrivalTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
        items: _.map(form.items, (item) => {
          if (item.expiry) {
            return {
              ...item,
              expiry: moment(item.expiry, 'DD-MM-YYYY').format(),
            }
          }
          return item
        }),
      }

      this.props.add(stock).then((action) => {
        if (action.type === ADD_STOCK_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(
            `/stocks/delivery/${action.payload.deliveryId}`
          )
        }
      })
    }
  }

  isValidStock = () => {
    const { supplier, arrivalDate, arrivalTime, items } = this.props.form
    return (
      supplier &&
      moment(arrivalDate, 'D-M-YYYY').isValid() &&
      moment(arrivalTime, 'H:m').isValid() &&
      !_.isEmpty(items)
    )
  }

  resetForm() {
    const now = moment()
    const [date, time] = [now.format('DD-MM-YYYY'), now.format('HH:mm')]
    this.props.resetForm(date, time)
    this.arrivalDate = date
    this.arrivalTime = time
  }

  componentDidMount() {
    this.resetForm()
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () => {
    return (
      !this.isSaved &&
      !_.isEqual(this.props.form, {
        ...INITIAL_STATE.form,
        arrivalDate: this.arrivalDate,
        arrivalTime: this.arrivalTime,
      })
    )
  }

  isDirtyItem = () => !_.isEqual(this.props.form.item, INITIAL_STATE.form.item)

  render() {
    const { confirmReset, confirmSave } = this.state
    return (
      <div>
        <Helmet title='Нова наличност - Pantry' />

        <Menu
          attached='top'
          borderless
          inverted
          color={COLORS.STOCK}
          style={{ opacity: 0.75 }}
        >
          <Menu.Item header content='Нова наличност' />
        </Menu>

        <Segment attached>
          <StockForm
            loading={this.props.isFetching}
            error={this.props.error}
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='add'
                  content='Запиши доставка'
                  type='button'
                  disabled={!this.isValidStock()}
                  onClick={() => {
                    if (!this.isDirtyItem()) {
                      this.handleAddStock()
                    } else {
                      this.setState({ confirmSave: true })
                    }
                  }}
                />
                <Form.Button
                  content='Изчисти'
                  type='button'
                  basic
                  disabled={!this.isDirty()}
                  onClick={() => this.setState({ confirmReset: true })}
                />
                <Confirm
                  content='Изчисти доставка?'
                  cancelButton='Отказ'
                  confirmButton='Изчисти'
                  size='tiny'
                  open={confirmReset}
                  onCancel={() => this.setState({ confirmReset: false })}
                  onConfirm={() => {
                    this.setState({ confirmReset: false })
                    this.resetForm()
                  }}
                />
                <Confirm
                  content='Имате недобавена наличност под редакция. Игнорирай?'
                  cancelButton='Не'
                  confirmButton='Да'
                  size='tiny'
                  open={confirmSave}
                  onCancel={() => this.setState({ confirmSave: false })}
                  onConfirm={() => {
                    this.setState({ confirmSave: false })
                    this.handleAddStock()
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  ({ stocks }) => ({
    form: stocks.form,
    isFetching: stocks.add.isFetching,
    error: stocks.add.error,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        add,
        ...formActions,
      },
      dispatch
    )
)(NewStockDelivery))
