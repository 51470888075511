import _ from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Menu,
  Segment,
  Message,
  Grid,
  Header,
  Placeholder,
  GridColumn,
  Icon,
} from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import copy from 'copy-text-to-clipboard'

import { COLORS, getReceiptCopy, getUserRole } from '../../utils'
import { querySales } from '../../actions/sales'
import { Role } from '../../common/roles'
import SaleListing from './SaleListing'

export default () => {
  const { receiptId } = useParams()
  const {
    query: {
      listing,
      status: { isFetching, error },
    },
  } = useSelector((state) => state.sales)

  const buyer = useSelector((state) => {
    const companyId = listing?.[0]?.buyer?._id
    return state.companies.items[companyId]
  })

  const { username, role } = useSelector((state) => ({
    username: state.auth.user.username,
    role: getUserRole(state.auth),
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(querySales({ receiptId }))
  }, [dispatch, receiptId])

  if (error || _.isEmpty(listing)) {
    return <Message negative content={error || 'Receipt id not found.'} />
  }

  const canEdit =
    role === Role.ADMIN ||
    _.isEqual(username, _.get(listing[0], ['createdBy', 'username']))

  const hasInvoice = !!listing[0]?.invoice

  return (
    <>
      <Helmet title='Продажба - Фактура' />
      <Menu attached='top' borderless inverted color={COLORS.SALE}>
        <Menu.Item header content='Продажба - Фактура' />
        <Menu.Menu position='right'>
          {hasInvoice &&
            <Menu.Item
              title='Принтирай търговски документ'
              as={Link}
              to='print-tradedoc'
            >
              <Icon name='print' />ТД
            </Menu.Item>}
          {hasInvoice &&
            <Menu.Item
              title='Принтирай фактура оригинал'
              as={Link}
              to='print'
            >
              <Icon name='print' />ФО
            </Menu.Item>}
          {hasInvoice &&
            <Menu.Item
              title='Принтирай фактура копие'
              as={Link}
              to='print?copy'
            >
              <Icon name='print' />ФК
            </Menu.Item>}
          <Menu.Item
            icon='copy'
            title='Копирай фактура'
            onClick={() => copy(getReceiptCopy(listing, buyer))}
          />
          {canEdit && (
            <Menu.Item
              icon='edit'
              title='Редактирай фактура'
              as={Link}
              to='edit'
            />
          )}
        </Menu.Menu>
      </Menu>
      {isFetching ? renderLoading() : renderSaleReceipt(listing)}
    </>
  )
}

const columns = new Set(['label', 'name', 'notes', 'qty', 'unitPrice', 'price'])

const renderSaleReceipt = (listing) => {
  // common collection fields
  const { soldOn, createdBy, type, buyer, invoice, tradeDoc, paymentMethod, receiptId } =
    listing[0]

  return (
    <>
      <Segment attached>
        <Grid doubling columns={4} padded='vertically'>
          <Grid.Row>
            <GridColumn>
              <Header
                content={
                  (buyer && (
                    <Link to={`/companies/${buyer._id}`}>{buyer.name}</Link>
                  )) ||
                  '~'
                }
                subheader='фирма'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={
                  <Link to={`/sales/receipt/${receiptId}`}>
                    {invoice || '<фактура>'}
                  </Link>
                }
                subheader='фактура'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={tradeDoc || '~'}
                subheader='търговски документ'
              />
            </GridColumn>

            <GridColumn>
              <Header
                content={paymentMethod || '~'}
                subheader='начин на плащане'
              />
            </GridColumn>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header
                content={moment(soldOn).format('DD/MM/YY HH:mm')}
                subheader='продадено на'
              />
            </Grid.Column>

            <GridColumn>
              <Header
                content={_.get(createdBy, 'username')}
                subheader='продал'
              />
            </GridColumn>

            <GridColumn>
              <Header content={type} subheader='вид' />
            </GridColumn>
          </Grid.Row>
        </Grid>
      </Segment>

      <SaleListing listing={listing} columns={columns} showTotal />
    </>
  )
}

const renderLoading = () => {
  return (
    <Grid doubling columns={4} padded='vertically'>
      {_.times(2, (index) => {
        return (
          <Grid.Row key={index}>
            {_.times(4, (index) => {
              return (
                <Grid.Column key={index}>
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>
              )
            })}
          </Grid.Row>
        )
      })}
    </Grid>
  )
}
