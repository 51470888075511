import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Segment,
  Header,
  Form,
  Grid,
  Label,
  Icon,
  Menu,
  Divider,
  Message,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'd3-format'
import { Link, Routes, Route, useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { createSelector } from 'reselect'
import { isFloat } from 'validator'

import {
  resetPrep,
  expandAll,
  setPrepDose,
  updateRecipeACL,
  UPDATE_RECIPE_ACL_FAILURE,
} from '../../actions/recipes'
import { queryProducts } from '../../actions/products'
import { querySales } from '../../actions/sales'
import {
  ingredientsCost,
  displayUnit,
  unitType,
  convertUnits,
  getSalePriceUnit,
} from '../../common/unit-utils'
import './Recipe.css'
import RecipeIngredients from './RecipeIngredients'
import RecipeListing from './RecipeListing'
import ProductListing from '../product/ProductListing'
import SaleListing from '../sales/SaleListing'
import RoleFilter from '../common/RoleFilter'
import DecimalInput from '../common/DecimalInput'
import PaginationControls from '../common/PaginationControls'
import { getRoleLabel, getRoleColor, getRoleTitle, COLORS } from '../../utils'
import NotFound from '../common/NotFound'
import { Role } from '../../common/roles'
import { runRecipe } from './run-recipe'

const getRecipeListing = createSelector(
  (state) => state.recipes.items,
  (state, params) => params.id,
  (items, recipeId) => {
    return _.chain(items)
      .filter(({ ingredients }) => _.some(ingredients, { recipeId }))
      .sortBy(({ name }) => -name)
      .value()
  }
)

const Recipe = () => {
  const [error, setError] = useState()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  const recipes = useSelector(state => state.recipes.items)
  const resources = useSelector(state => state.resources.items)
  const products = useSelector(state => state.products.items)
  const stocks = useSelector(state => state.stocks.items)
  const productQuery = useSelector(state => state.products.query)
  const saleQuery = useSelector(state => state.sales.query)
  const updateACLStatus = useSelector(state => state.recipes.updateACL)

  const recipe = recipes[params.id]
  const byProductResource = recipe && products[_.get(recipe.byProduct, 'resourceId')]
  const totalCost = recipe && ingredientsCost(recipe.ingredients, resources, recipes)
  const recipeListing = useSelector(state => getRecipeListing(state, params))
  const prep = useSelector(state => state.recipes.preps[params.id])

  useEffect(() => {
    if (recipe?.slug && recipe.slug !== params.slug) {
      navigate(recipe.slug, { replace: true })
    }
  }, [navigate, recipe.slug, params.slug])

  useEffect(() => {
    dispatch(queryProducts({ offset: 0, limit: 10, recipeId: params.id }))
    dispatch(querySales({ offset: 0, limit: 10, recipeId: params.id }))
  }, [dispatch, params.id])

  useEffect(() => {
    if (!prep) {
      dispatch(resetPrep(params.id))
    }
    const dosage = _.get(location.state, 'dosage')
    if (dosage !== prep?.dosage) {
      dispatch(setPrepDose(params.id,
        _.round(
          (convertUnits(
            dosage.unit,
            recipe.unit,
            recipe.density,
            recipe.densityUnit
          ) *
            dosage.qty) /
          recipe.quantity,
          3
        )
      ))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  if (!recipe) {
    return <NotFound message='Няма такава рецепта' />
  }
  if (!prep) {
    return null
  }

  const {
    id,
    name,
    desc,
    quantity,
    unit,
    salePrice,
    duration,
    showAsIngredient,
    byProduct,
    density,
    densityUnit,
    acl,
  } = recipe

  const dose = isFloat(prep.dose + '', { gt: 0 }) ? parseFloat(prep.dose) : 1

  const secMap = {
    weight: [100, 'g'],
    volume: [100, 'ml'],
    count: [1, 'count'],
  }[unitType(unit)]
  const secCostUnit = secMap[1]
  const secCostRatio =
    (convertUnits(unit, secCostUnit) * quantity) / secMap[0]

  return (
    <>
      <div className='recipe-view'>
        <Helmet title={name} />

        <Message
          error
          hidden={!error}
          size='small'
          onDismiss={() => setError(null)}
        >
          {error}
        </Message>

        <Menu
          attached='top'
          size='large'
          inverted
          color={COLORS.RECIPE}
          borderless
          className='wrap'
        >
          <Menu.Item header content={name} />

          <RoleFilter>
            <Menu.Menu position='right'>
              {_.map(['manager', 'user'], (role) => {
                if (
                  updateACLStatus.isFetching &&
                  (updateACLStatus.acl === role ||
                    (updateACLStatus.acl === null && acl === role))
                ) {
                  return (
                    <Menu.Item key={role}>
                      <Icon fitted name='spinner' loading />
                    </Menu.Item>
                  )
                }
                const isActive = role === acl
                return (
                  <Menu.Item
                    key={role}
                    link
                    active={isActive}
                    color={isActive ? getRoleColor(role) : null}
                    content={getRoleLabel(role)}
                    title={getRoleTitle(role)}
                    onClick={() => {
                      setError(null)
                      dispatch(updateRecipeACL(id, isActive ? null : role)).then(
                        (action) => {
                          if (action.type === UPDATE_RECIPE_ACL_FAILURE) {
                            setError(action.error)
                          }
                        }
                      )
                    }}
                  />
                )
              })}
              <Menu.Item
                icon='edit'
                title='Редактирай рецепта'
                as={Link}
                to={`/recipes/${id}/edit`}
              />
            </Menu.Menu>
          </RoleFilter>
        </Menu>

        <Segment attached>
          {showAsIngredient ? <Label ribbon='right'>Съставка</Label> : null}

          <Grid columns={5} doubling>
            <Grid.Column>
              <Header as='h4'>Количество</Header>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group inline>
                  <Form.Field className='quantity-multiplier'>
                    <label>
                      {quantity} {displayUnit(unit)}
                    </label>

                    <Icon name='times' size='small' color='grey' />

                    <DecimalInput
                      style={{
                        padding: 8,
                        width: 60,
                        backgroundColor: '#f0f0f0',
                      }}
                      size='small'
                      transparent
                      value={prep.dose}
                      onChange={(e, { value }) => {
                        dispatch(setPrepDose(id, value))
                      }}
                    />
                  </Form.Field>
                  {dose !== 1 ? (
                    <Form.Field>
                      <label>
                        = {format('.3~f')(quantity * dose)}{' '}
                        {displayUnit(unit)}
                      </label>
                    </Form.Field>
                  ) : null}
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column>
              <RoleFilter>
                <Header as='h4'>Цена</Header>
                <p>
                  {`${format('.2f')(totalCost)} лв.`}
                  {dose !== 1
                    ? ` x ${dose} = ${format('.2f')(totalCost * dose)} лв.`
                    : null}
                </p>
                <p>
                  {`${format('.2f')(totalCost / secCostRatio)} лв.
                    за ${secMap[0]} ${displayUnit(secCostUnit)}`}
                </p>
              </RoleFilter>
            </Grid.Column>

            <Grid.Column>
              <Header as='h4'>Плътност</Header>
              <p>
                {density ? `${density} ${displayUnit(densityUnit)}` : '~'}
              </p>
            </Grid.Column>

            <Grid.Column>
              <Header as='h4'>Трайност</Header>
              <p>{_.isNil(duration) ? '~' : `${duration} дни`}</p>
            </Grid.Column>

            <Grid.Column>
              <Header as='h4'>Продажна цена</Header>
              <p>
                {_.isNil(salePrice)
                  ? '~'
                  : `${format('.2f')(salePrice)} лв./${displayUnit(
                    getSalePriceUnit(unit)
                  )}`}
              </p>
            </Grid.Column>
          </Grid>

          <Menu secondary pointing>
            <Menu.Item header active>
              Съставки
            </Menu.Item>
            <Menu.Item
              icon='angle double right'
              title='Затвори всичко'
              onClick={() => dispatch(resetPrep(id))}
            />
            <Menu.Item
              icon='angle double down'
              title='Отвори всичко'
              onClick={() => dispatch(expandAll(id))}
            />
            <Menu.Item
              icon='play'
              title='Изпълни рецепта'
              link
              onClick={() => {
                navigate('/products/new', {
                  state: runRecipe(recipe, prep, stocks, products, resources, recipes),
                })
              }}
            />
          </Menu>

          <div className='y-scrollable'>
            <RecipeIngredients recipeId={id} totalCost={totalCost} />
          </div>

          <Divider hidden />

          {desc ? (
            <div>
              <Header as='h4'>Описание</Header>
              <p>
                {_.map(_.split(desc, '\n'), (p, index) => (
                  <span key={index}>
                    {p}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          ) : null}

          {byProduct && (
            <>
              <Header as='h4'>Вторичен продукт</Header>
              <Link to={`/resources/${byProduct.resourceId}`}>
                <Icon name='external' color={COLORS.RESOURCE} />
              </Link>
              {byProductResource.name} - {byProduct.qty}{' '}
              {displayUnit(byProduct.unit)}
              {dose !== 1
                ? ` x ${dose} = ${format('.2~f')(
                  byProduct.qty * dose
                )} ${displayUnit(byProduct.unit)}`
                : null}
            </>
          )}
        </Segment>
      </div>

      <RoleFilter requires={Role.MANAGER}>
        <Menu secondary pointing>
          {_.map(
            [
              ['', 'Продукти'],
              ['sales', 'Продажби'],
              ['recipes', 'Рецепти'],
            ],
            ([to, text], index) => (
              <NavLink to={to} key={index} end>
                {({ isActive }) => (<Menu.Item content={text} active={isActive} />)}
              </NavLink>
            )
          )}
        </Menu>

        <Routes>
          <Route
            path='/'
            element={<>
              <PaginationControls
                offset={productQuery.filter.offset}
                limit={productQuery.filter.limit}
                total={productQuery.total}
                setFilter={(filter) =>
                  dispatch(queryProducts({
                    ...productQuery.filter,
                    ...filter,
                  }))
                }
                loading={productQuery.status.isFetching}
              />
              <ProductListing
                listing={productQuery.listing}
                loading={productQuery.status.isFetching}
              />
            </>}
          />

          <Route
            path='sales'
            element={
              <>
                <div className='flexwrap'>
                  <div>
                    <PaginationControls
                      offset={saleQuery.filter.offset}
                      limit={saleQuery.filter.limit}
                      total={saleQuery.total}
                      setFilter={(filter) =>
                        dispatch(querySales({
                          ...saleQuery.filter,
                          ...filter,
                        }))
                      }
                      loading={saleQuery.status.isFetching}
                    />
                  </div>

                  <RoleFilter>
                    <Label
                      as='span'
                      size='large'
                      style={{ marginLeft: 'auto', marginRight: '0.5em' }}
                      content={format(',.2f')(saleQuery.sum) + ' лв.'}
                    />
                  </RoleFilter>
                </div>
                <SaleListing
                  listing={saleQuery.listing}
                  loading={saleQuery.status.isFetching}
                />
              </>}
          />

          <Route
            path='recipes'
            element={<RecipeListing listing={recipeListing} />}
          />
        </Routes>
      </RoleFilter>
    </>
  )
}

export default Recipe