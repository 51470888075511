import _ from 'lodash'
import update from 'immutability-helper'

import {
  ADD_STOCK_REQUEST,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAILURE,
  LOAD_STOCKS_REQUEST,
  LOAD_STOCKS_SUCCESS,
  LOAD_STOCKS_FAILURE,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAILURE,
  formTypes,
  SET_STOCK_FILTER,
  QUERY_STOCKS_REQUEST,
  QUERY_STOCKS_SUCCESS,
  QUERY_STOCKS_FAILURE,
  STOCKS_USAGE_REQUEST,
  STOCKS_USAGE_FAILURE,
  STOCKS_USAGE_SUCCESS,
} from '../actions/stocks'

import {
  SYNC_ADD_STOCK,
  SYNC_UPDATE_STOCK,
  SYNC_STOCK_QTY,
} from '../actions/sync'

export const INITIAL_STATE = {
  add: {
    isFetching: false,
    error: null,
  },
  update: {
    isFetching: false,
    error: null,
  },
  load: {
    isFetching: false,
    error: null,
  },
  form: {
    // per delivery
    supplier: null,
    arrivalDate: '',
    arrivalTime: '',
    receipt: '',

    // delivery items
    items: [],

    item: {
      resource: null,
      batches: '1',
      quantity: '',
      unit: '',
      price: '',
      expiry: '',
      batchId: '',
      desc: '',
      producer: '',
      origin: '',
      bioCert: '',
      storage: '',
    },
  },
  filter: {
    offset: 0,
    limit: 15,
    query: '',
    showAll: false,
    showOverdrawn: false,
  },
  items: {},

  query: {
    listing: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 10,
    },
    status: {
      isFetching: false,
      error: null,
    },
  },

  usage: {
    log: [],
    stockId: null,
    status: { isFetching: false, error: null },
  },
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADD_STOCK_REQUEST:
      return {
        ...state,
        add: {
          isFetching: true,
          error: null,
        },
      }
    case ADD_STOCK_FAILURE:
      return {
        ...state,
        add: {
          isFetching: false,
          error: action.error,
        },
      }
    case ADD_STOCK_SUCCESS:
      return {
        ...state,
        add: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          ..._.keyBy(action.payload.stocks, 'id'),
        },
      }

    case UPDATE_STOCK_REQUEST:
      return {
        ...state,
        update: {
          isFetching: true,
          error: null,
        },
      }
    case UPDATE_STOCK_FAILURE:
      return {
        ...state,
        update: {
          isFetching: false,
          error: action.error,
        },
      }
    case UPDATE_STOCK_SUCCESS:
      return {
        ...state,
        update: {
          isFetching: false,
          error: null,
        },
        items: {
          ...state.items,
          ..._.keyBy(action.payload.stocks, 'id'),
        },
      }

    case LOAD_STOCKS_REQUEST:
      return {
        ...state,
        load: {
          isFetching: true,
          error: null,
        },
      }
    case LOAD_STOCKS_FAILURE:
      return {
        ...state,
        load: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOAD_STOCKS_SUCCESS:
      return {
        ...state,
        load: {
          isFetching: false,
          error: null,
        },
        items: _.keyBy(action.payload, (item) => item.id),
      }

    case QUERY_STOCKS_REQUEST:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case QUERY_STOCKS_FAILURE:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case QUERY_STOCKS_SUCCESS:
      return {
        ...state,
        query: {
          ...state.query,
          status: {
            isFetching: false,
            error: null,
          },
          listing: action.listing,
          total: action.total,
          filter: action.filter,
        },
        items: action.cache
          ? {
              ...state.items,
              ..._.keyBy(action.listing, 'id'),
            }
          : state.items,
      }

    case STOCKS_USAGE_REQUEST:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: true,
            error: null,
          },
        },
      }
    case STOCKS_USAGE_FAILURE:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: false,
            error: action.error,
          },
        },
      }
    case STOCKS_USAGE_SUCCESS:
      return {
        ...state,
        usage: {
          ...state.usage,
          status: {
            isFetching: false,
            error: null,
          },
          log: action.log,
        },
      }

    // form actions
    case formTypes.STOCK_FORM_SET:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      }
    case formTypes.STOCK_FORM_RESET:
      return {
        ...state,
        form: {
          ...INITIAL_STATE.form,
          arrivalDate: action.date,
          arrivalTime: action.time,
        },
      }
    case formTypes.STOCK_FORM_ADD_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          items: [
            ...state.form.items,
            { ...state.form.item, key: _.uniqueId() },
          ],
          item: INITIAL_STATE.form.item,
        },
      }
    case formTypes.STOCK_FORM_UPDATE_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          items: _.map(state.form.items, (item) => {
            if (item.key === state.form.item.key) {
              return state.form.item
            }
            return item
          }),
          item: INITIAL_STATE.form.item,
        },
      }
    case formTypes.STOCK_FORM_REMOVE_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          items: _.filter(state.form.items, (v, i) => i !== action.index),
        },
      }
    case formTypes.STOCK_FORM_COPY_ITEM:
      return {
        ...state,
        form: {
          ...state.form,
          item: state.form.items[action.index],
        },
      }

    // filter
    case SET_STOCK_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      }

    // sync
    case SYNC_ADD_STOCK:
    case SYNC_UPDATE_STOCK:
      return {
        ...state,
        items: {
          ...state.items,
          ..._.keyBy(action.payload.stocks, 'id'),
        },
      }
    case SYNC_STOCK_QTY:
      return update(state, {
        items: { $merge: _.keyBy(action.payload.stocks, 'id') },
      })

    default:
      return state
  }
}
