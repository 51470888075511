import React, { useEffect, useRef, useState } from 'react'
import { Search, Input } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Fuse from 'fuse.js'
import Mousetrap from 'mousetrap'
import { useNavigate } from 'react-router-dom'

const MAX_RESULTS = 4

const SearchBox = () => {
  const navigate = useNavigate()

  const categories = useSelector((state) => ({
    recipes: {
      name: 'Рецепти',
      fuse: new Fuse(_.values(state.recipes.items), {
        shouldSort: true,
        location: 0,
        distance: 100,
        threshold: 0.5,
        useExtendedSearch: true,
        keys: [
          { name: 'name', weight: 0.8 },
          { name: 'desc', weight: 0.2 },
        ],
      }),
    },
    resources: {
      name: 'Суровини',
      fuse: new Fuse(_.values(state.resources.items), {
        shouldSort: true,
        location: 0,
        distance: 100,
        threshold: 0.5,
        useExtendedSearch: true,
        keys: [
          { name: 'name', weight: 0.8 },
          { name: 'desc', weight: 0.2 },
        ],
      }),
    },
    companies: {
      name: 'Фирми',
      fuse: new Fuse(_.values(state.companies.items), {
        shouldSort: true,
        location: 0,
        distance: 100,
        threshold: 0.5,
        useExtendedSearch: true,
        keys: [
          { name: 'name' },
          { name: 'companyName' }],
      }),
    },
  }))

  const inputRef = useRef()
  const [state, setState] = useState({ searchInput: '', searchResults: {} })

  const resetSearch = () => {
    setState({ searchInput: '', searchResults: {} })
  }

  const handleResultSelect = (e, { result }) => {
    resetSearch()
    navigate(`/${result.category}/${result.id}`)
  }

  const handleFocus = (e) => {
    inputRef.current.focus()
    e.preventDefault()
  }

  useEffect(() => {
    // search
    Mousetrap.bind('/', handleFocus)

    // new recipe / нова рецепта
    Mousetrap.bind(['n r', 'н р'], () =>
      _.defer(() => navigate('/recipes/new'))
    )

    // new delivery / нова наличност
    Mousetrap.bind(['n d', 'н н'], () =>
      _.defer(() => navigate('/stocks/delivery/new'))
    )

    // new sale / нова продажба
    Mousetrap.bind(['n s', 'н п'], () =>
      _.defer(() => navigate('/sales/new'))
    )

    // new product / нов продукт
    Mousetrap.bind(['p r', 'п р'], () =>
      _.defer(() => navigate('/products/new'))
    )

    // see recipes / виж рецепти
    Mousetrap.bind(['s r', 'в р'], () =>
      _.defer(() => navigate('/recipes'))
    )

    // see charts / виж отчети
    Mousetrap.bind(['s c', 'в о'], () =>
      _.defer(() => navigate('/reports'))
    )

    // see products / виж продукти
    Mousetrap.bind(['s p', 'в п'], () =>
      _.defer(() => navigate('/products'))
    )
  }, [navigate])

  const handleSearchChange = (e, { value }) => {
    const normalized = _.trim(value)
    if (_.isEmpty(normalized)) {
      return resetSearch()
    }

    const searchResults = _.reduce(
      categories,
      (final, { name, fuse }, category) => {
        const results = _.chain(fuse.search(normalized))
          .slice(0, MAX_RESULTS)
          .map(({ item }) => ({
            category,
            id: item.id,
            title: item.name,
            description: _.truncate(item.desc || item.companyName, {
              length: 40,
            }),
          }))
          .value()

        if (!_.isEmpty(results)) {
          final[category] = { name, results }
        }
        return final
      },
      {}
    )

    setState({
      searchInput: value,
      searchResults,
    })
  }

  return (
    <Search
      id='search-box'
      category
      placeholder='Търси'
      selectFirstResult
      noResultsMessage='Няма открити резултати'
      input={<Input ref={inputRef} transparent iconPosition='left' />}
      onSearchChange={_.debounce(handleSearchChange, 300, {
        leading: true,
      })}
      onResultSelect={handleResultSelect}
      value={state.searchInput}
      results={state.searchResults}
    />
  )
}


export default SearchBox
