import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Header, Menu, Segment, Form, Confirm, Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'

import {
  queryStocks,
  update,
  formActions,
  QUERY_STOCKS_SUCCESS,
  UPDATE_STOCK_SUCCESS,
} from '../../actions/stocks'
import { INITIAL_STATE } from '../../reducers/stocks'
import StockForm from './StockForm'
import { COLORS, withParamsAndNavigate } from '../../utils'
import Prompt from '../common/Prompt'

class EditStockDelivery extends Component {
  static propTypes = {
    deliveryId: PropTypes.string.isRequired,
    companies: PropTypes.object.isRequired,

    delivery: PropTypes.array.isRequired,
    queryStocks: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,

    update: PropTypes.func.isRequired,
    updateStatus: PropTypes.object.isRequired,

    form: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }

  state = {
    confirmSave: false,
  }

  handleDeliveryUpdate = () => {
    const { form, deliveryId } = this.props

    if (this.isValidStock()) {
      let deliveryUpdate = {
        supplier: form.supplier,
        receipt: form.receipt,
        arrival: moment(
          [form.arrivalDate, form.arrivalTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
        items: _.map(form.items, (item) => {
          if (item.expiry) {
            return {
              ...item,
              expiry: moment(item.expiry, 'DD-MM-YYYY').format(),
            }
          }
          return item
        }),
      }

      this.props.update(deliveryId, deliveryUpdate).then((action) => {
        if (action.type === UPDATE_STOCK_SUCCESS) {
          // disable unsaved-changes prompt
          this.isSaved = true
          this.props.navigate(
            `/stocks/delivery/${action.payload.deliveryId}`
          )
        }
      })
    }
  }

  isValidStock = () => {
    const { supplier, arrivalDate, arrivalTime, items } = this.props.form
    return (
      supplier &&
      moment(arrivalDate, 'D-M-YYYY').isValid() &&
      moment(arrivalTime, 'H:m').isValid() &&
      !_.isEmpty(items)
    )
  }

  copyDelivery(delivery) {
    const { setForm } = this.props

    if (_.isEmpty(delivery)) {
      return
    }
    let { supplier, arrival, receipt } = delivery[0]

    this.uneditedForm = {
      ...INITIAL_STATE.form,
      supplier,
      receipt,
      arrivalDate: moment(arrival).format('DD/MM/YY'),
      arrivalTime: moment(arrival).format('HH:mm'),
      items: _.map(delivery, (item) => ({
        ...item,
        key: _.uniqueId(),
        expiry: item.expiry
          ? moment(item.expiry).format('DD-MM-YYYY')
          : item.expiry,
      })),
    }
    setForm(this.uneditedForm)
  }

  componentDidMount() {
    // Potential optimization
    // const {
    //   query: { filter, status },
    //   deliveryId
    // } = this.props
    // if (filter.delivery === deliveryId && !status.isFetching) {
    //   this.copyDelivery(this.props.delivery)
    //   return
    // }

    this.props
      .queryStocks({
        delivery: this.props.deliveryId,
      })
      .then((action) => {
        if (action.type === QUERY_STOCKS_SUCCESS) {
          this.copyDelivery(action.listing)
        }
      })
  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  isDirty = () =>
    !this.isSaved && !_.isEqual(this.props.form, this.uneditedForm)

  isDirtyItem = () => !_.isEqual(this.props.form.item, this.uneditedForm.item)

  render() {
    if (this.props.query.status.isFetching) {
      return <Loader active />
    }

    if (_.isEmpty(this.props.delivery)) {
      return <Header>Доставката не е открита.</Header>
    }

    const { deliveryId, delivery, companies } = this.props
    const { confirmSave } = this.state

    let { supplier } = delivery[0]
    supplier = companies[supplier]

    const title = `Доставка - ${supplier.name}`

    return (
      <div>
        <Helmet title={title} />

        <Menu attached='top' inverted color={COLORS.STOCK} borderless>
          <Menu.Item header icon='edit' content={title} />
        </Menu>

        <Segment attached>
          <StockForm
            loading={this.props.updateStatus.isFetching}
            error={this.props.updateStatus.error}
            editMode
            buttonGroup={
              <Form.Group>
                <Form.Button
                  positive
                  icon='save'
                  content='Запази промените'
                  type='button'
                  disabled={!this.isValidStock() || !this.isDirty()}
                  onClick={() => {
                    if (!this.isDirtyItem()) {
                      this.handleDeliveryUpdate()
                    } else {
                      this.setState({ confirmSave: true })
                    }
                  }}
                />
                <Form.Button
                  content='Отказ'
                  type='button'
                  basic
                  onClick={() => {
                    this.props.navigate(`/stocks/delivery/${deliveryId}`)
                  }}
                />
                <Confirm
                  content='Имате наличност под редакция. Игнорирай?'
                  cancelButton='Не'
                  confirmButton='Да'
                  size='tiny'
                  open={confirmSave}
                  onCancel={() => this.setState({ confirmSave: false })}
                  onConfirm={() => {
                    this.setState({ confirmSave: false })
                    this.handleDeliveryUpdate()
                  }}
                />
              </Form.Group>
            }
          />
        </Segment>
        <Prompt when={this.isDirty} />
      </div>
    )
  }
}

export default withParamsAndNavigate(connect(
  (
    { stocks, resources, companies },
    { params: { deliveryId } },
  ) => ({
    deliveryId,
    delivery: stocks.query.listing,
    query: stocks.query,
    updateStatus: stocks.update,
    form: stocks.form,
    resources: resources.items,
    companies: companies.items,
  }),
  (dispatch, ownProps) =>
    bindActionCreators(
      {
        queryStocks,
        update,
        ...formActions,
      },
      dispatch
    )
)(EditStockDelivery))
