import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Segment, Menu, Form, Label, Divider } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { unstable_usePrompt as usePrompt, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { useBeforeunload } from 'react-beforeunload'

import ProductForm, { isFormValid } from './ProductForm'
import { getProductLabel, COLORS } from '../../utils'
import {
  setProductForm,
  updateProduct,
  resetProductForm,
  UPDATE_PRODUCT_SUCCESS,
} from '../../actions/products'

export const copyProduct = (product, stocks) => {
  return {
    ...product,
    producedOnDate: moment(product.producedOn).format('DD/MM/YY'),
    producedOnTime: moment(product.producedOn).format('HH:mm'),
    ingredients: _.map(product.ingredients, (ingredient) => {
      if (!ingredient.stockId) {
        return ingredient
      }
      return {
        ...ingredient,
        resourceId: _.get(stocks[ingredient.stockId], 'resource'),
      }
    }),
  }
}

const EditProduct = () => {
  const params = useParams()
  const navigate = useNavigate()
  const products = useSelector((state) => state.products.items)
  const stocks = useSelector((state) => state.stocks.items)
  const product = products[params.id]
  const dispatch = useDispatch()
  const {
    form,
    update: { isFetching, error },
  } = useSelector((state) => state.products)

  const isSaved = useRef(false)
  const [uneditedForm] = useState(copyProduct(product, stocks))

  useEffect(() => {
    dispatch(setProductForm(uneditedForm))

    // unmount cleanup
    return () => {
      dispatch(resetProductForm())
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  const handleUpdateProduct = () => {
    dispatch(
      updateProduct(product.id, {
        ...form,
        producedOn: moment(
          [form.producedOnDate, form.producedOnTime].join(' '),
          'DD-MM-YYYY HH:mm'
        ).format(),
      })
    ).then((action) => {
      if (action.type === UPDATE_PRODUCT_SUCCESS) {
        // disable unsaved-changes prompt
        isSaved.current = true
        navigate(`/products/${product.id}`)
      }
    })
  }

  const isDirty = () => !isSaved.current && !_.isEqual(form, uneditedForm)

  useBeforeunload((e) => !isDirty() || e.preventDefault())
  usePrompt({
    when: isDirty,
    message: 'Промените не са съхранени! Сигурни ли сте?',
  })

  return (
    <>
      <Helmet title={product.name} />

      <Menu attached='top' inverted color={COLORS.PRODUCT} borderless>
        <Menu.Item header icon='edit' content={form.name || product.name} />
      </Menu>

      <Segment attached>
        <Label
          color={COLORS.PRODUCT}
          ribbon
          content={getProductLabel(product)}
          size='big'
        />
        <Divider hidden />

        <ProductForm
          editProductId={product.id}
          loading={isFetching}
          error={error}
          byProductDisabled={!!uneditedForm.byProduct}
          buttonGroup={
            <Form.Group widths='equal'>
              <Form.Button
                fluid
                disabled={!isFormValid(form) || !isDirty()}
                loading={isFetching}
                positive
                content='Запиши промените'
                icon='save'
                onClick={handleUpdateProduct}
              />
              <Form.Button
                fluid
                disabled={!isDirty()}
                content='Отмени промените'
                icon='undo'
                negative
                onClick={() => {
                  window.confirm('Отмени промени?') &&
                    dispatch(setProductForm(copyProduct(product, stocks)))
                }}
              />
            </Form.Group>
          }
        />
      </Segment>
    </>
  )
}

export default EditProduct
