import _ from 'lodash'
import React from 'react'
import { Table, Segment, Loader, Label, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { COLORS, getProductLabel, getLabel } from '../../utils'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { displayUnit } from '../../common/unit-utils'
import { format } from 'd3-format'

const defaultColumns = new Set([
  'date',
  'label',
  'name',
  'notes',
  'type',
  'qty',
  'unitPrice',
  'price',
  'invoice',
  'buyer',
  // 'editLink',
])

export default ({ listing, loading, columns = defaultColumns, showTotal }) => {
  const resources = useSelector(({ resources }) => resources.items)

  return (
    <div className='y-scrollable'>
      <Table
        selectable
        compact
        unstackable
        color={COLORS.SALE}
        size='small'
        singleLine
      >
        <Table.Header>
          <Table.Row>
            {columns.has('date') && <Table.HeaderCell>дата</Table.HeaderCell>}
            {columns.has('label') && (
              <Table.HeaderCell>етикет</Table.HeaderCell>
            )}
            {columns.has('name') && <Table.HeaderCell>име</Table.HeaderCell>}
            {columns.has('notes') && (
              <Table.HeaderCell>бележки</Table.HeaderCell>
            )}
            {columns.has('type') && <Table.HeaderCell>вид</Table.HeaderCell>}
            {columns.has('qty') && (
              <Table.HeaderCell colSpan={2} textAlign='center'>
                {' '}
                кол.{' '}
              </Table.HeaderCell>
            )}
            {columns.has('unitPrice') && (
              <Table.HeaderCell textAlign='right'>ед. цена</Table.HeaderCell>
            )}
            {columns.has('price') && (
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
            )}
            {columns.has('invoice') && (
              <Table.HeaderCell textAlign='right'>фактура</Table.HeaderCell>
            )}
            {columns.has('buyer') && (
              <Table.HeaderCell>купувач</Table.HeaderCell>
            )}
            {columns.has('editLink') && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Segment basic padded>
                  <Loader active />
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : (
            _.map(listing, (sale) => {
              const {
                soldOn,
                qty,
                productId: product,
                stockId: stock,
                price,
                unitPrice,
                type,
                notes,
                invoice,
              } = sale

              const name =
                (product && product.name) ||
                (stock && resources[stock.resource].name)
              const unit = (product && product.unit) || (stock && stock.unit)

              return (
                <Table.Row key={sale.id}>
                  {columns.has('date') && (
                    <Table.Cell>
                      {moment(soldOn).locale('bg').format('D MMM YYYY HH:mm')}
                    </Table.Cell>
                  )}
                  {columns.has('label') && (
                    <Table.Cell>
                      {product ? (
                        <Label
                          color={COLORS.PRODUCT}
                          style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                          tag
                          as={Link}
                          to={`/products/${product._id}`}
                          content={getProductLabel(product)}
                        />
                      ) : (
                        <Label
                          color={COLORS.STOCK}
                          style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                          tag
                          as={Link}
                          to={`/stocks/${stock._id}`}
                          content={getLabel(stock)}
                        />
                      )}
                    </Table.Cell>
                  )}
                  {columns.has('name') && (
                    <Table.Cell>
                      <Link to={`/sales/${sale.id}`}>{name}</Link>
                    </Table.Cell>
                  )}
                  {columns.has('notes') && (
                    <Table.Cell title={notes}>{_.truncate(notes)}</Table.Cell>
                  )}
                  {columns.has('type') && <Table.Cell>{type}</Table.Cell>}
                  {columns.has('qty') && (
                    <>
                      <Table.Cell textAlign='right'>{qty}</Table.Cell>
                      <Table.Cell style={{ paddingLeft: 0 }}>
                        {displayUnit(unit)}
                      </Table.Cell>
                    </>
                  )}
                  {columns.has('unitPrice') && (
                    <Table.Cell textAlign='right'>
                      {_.isNil(unitPrice) ? '~' : format('.2f')(unitPrice)}
                    </Table.Cell>
                  )}
                  {columns.has('price') && (
                    <Table.Cell textAlign='right'>
                      <strong>
                        {_.isNil(price) ? '~' : `${format('.2f')(price)} лв.`}
                      </strong>
                    </Table.Cell>
                  )}
                  {columns.has('invoice') && (
                    <Table.Cell textAlign='right'>
                      <Link to={`/sales/receipt/${sale.receiptId}`}>
                        {invoice || '<фактура>'}
                      </Link>
                    </Table.Cell>
                  )}
                  {columns.has('buyer') && (
                    <Table.Cell>
                      {sale.buyer && (
                        <Link
                          title={sale.buyer.name}
                          to={`/companies/${sale.buyer._id}`}
                        >
                          {_.truncate(sale.buyer.name)}
                        </Link>
                      )}
                    </Table.Cell>
                  )}
                  {columns.has('editLink') && (
                    <Table.Cell textAlign='center' className='hidden-tbl-cell'>
                      <Link
                        to={`/sales/receipt/${sale.id}/edit`}
                        className='show-on-hover'
                      >
                        <Icon name='edit' color='grey' />
                      </Link>
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })
          )}
        </Table.Body>

        {showTotal && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={columns.size} />
              <Table.HeaderCell textAlign='right'>
                <strong>
                  {`${format('.2f')(
                    _.sumBy(listing, (i) => parseFloat(i.price))
                  )} лв.`}
                </strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  )
}
