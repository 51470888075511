import { api } from '../utils'

// add company

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST'
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS'
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE'

export const add = (company) => (dispatch, getState) => {
  if (getState().companies.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_COMPANY_REQUEST })

  return api
    .withOrg(getState)('/company', { method: 'POST', body: company })
    .then((res) =>
      dispatch({
        type: ADD_COMPANY_SUCCESS,
        payload: res.company,
      })
    )
    .catch((error) => dispatch({ type: ADD_COMPANY_FAILURE, error }))
}

// update company

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'

export const update = (id, company) => (dispatch, getState) => {
  if (getState().companies.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_COMPANY_REQUEST })

  return api
    .withOrg(getState)(`/company/${id}`, { method: 'POST', body: company })
    .then((res) =>
      dispatch({
        type: UPDATE_COMPANY_SUCCESS,
        payload: res.company,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_COMPANY_FAILURE, error }))
}

// load companies

export const LOAD_COMPANIES_REQUEST = 'LOAD_COMPANIES_REQUEST'
export const LOAD_COMPANIES_SUCCESS = 'LOAD_COMPANIES_SUCCESS'
export const LOAD_COMPANIES_FAILURE = 'LOAD_COMPANIES_FAILURE'

export const loadCompanies = () => (dispatch, getState) => {
  const { isFetching } = getState().companies.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_COMPANIES_REQUEST })

  return api
    .withOrg(getState)('/companies')
    .then((res) =>
      dispatch({
        type: LOAD_COMPANIES_SUCCESS,
        payload: res.companies,
      })
    )
    .catch((error) => dispatch({ type: LOAD_COMPANIES_FAILURE, error }))
}

// remove companies

export const REMOVE_COMPANY_REQUEST = 'REMOVE_COMPANY_REQUEST'
export const REMOVE_COMPANY_SUCCESS = 'REMOVE_COMPANY_SUCCESS'
export const REMOVE_COMPANY_FAILURE = 'REMOVE_COMPANY_FAILURE'

export const removeCompany = (companyId) => (dispatch, getState) => {
  const { isFetching } = getState().companies.remove
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_COMPANY_REQUEST, companyId })

  return api
    .withOrg(getState)(`/company/${companyId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_COMPANY_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_COMPANY_FAILURE, error }))
}

// company form
export const formTypes = {
  COMPANY_FORM_SET: 'COMPANY_FORM_SET',
  COMPANY_FORM_RESET: 'COMPANY_FORM_RESET',
}

export const formActions = {
  setForm: (form) => ({ type: formTypes.COMPANY_FORM_SET, form }),
  resetForm: () => ({ type: formTypes.COMPANY_FORM_RESET }),
}

// filter
export const SET_COMPANY_FILTER = 'SET_COMPANY_FILTER'
export const setCompanyFilter = (filter) => ({
  type: SET_COMPANY_FILTER,
  filter,
})

// query supplier resources

export const LOAD_SUP_RESOURCES_REQUEST = 'LOAD_SUP_RESOURCES_REQUEST'
export const LOAD_SUP_RESOURCES_SUCCESS = 'LOAD_SUP_RESOURCES_SUCCESS'
export const LOAD_SUP_RESOURCES_FAILURE = 'LOAD_SUP_RESOURCES_FAILURE'

export const loadSupResources = (companyId, filter) => (dispatch, getState) => {
  const {
    status: { isFetching },
  } = getState().companies.loadSupResources
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_SUP_RESOURCES_REQUEST })

  return api
    .withOrg(getState)(`/companies/${companyId}/resources`, {
      query: filter,
    })
    .then((res) =>
      dispatch({
        type: LOAD_SUP_RESOURCES_SUCCESS,
        listing: res.listing,
        total: res.total,
        filter,
      })
    )
    .catch((error) => dispatch({ type: LOAD_SUP_RESOURCES_FAILURE, error }))
}
