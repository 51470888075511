import _ from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Container,
  Divider,
  Header,
  Message,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { format, addDays, parseISO } from 'date-fns'

import { querySales } from '../../actions/sales'
import { displayUnit } from '../../common/unit-utils'
import { getProductLabel } from '../../utils'
import './TradeDocPrint.scss'

export default () => {
  const dispatch = useDispatch()
  const recipes = useSelector((state) => state.recipes.items)
  const { receiptId } = useParams()
  const {
    query: {
      listing,
      status: { isFetching, error },
    },
  } = useSelector((state) => state.sales)

  useEffect(() => {
    dispatch(querySales({ receiptId }))
  }, [dispatch, receiptId])

  if (!isFetching && (error || _.isEmpty(listing))) {
    return <Message negative content={error || 'Receipt id not found.'} />
  }

  if (isFetching) {
    return renderLoading()
  }

  return renderSaleReceipt(listing, recipes)
}

const renderSaleReceipt = (listing, recipes) => {
  const sale = listing[0]
  const { invoice, buyer, tradeDoc } = sale
  const [, invoiceId, invoiceDate] = invoice?.match(/(\d+)\s*\/\s*(\S+)/) || []

  return (
    <>
      <Helmet title={` ${invoice}`} />
      <Container className='print-receipt'>
        <Segment basic textAlign='center'>
          <Divider hidden />
          <Header as='h1'>Търговски документ</Header>
          <Header as='h2'>№ {tradeDoc} г.</Header>
          <Header as='h3'>
            към фактура № {invoiceId.padStart(10, '0')} / {invoiceDate} г.
          </Header>
        </Segment>

        <Table compact>
          <Table.Body>
            {_.map(
              [
                ['Производител:', 'Еливал ООД'],
                [
                  'Обект:',
                  'Обект за производство и разнос на солени и сладки ядкови деликатеси',
                ],
                ['Адрес:', 'гр. Варна,  ул. „Стоенчо Хаджи Иванов“ 1'],
                ['БАБХ рег. номер:', '03040077'],
                ['БАБХ удостоверение:', '1020/01.12.2020'],
                ['Тел. за контакт:', '0899 196 136 – Елена Костадинова'],
              ],
              ([label, value], index) => (
                <Table.Row key={index}>
                  <Table.Cell textAlign='right'>
                    <i>{label}</i>
                  </Table.Cell>
                  <Table.Cell textAlign='left'>{value}</Table.Cell>
                </Table.Row>
              )
            )}
            <Table.Row>
              <Table.Cell>&nbsp;</Table.Cell>
              <Table.Cell>&nbsp;</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign='right'>
                <i>Получател:</i>
              </Table.Cell>
              <Table.Cell textAlign='left'>{buyer.companyName}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Divider hidden />

        <Table compact celled>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell>ПРОДУКТ</Table.HeaderCell>
              <Table.HeaderCell>БАРКОД</Table.HeaderCell>
              <Table.HeaderCell>КОЛИЧЕСТВО</Table.HeaderCell>
              <Table.HeaderCell>ПАРТИДА</Table.HeaderCell>
              <Table.HeaderCell>СРОК НА ГОДНОСТ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(listing, (sale, index) => {
              const expiry = addDays(
                parseISO(sale.productId.producedOn),
                sale.productId.duration
              )
              const barCode = recipes[sale.recipeId]?.barCode
              return (
                <Table.Row textAlign='center' key={index}>
                  <Table.Cell textAlign='left'>{sale.name}</Table.Cell>
                  <Table.Cell>{barCode}</Table.Cell>
                  <Table.Cell>
                    {sale.qty} {displayUnit(sale.unit)}
                  </Table.Cell>
                  <Table.Cell>{getProductLabel(sale.productId)}</Table.Cell>
                  <Table.Cell>{format(expiry, 'dd.MM.yyyy')}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Divider hidden />

        <div style={{ textIndent: '2em' }}>
          Чрез въведената в обекта Система за управление безопасността на
          храните, фирма ЕЛИВАЛ ООД гарантира безопасността и качеството на
          произвежданата продукция. Същата е годна за човешка консумация.
        </div>
        <div style={{ textIndent: '2em' }}>
          Фирмата-производител не поема отговорността за качеството на
          гореописаните продукти при неправилно съхранение и извън срока на
          трайност, указан в етикета на всеки продукт.
        </div>

        <Divider hidden section />

        <div style={{ width: '50%', margin: '0 0 0 auto' }}>
          Управител:
          <Divider fitted />
        </div>
      </Container>
    </>
  )
}

const renderLoading = () => (
  <Container>
    <Placeholder fluid>
      {_.times(10, (index) => (
        <Placeholder.Line key={index} />
      ))}
    </Placeholder>
  </Container>
)
