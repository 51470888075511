import _ from 'lodash'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SET_ORG_START,
  SET_ORG_FINISH,
  BOOTSTRAP_START,
  BOOTSTRAP_FINISH,
} from '../actions/auth'

const initialState = {
  user: null,

  activeOrg: null,
  loadingOrg: false,

  isBootstrapping: false,

  login: {
    isFetching: false,
    error: null,
  },
  fetchUser: {
    isFetching: false,
    error: null,
  },
}

export default function (state = initialState, action = {}) {
  let activeOrg = window.localStorage.org

  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        login: {
          isFetching: true,
          error: null,
        },
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          isFetching: false,
          error: action.error,
        },
      }
    case LOGIN_SUCCESS:
      if (
        !activeOrg ||
        !_.find(action.user.membership, (m) => m.org.orgname === activeOrg)
      ) {
        activeOrg = _.get(action.user.membership, '[0].org.orgname')
        window.localStorage.org = activeOrg
      }
      return {
        ...state,
        user: action.user,
        activeOrg,
        login: {
          isFetching: false,
          error: null,
        },
      }

    case FETCH_USER_REQUEST:
      return {
        ...state,
        fetchUser: {
          isFetching: true,
          error: null,
        },
      }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchUser: {
          isFetching: false,
          error: action.error,
        },
      }
    case FETCH_USER_SUCCESS:
      if (
        !activeOrg ||
        !_.find(action.user.membership, (m) => m.org.orgname === activeOrg)
      ) {
        activeOrg = _.get(action.user.membership, '[0].org.orgname')
        window.localStorage.org = activeOrg
      }
      return {
        ...state,
        user: action.user,
        activeOrg,
        fetchUser: {
          isFetching: false,
          error: null,
        },
      }

    case BOOTSTRAP_START:
      return {
        ...state,
        isBootstrapping: true,
      }
    case BOOTSTRAP_FINISH:
      return {
        ...state,
        isBootstrapping: false,
      }

    case SET_ORG_START:
      window.localStorage.org = action.org
      return {
        ...state,
        activeOrg: action.org,
        loadingOrg: true,
      }
    case SET_ORG_FINISH:
      return {
        ...state,
        loadingOrg: false,
      }

    default:
      return state
  }
}
