import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import classNames from 'classnames'

const ItemTypes = { ROW: 'row' }

export default ({ children, index, moveIngredient, disabled }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.ROW,
    hover(item) {
      const [fromIndex, toIndex] = [item.index, index]
      if (fromIndex === toIndex) {
        return
      }
      moveIngredient(fromIndex, toIndex)
      item.index = toIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  // row is both target and source
  const ref = useRef(null)
  drag(drop(ref))

  return (
    <tr
      ref={ref}
      className={classNames('ingredient', { disabled })}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      {children}
    </tr>
  )
}
