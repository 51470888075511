import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Role, ROLES, isPermitted } from '../../common/roles'
import { getUserRole } from '../../utils'

const RoleFilter = ({ requires = Role.ADMIN, children, fallback = null }) => {
  const role = useSelector((state) => getUserRole(state.auth))
  return isPermitted({ has: role, requires }) ? children : fallback
}

RoleFilter.propTypes = {
  role: PropTypes.string,
  requires: PropTypes.oneOf(ROLES),
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
}

export default RoleFilter
