import _ from 'lodash'
import React, { useEffect } from 'react'
import {
  Segment,
  Header,
  Menu,
  Grid,
  Popup,
  Divider,
  Icon,
  Table,
  Label,
  GridColumn,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Link,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import RoleFilter from '../common/RoleFilter'
import { COLORS } from '../../utils'
import NotFound from '../common/NotFound'
import PaginationControls from '../common/PaginationControls'
import StockListing from '../stocks/StockListing'
import SaleListing from '../sales/SaleListing'
import { queryStocks } from '../../actions/stocks'
import { querySales, queryInvoices } from '../../actions/sales'
import { loadSupResources } from '../../actions/companies'
import { format } from 'd3-format'
import { getStockTotals } from '../../stock-utils'
import { convertUnits, displayUnit } from '../../common/unit-utils'
import SaleReceiptListing from '../sales/SaleReceiptListing'

export default () => {
  const { id, slug } = useParams()
  const navigate = useNavigate()

  const company = useSelector((state) => state.companies.items[id])
  const priceTiers = useSelector((state) => state.priceTiers.items)
  const stockQuery = useSelector((state) => state.stocks.query)
  const saleQuery = useSelector((state) => state.sales.query)
  const invoiceQuery = useSelector((state) => state.sales.queryByInvoice)
  const resources = useSelector((state) => state.companies.loadSupResources)
  const stockTotals = useSelector((state) => getStockTotals(state))

  const dispatch = useDispatch()

  if (!company) {
    return <NotFound message='Няма такъва фирма' />
  }

  useEffect(() => {
    if (company && company.slug && company.slug !== slug) {
      navigate(company.slug, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    dispatch(queryStocks({ offset: 0, limit: 10, order: 'desc', supplier: id }))
    dispatch(querySales({ offset: 0, limit: 10, order: 'desc', buyer: id }))
    dispatch(queryInvoices({ offset: 0, limit: 10, buyer: id }))
    dispatch(loadSupResources(id, { offset: 0, limit: 10 }))
  }, [dispatch, id])

  const {
    name,
    companyName,
    originOrg,
    bulstat,
    certificate,
    location,
    contact,
    manager,
    taxNumber,
    deliveryAddress,
    paymentMethod,
    email,
    notes,
    isSupplier,
    isBuyer,
    priceTier,
  } = company

  return (
    <>
      <Helmet title={name} />

      <Menu
        attached='top'
        inverted
        color={COLORS.COMPANY}
        borderless
        className='wrap'
      >
        <Menu.Item header>
          {originOrg ? (
            <Popup
              inverted
              position='right center'
              size='small'
              trigger={<Icon name='star' />}
              content='Вътрешен доставчик за наличности на вторични продукти.'
            />
          ) : null}
          {name}
        </Menu.Item>
        <Menu.Menu position='right'>
          <RoleFilter>
            <Menu.Item icon='edit' as={Link} to={`/companies/${id}/edit`} />
          </RoleFilter>
        </Menu.Menu>
      </Menu>

      <Segment attached>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <Header content={name} subheader='име' />
            </Grid.Column>

            <Grid.Column>
              <Header content={companyName || '~'} subheader='име на фирма' />
            </Grid.Column>

            <Grid.Column>
              <Header content={bulstat || '~'} subheader='булстат' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header content={taxNumber || '~'} subheader='ДДС №' />
            </Grid.Column>

            <Grid.Column>
              <Header content={location || '~'} subheader='седалище' />
            </Grid.Column>

            <Grid.Column>
              <Header content={manager || '~'} subheader='МОЛ' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header
                content={paymentMethod || '~'}
                subheader='начин за плащане'
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                content={deliveryAddress || '~'}
                subheader='адрес за доставка'
              />
            </Grid.Column>

            <Grid.Column>
              <Header>
                <Header.Content>{certificate || '~'}</Header.Content>
                <Header.Subheader>
                  <Popup
                    content='Удостоверение за регистрация по Закона за храните'
                    size='tiny'
                    position='bottom left'
                    trigger={
                      <span>
                        УРЗХ <sup>?</sup>
                      </span>
                    }
                  />
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header content={email || '~'} subheader='имейл' />
            </Grid.Column>

            <Grid.Column>
              <Header content={contact || '~'} subheader='контакт' />
            </Grid.Column>

            {isBuyer && (
              <GridColumn>
                <Header
                  content={priceTiers[priceTier]?.name || '~'}
                  subheader='ценови клас'
                />
              </GridColumn>
            )}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Label color={isSupplier ? 'green' : null}>Доставчик</Label>
              <Label color={isBuyer ? 'green' : null}>Клиент</Label>
            </Grid.Column>
          </Grid.Row>

          {notes && (
            <Grid.Row>
              <Grid.Column width='16'>
                <em>
                  {_.map(_.split(notes, '\n'), (p, index) => (
                    <span key={index}>
                      {p}
                      <br />
                    </span>
                  ))}
                </em>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>

      <Divider hidden />

      <Menu secondary pointing>
        {_.map(
          [
            ['', 'Наличности'],
            ['resources', 'Суровини'],
            ['sales', 'Продажби'],
            ['invoices', 'Фактури'],
          ],
          ([to, text], index) => (
            <NavLink to={to} key={index} end>
              {({ isActive }) => <Menu.Item content={text} active={isActive} />}
            </NavLink>
          )
        )}
      </Menu>

      <Routes>
        <Route
          path='/'
          element={
            <>
              <PaginationControls
                {...{
                  ...stockQuery.filter,
                  total: stockQuery.total,
                  setFilter: (filter) =>
                    dispatch(
                      queryStocks({
                        ...stockQuery.filter,
                        ...filter,
                      })
                    ),
                  loading: stockQuery.status.isFetching,
                }}
              />
              <StockListing
                listing={stockQuery.listing}
                loading={stockQuery.status.isFetching}
              />
            </>
          }
        />

        <Route
          path='sales'
          element={
            <>
              <PaginationControls
                {...{
                  ...saleQuery.filter,
                  total: saleQuery.total,
                  setFilter: (filter) =>
                    dispatch(
                      querySales({
                        ...saleQuery.filter,
                        ...filter,
                      })
                    ),
                  loading: saleQuery.status.isFetching,
                }}
              />
              <SaleListing
                listing={saleQuery.listing}
                loading={saleQuery.status.isFetching}
              />
            </>
          }
        />

        <Route
          path='invoices'
          element={
            <>
              <PaginationControls
                {...{
                  ...invoiceQuery.filter,
                  total: invoiceQuery.total,
                  setFilter: (filter) =>
                    dispatch(
                      queryInvoices({
                        ...invoiceQuery.filter,
                        ...filter,
                      })
                    ),
                  loading: invoiceQuery.status.isFetching,
                }}
              />
              <SaleReceiptListing
                listing={invoiceQuery.listing}
                loading={invoiceQuery.status.isFetching}
              />
            </>
          }
        />

        <Route
          path='resources'
          element={
            <>
              <PaginationControls
                {...{
                  ...resources.filter,
                  total: resources.total,
                  setFilter: (filter) =>
                    dispatch(
                      loadSupResources(id, {
                        ...resources.filter,
                        ...filter,
                      })
                    ),
                  loading: resources.status.isFetching,
                }}
              />

              <div className='y-scrollable'>
                <Table
                  selectable
                  compact
                  singleLine
                  unstackable
                  color={COLORS.RESOURCE}
                  size='small'
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>суровина</Table.HeaderCell>
                      <Table.HeaderCell textAlign='right'>
                        налично
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell textAlign='right'>
                        последна цена (лв.)
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell textAlign='right'>
                        сума (лв.)
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign='right'>
                        доставки
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(resources.listing, (item, index) => {
                      const totalStock = stockTotals[item.resource._id] || {}
                      const lastUnitPrice =
                        item.lastUnitPrice /
                        convertUnits(item.lastUnit, item.resource.unit)
                      return (
                        <Table.Row key={index} negative={totalStock.qty === 0}>
                          <Table.Cell>
                            <Link to={`/resources/${item.resource._id}`}>
                              {item.resource.name}
                            </Link>
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            {format('.3~f')(totalStock.qty)}{' '}
                          </Table.Cell>
                          <Table.Cell>
                            {displayUnit(totalStock.unit)}
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            {format('.2f')(lastUnitPrice)}
                          </Table.Cell>
                          <Table.Cell>
                            /{displayUnit(item.resource.unit)}
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            {format('.2f')(item.total)}
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            {item.count}
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              </div>
            </>
          }
        />
      </Routes>
    </>
  )
}
