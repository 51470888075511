import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import {
  Form,
  Table,
  Header,
  Label,
  Dropdown,
  Icon,
  Button,
  Segment,
  Message,
  Grid,
  Divider,
  Confirm,
} from 'semantic-ui-react'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react'
import { useSelector, useDispatch } from 'react-redux'
import validator from 'validator'
import { Link } from 'react-router-dom'
import { format } from 'd3-format'

import DraggableRow from '../RecipeForm/DraggableRow'
import DecimalInput from '../common/DecimalInput'
import {
  setSalesForm,
  setSalesFormItem,
  addSalesFormItem,
  removeSalesFormItem,
  editSalesFormItem,
  updateSalesFormItem,
  moveSalesFormItem,
  removeSale,
  REMOVE_SALE_SUCCESS,
} from '../../actions/sales'
import { INITIAL_STATE } from '../../reducers/sales'
import { getProductLabel, getLabel, focus, COLORS } from '../../utils'
import {
  displayUnit,
  getSalePriceUnit,
  convertUnits,
} from '../../common/unit-utils'
import { PAYMENT_METHODS } from '../../common/misc'

export const SALE_TYPES = ['клиент', 'лична', 'приятел', 'персонал']
const TYPE_OPTIONS = _.map(SALE_TYPES, (value) => ({
  text: value,
  value,
}))

export default ({ isEdit, loading, error, buttonGroup }) => {
  const { form } = useSelector((state) => state.sales)
  const products = useSelector((state) => state.products.items)
  const stocks = useSelector((state) => state.stocks.items)
  const resources = useSelector((state) => state.resources.items)
  const companies = useSelector((state) => state.companies.items)
  const recipes = useSelector((state) => state.recipes.items)
  const priceTiers = useSelector((state) => state.priceTiers.items)

  const dispatch = useDispatch()
  const [confirmDelete, setConfirmDelete] = useState({ open: false })

  const isProduct = _.isUndefined(form.item.stockId)

  let itemUnit = null
  if (form.item.productId) {
    itemUnit = _.get(products[form.item.productId], 'unit')
  }
  if (form.item.stockId) {
    itemUnit = _.get(stocks[form.item.stockId], 'unit')
  }

  const totalPrice = _.sumBy(form.items, ({ price }) => {
    price = parseFloat(price)
    return isNaN(price) ? 0 : price
  })

  const calcItemPrice = useCallback(
    (qty, unitPrice, unit) => {
      if (!unit) {
        // leave unchanged
        return form.item.price
      }
      const saleUnit = getSalePriceUnit(unit)
      const price =
        parseFloat(qty) * parseFloat(unitPrice) * convertUnits(unit, saleUnit)
      return _.isNaN(price) ? form.item.price : price.toFixed(2)
    },
    [form.item.price]
  )

  const getProductSalePrice = useCallback(
    (product) => {
      const salePrices = recipes[product?.recipeId]?.salePrices
      const tieredPrice = _.find(salePrices, { tier: form.priceTier })?.price
      return tieredPrice || product?.salePrice
    },
    [recipes, form.priceTier]
  )

  const onProductSelect = useCallback(
    ({ key }, { value, product }) => {
      const unitPrice = getProductSalePrice(product)
      dispatch(
        setSalesFormItem({
          productId: value,
          unit: product.unit,
          unitPrice,
          price: calcItemPrice(form.item.qty, unitPrice, product.unit),
          stockId: undefined,
        })
      )
      key === 'Enter' && _.defer(() => focus('#quantity-input'))
    },
    [dispatch, getProductSalePrice, calcItemPrice, form.item.qty]
  )

  const getBuyerOptions = () => {
    return _.map(companies, ({ id, name, notes }) => ({
      key: id,
      value: id,
      text: name,
      content: (
        <Header size='tiny'>
          <Icon name='circle' color={COLORS.COMPANY} />
          <Header.Content>{name}</Header.Content>
          <Header.Subheader>
            {_.truncate(notes, { length: 65 })}
          </Header.Subheader>
        </Header>
      ),
    }))
  }

  return (
    <>
      <Form loading={loading}>
        <Form.Group widths={4}>
          <Form.Field required>
            <label>Дата</label>
            <DateInput
              closable
              placeholder='дата'
              name='soldOnDate'
              value={form.soldOnDate}
              onChange={(e, { value }) =>
                dispatch(setSalesForm({ soldOnDate: value }))
              }
            />
          </Form.Field>

          <Form.Field required>
            <label>Час</label>
            <TimeInput
              closable
              popupPosition='bottom right'
              placeholder='час'
              name='soldOnTime'
              value={form.soldOnTime}
              onChange={(e, { value }) =>
                dispatch(setSalesForm({ soldOnTime: value }))
              }
            />
          </Form.Field>

          <Form.Select
            label='Купувач'
            clearable
            fluid
            searchInput={{ autoFocus: true }}
            name='buyer'
            placeholder='купувач'
            search
            openOnFocus={false}
            noResultsMessage='Няма открити купувачи.'
            options={getBuyerOptions()}
            value={form.buyer}
            onChange={(e, { value }) => {
              // prevent re-render on scrolling
              if (!e.code || e.code === 'Enter') {
                dispatch(
                  setSalesForm({
                    buyer: value,
                    paymentMethod: companies[value]?.paymentMethod || '',
                    priceTier: companies[value]?.priceTier || '',
                  })
                )
              }
            }}
          />

          <Form.Dropdown
            required
            fluid
            selection
            clearable
            search
            label='Начин на плащане'
            value={form.paymentMethod}
            options={_.map(PAYMENT_METHODS, (method) => ({
              key: method,
              text: method,
              value: method,
            }))}
            onChange={(e, { value }) =>
              dispatch(setSalesForm({ paymentMethod: value }))
            }
          />
        </Form.Group>

        <Form.Group widths={4}>
          <Form.Select
            required
            fluid
            name='type'
            label='Вид'
            placeholder='вид'
            value={form.type}
            onChange={(e, { value }) => dispatch(setSalesForm({ type: value }))}
            search
            openOnFocus={false}
            options={TYPE_OPTIONS}
          />

          <Form.Input
            fluid
            label='Фактура'
            name='invoice'
            value={form.invoice}
            placeholder='фактура'
            onChange={(e, { value }) =>
              dispatch(setSalesForm({ invoice: value }))
            }
          />

          <Form.Input
            fluid
            label='Търговски документ'
            name='tradeDoc'
            value={form.tradeDoc}
            placeholder='търговски документ'
            onChange={(e, { value }) =>
              dispatch(setSalesForm({ tradeDoc: value }))
            }
          />

          <Form.Select
            fluid
            clearable
            label='Ценови клас'
            placeholder='Ценови клас'
            value={form.priceTier}
            options={_.map(priceTiers, ({ id, name }) => ({
              value: id,
              text: name,
            }))}
            onChange={(e, { value }) => {
              dispatch(setSalesForm({ priceTier: value }))
            }}
          />
        </Form.Group>
      </Form>

      <div className='y-scrollable'>
        <Table selectable unstackable compact singleLine size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>артикул</Table.HeaderCell>
              <Table.HeaderCell>наименование</Table.HeaderCell>
              <Table.HeaderCell>бележки</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' colSpan={2}>
                количество
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>ед. цена</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>цена</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(form.items, (item, index) => {
              const product = item.productId && products[item.productId]
              const stock = item.stockId && stocks[item.stockId]
              const id = item.productId || item.stockId
              const name = product
                ? product.name
                : _.get(resources[stock.resource], 'name')
              return (
                <DraggableRow
                  key={id}
                  index={index}
                  moveIngredient={(fromIndex, toIndex) =>
                    dispatch(moveSalesFormItem(fromIndex, toIndex))
                  }
                  disabled={item.key === form.item.key}
                >
                  <Table.Cell>
                    {product ? (
                      <Label
                        color={COLORS.PRODUCT}
                        style={{ opacity: product.curQty > 0 ? 1 : 0.5 }}
                        tag
                        as={Link}
                        to={`/products/${product.id}`}
                        content={getProductLabel(product)}
                      />
                    ) : (
                      <Label
                        color={COLORS.STOCK}
                        style={{ opacity: stock.curQty > 0 ? 1 : 0.5 }}
                        tag
                        as={Link}
                        to={`/stocks/${stock.id}`}
                        content={getLabel(stock)}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{_.truncate(item.notes)}</Table.Cell>
                  <Table.Cell textAlign='right'>
                    {format('.3~f')(item.qty)}{' '}
                  </Table.Cell>
                  <Table.Cell>
                    {displayUnit((product || stock).unit)}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {item.unitPrice === ''
                      ? '~'
                      : `${format('.2f')(item.unitPrice)} лв.`}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {item.price === ''
                      ? '~'
                      : `${format('.2f')(item.price)} лв.`}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    <Icon
                      name='edit'
                      color='grey'
                      link
                      onClick={() => {
                        dispatch(editSalesFormItem(index))
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    <Icon
                      name='remove'
                      color='grey'
                      link
                      onClick={() => {
                        if (isEdit && item.id) {
                          // deletes existing sale from db
                          setConfirmDelete({
                            open: true,
                            index,
                            saleId: item.id,
                          })
                        } else {
                          dispatch(removeSalesFormItem(index))
                        }
                      }}
                    />
                  </Table.Cell>
                </DraggableRow>
              )
            })}
          </Table.Body>
          <Confirm
            content='Продажбата ще бъде изтрита безвъзвратно. Сигурни ли сте?'
            cancelButton='Отмени'
            confirmButton={<Button negative content='Изтрий' />}
            size='tiny'
            open={confirmDelete.open}
            onCancel={() => setConfirmDelete({ open: false })}
            onConfirm={() => {
              dispatch(removeSale(confirmDelete.saleId)).then((action) => {
                if (action.type === REMOVE_SALE_SUCCESS) {
                  dispatch(removeSalesFormItem(confirmDelete.index))
                }
                setConfirmDelete({ open: false })
              })
            }}
          />

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell textAlign='right' colSpan={7}>
                <strong>{format('.2f')(totalPrice)} лв.</strong>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={2} />
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>

      <Segment secondary basic>
        <Grid doubling>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Button.Group widths='8'>
                <Button
                  color={isProduct ? COLORS.PRODUCT : null}
                  content='продукт'
                  onClick={() => {
                    dispatch(
                      setSalesFormItem({
                        productId: null,
                        stockId: undefined,
                        unitPrice: '',
                        price: '',
                      })
                    )
                  }}
                />
                <Button
                  color={!isProduct ? COLORS.STOCK : null}
                  content='наличност'
                  onClick={() => {
                    dispatch(
                      setSalesFormItem({
                        stockId: null,
                        productId: undefined,
                        unitPrice: '',
                        price: '',
                      })
                    )
                  }}
                />
              </Button.Group>
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <Form.Input
                value={form.item.notes}
                placeholder='бележки'
                fluid
                onChange={(e, { value }) => {
                  dispatch(setSalesFormItem({ notes: value }))
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Form loading={loading}>
          <Grid doubling>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Form.Field>
                  <label>{isProduct ? 'продукт' : 'наличност'}</label>
                  {isProduct ? (
                    <ProductSelect
                      id='item-select-dropdown'
                      value={form.item.productId}
                      onChange={onProductSelect}
                    />
                  ) : (
                    <StockSelect
                      id='item-select-dropdown'
                      value={form.item.stockId}
                      onChange={({ key }, { value, stock }) => {
                        const salePrice = _.get(
                          resources,
                          [stock.resource, 'salePrice'],
                          0
                        )
                        const stockCost =
                          stock.price / (stock.quantity * stock.batches)
                        dispatch(
                          setSalesFormItem({
                            stockId: value,
                            productId: undefined,
                            unit: stock.unit,
                            unitPrice: (salePrice || stockCost).toFixed(2),
                          })
                        )
                      }}
                    />
                  )}
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={4} mobile={8}>
                <Form.Field>
                  <label>количество</label>
                  <DecimalInput
                    fluid
                    id='quantity-input'
                    label={
                      itemUnit
                        ? { content: displayUnit(itemUnit), basic: true }
                        : null
                    }
                    labelPosition={itemUnit ? 'right' : null}
                    placeholder='количество'
                    value={form.item.qty}
                    onChange={(e, { value }) =>
                      dispatch(
                        setSalesFormItem({
                          qty: _.trim(value),
                          price: calcItemPrice(
                            value,
                            form.item.unitPrice,
                            itemUnit
                          ),
                        })
                      )
                    }
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={2} mobile={4}>
                <Form.Field
                  control={DecimalInput}
                  label={
                    itemUnit
                      ? `цена / ${displayUnit(getSalePriceUnit(itemUnit))}`
                      : 'ед. цена'
                  }
                  placeholder='ед. цена'
                  value={form.item.unitPrice}
                  onChange={(e, { value }) =>
                    dispatch(
                      setSalesFormItem({
                        unitPrice: _.trim(value),
                        price: calcItemPrice(form.item.qty, value, itemUnit),
                      })
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column computer={2} mobile={4}>
                <Form.Field
                  label='цена'
                  placeholder='цена'
                  control={DecimalInput}
                  value={form.item.price}
                  onChange={(e, { value }) =>
                    dispatch(setSalesFormItem({ price: _.trim(value) }))
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {!form.item.key ? (
                  <Form.Group>
                    <Form.Button
                      size='small'
                      disabled={!isValidItem(form.item)}
                      icon='add'
                      content='Добави продажба'
                      onClick={() => {
                        dispatch(addSalesFormItem())
                        focus('#item-select-dropdown > input')
                      }}
                    />
                    <Form.Button
                      size='small'
                      icon='refresh'
                      content='Изчисти'
                      onClick={() =>
                        dispatch(setSalesFormItem(INITIAL_STATE.form.item))
                      }
                    />
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Button
                      disabled={!isValidItem(form.item)}
                      icon='edit'
                      content='Редактирай'
                      onClick={() => {
                        dispatch(updateSalesFormItem())
                        focus('#item-select-dropdown > input')
                      }}
                    />
                    <Form.Button
                      size='small'
                      icon='refresh'
                      content='Отмени редакция'
                      onClick={() =>
                        dispatch(
                          setSalesFormItem({
                            ...INITIAL_STATE.form.item,
                            // edit key
                            key: undefined,
                          })
                        )
                      }
                    />
                  </Form.Group>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>

      <Message negative hidden={!error} content={error} />
      {buttonGroup}
    </>
  )
}

export const isValidItem = (item) => {
  const { productId, stockId, type, qty, unitPrice, price } = item

  return (
    (productId || stockId) &&
    // qty
    validator.isFloat(qty + '', { gt: 0 }) &&
    // unitPrice
    (unitPrice === '' || validator.isFloat(unitPrice + '', { gt: 0 })) &&
    // price
    (validator.isFloat(price + '', { min: 0 }) ||
      (price === '' && type === 'лична'))
  )
}

const SEQ_ID_PATTERN = /^(\d{1,4})$/i

const ProductSelect = React.memo(function ({ value, onChange, ...props }) {
  const products = useSelector((state) => state.products.items)
  const [query, setQuery] = useState()

  return (
    <Dropdown
      selection
      search
      lazyLoad
      onSearchChange={(e, { searchQuery }) => {
        searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
        setQuery(searchQuery && Number(searchQuery))
      }}
      selectOnBlur={true}
      openOnFocus={false}
      selectOnNavigation={false}
      value={value}
      placeholder='продукт'
      noResultsMessage='Няма продукт'
      options={_.chain(products)
        .filter(
          ({ id, curQty, seqId }) =>
            id === value || curQty > 0 || seqId === query
        )
        .orderBy('producedOn', 'asc')
        .map((product) => {
          const { curQty, unit, name } = product
          const label = getProductLabel(product)
          const text = ` ${name} [${format('.3~f')(curQty)} ${displayUnit(
            unit
          )}]`
          return {
            key: product.id,
            value: product.id,
            text: label + text,
            content: (
              <Header
                size='tiny'
                color={curQty > 0 ? COLORS.PRODUCT : null}
                content={label}
                subheader={text}
              />
            ),
          }
        })
        .value()}
      onChange={(e, { value }) =>
        onChange(e, { value, product: products[value] })
      }
      {...props}
    />
  )
})

const StockSelect = React.memo(
  function ({ value, onChange, ...props }) {
    const stocks = useSelector((state) => state.stocks.items)
    const resources = useSelector((state) => state.resources.items)
    const [query, setQuery] = useState()

    return (
      <Dropdown
        selection
        lazyLoad
        search
        onSearchChange={(e, { searchQuery }) => {
          searchQuery = _.get(_.trim(searchQuery).match(SEQ_ID_PATTERN), 0)
          setQuery(searchQuery && Number(searchQuery))
        }}
        selectOnBlur={true}
        openOnFocus={false}
        value={value}
        selectOnNavigation={false}
        placeholder='наличност'
        noResultsMessage='Няма наличност'
        options={_.chain(stocks)
          .filter(
            ({ id, curQty, seqId }) =>
              id === value || curQty > 0 || seqId === query
          )
          .orderBy('arrival', 'asc')
          .map((stock) => {
            const { curQty, unit, resource, desc } = stock
            const name = resources[resource].name
            const label = getLabel(stock)
            const text = ` ${name} [${format('.3~f')(curQty)} ${displayUnit(
              unit
            )}]`
            return {
              key: stock.id,
              value: stock.id,
              text: label + text,
              content: (
                <Header size='tiny' color={curQty > 0 ? COLORS.STOCK : null}>
                  <Header.Content>{label}</Header.Content>
                  <Header.Subheader>
                    {text}
                    <br />
                    <em>{_.truncate(desc, { length: 56 })}</em>
                  </Header.Subheader>
                </Header>
              ),
            }
          })
          .value()}
        onChange={(e, { value }) =>
          onChange(e, { value, stock: stocks[value] })
        }
        {...props}
      />
    )
  },
  (prev, next) => {
    return prev.value === next.value
  }
)
