import _ from 'lodash'
import { api } from '../utils'

// add recipe

export const ADD_RECIPE_REQUEST = 'ADD_RECIPE_REQUEST'
export const ADD_RECIPE_SUCCESS = 'ADD_RECIPE_SUCCESS'
export const ADD_RECIPE_FAILURE = 'ADD_RECIPE_FAILURE'

export const add = (recipe) => (dispatch, getState) => {
  if (getState().recipes.add.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: ADD_RECIPE_REQUEST })

  return api
    .withOrg(getState)('/recipe', { method: 'POST', body: recipe })
    .then((res) =>
      dispatch({
        type: ADD_RECIPE_SUCCESS,
        payload: res.recipe,
      })
    )
    .catch((error) => dispatch({ type: ADD_RECIPE_FAILURE, error }))
}

// update recipe

export const UPDATE_RECIPE_REQUEST = 'UPDATE_RECIPE_REQUEST'
export const UPDATE_RECIPE_SUCCESS = 'UPDATE_RECIPE_SUCCESS'
export const UPDATE_RECIPE_FAILURE = 'UPDATE_RECIPE_FAILURE'

export const update = (id, recipe) => (dispatch, getState) => {
  if (getState().recipes.update.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_RECIPE_REQUEST })

  return api
    .withOrg(getState)(`/recipe/${id}`, { method: 'POST', body: recipe })
    .then((res) =>
      dispatch({
        type: UPDATE_RECIPE_SUCCESS,
        payload: res.recipe,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_RECIPE_FAILURE, error }))
}

// update recipe ACL

export const UPDATE_RECIPE_ACL_REQUEST = 'UPDATE_RECIPE_ACL_REQUEST'
export const UPDATE_RECIPE_ACL_SUCCESS = 'UPDATE_RECIPE_ACL_SUCCESS'
export const UPDATE_RECIPE_ACL_FAILURE = 'UPDATE_RECIPE_ACL_FAILURE'

export const updateRecipeACL = (id, acl) => (dispatch, getState) => {
  if (getState().recipes.updateACL.isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: UPDATE_RECIPE_ACL_REQUEST, acl })

  return api
    .withOrg(getState)(`/recipe/${id}/acl`, { method: 'POST', body: { acl } })
    .then((res) =>
      dispatch({
        type: UPDATE_RECIPE_ACL_SUCCESS,
        payload: res.recipes,
      })
    )
    .catch((error) => dispatch({ type: UPDATE_RECIPE_ACL_FAILURE, error }))
}

// load recipes

export const LOAD_RECIPES_REQUEST = 'LOAD_RECIPES_REQUEST'
export const LOAD_RECIPES_SUCCESS = 'LOAD_RECIPES_SUCCESS'
export const LOAD_RECIPES_FAILURE = 'LOAD_RECIPES_FAILURE'

export const loadRecipes = () => (dispatch, getState) => {
  const { isFetching } = getState().recipes.load
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: LOAD_RECIPES_REQUEST })

  return (
    api
      .withOrg(getState)('/recipes')
      .then((res) =>
        dispatch({
          type: LOAD_RECIPES_SUCCESS,
          payload: res.recipes,
        })
      )
      // reset filter
      .then(() => dispatch(setRecipeFilter({ offset: 0 })))
      .catch((error) => dispatch({ type: LOAD_RECIPES_FAILURE, error }))
  )
}

// remove recipe
export const REMOVE_RECIPE_REQUEST = 'REMOVE_RECIPE_REQUEST'
export const REMOVE_RECIPE_SUCCESS = 'REMOVE_RECIPE_SUCCESS'
export const REMOVE_RECIPE_FAILURE = 'REMOVE_RECIPE_FAILURE'

export const remove = (recipeId) => (dispatch, getState) => {
  const { isFetching } = getState().recipes.remove
  if (isFetching) {
    return Promise.resolve()
  }
  dispatch({ type: REMOVE_RECIPE_REQUEST, recipeId })

  return api
    .withOrg(getState)(`/recipe/${recipeId}`, { method: 'DELETE' })
    .then((res) => dispatch({ type: REMOVE_RECIPE_SUCCESS }))
    .catch((error) => dispatch({ type: REMOVE_RECIPE_FAILURE, error }))
}

// recipe form
export const formTypes = {
  RECIPE_FORM_SET: 'RECIPE_FORM_SET',
  RECIPE_FORM_RESET: 'RECIPE_FORM_RESET',
  RECIPE_FORM_ADD_INGREDIENT: 'RECIPE_FORM_ADD_INGREDIENT',
  RECIPE_FORM_MOVE_INGREDIENT: 'RECIPE_FORM_MOVE_INGREDIENT',
  RECIPE_FORM_REMOVE_INGREDIENT: 'RECIPE_FORM_REMOVE_INGREDIENT',
  RECIPE_FORM_COPY_INGREDIENT: 'RECIPE_FORM_COPY_INGREDIENT',
  RECIPE_FORM_UPDATE_INGREDIENT: 'RECIPE_FORM_UPDATE_INGREDIENT',
  RECIPE_FORM_SELECT_INGREDIENT: 'RECIPE_FORM_SELECT_INGREDIENT',
}

const detectCycle = (startId, nextId, items) => {
  const cycle = [nextId]
  const search = (id) => {
    for (var { recipeId } of items[id].ingredients) {
      if (recipeId) {
        cycle.push(recipeId)
        if (recipeId === startId || search(recipeId)) {
          return true
        }
        cycle.pop(recipeId)
      }
    }
  }
  search(nextId)
  return cycle.length > 1 ? _.map(cycle, (id) => items[id].name) : null
}

export const formActions = {
  setForm: (form) => ({ type: formTypes.RECIPE_FORM_SET, form }),
  resetForm: () => ({ type: formTypes.RECIPE_FORM_RESET }),
  addIngredient: (curRecipeId) => (dispatch, getState) => {
    const { recipes } = getState()
    const { recipeId } = recipes.form.ingredient

    // check for cycles when adding recipe ingredient to existing recipe
    if (curRecipeId && recipeId) {
      const cycle = detectCycle(curRecipeId, recipeId, recipes.items)
      if (cycle) {
        return dispatch({
          type: formTypes.RECIPE_FORM_SET,
          form: { ingredientCycle: cycle },
        })
      }
    }

    return dispatch({ type: formTypes.RECIPE_FORM_ADD_INGREDIENT })
  },
  moveIngredient: (fromIndex, toIndex) => ({
    type: formTypes.RECIPE_FORM_MOVE_INGREDIENT,
    fromIndex,
    toIndex,
  }),
  removeIngredient: (index) => ({
    type: formTypes.RECIPE_FORM_REMOVE_INGREDIENT,
    index,
  }),
  copyIngredient: (index) => ({
    type: formTypes.RECIPE_FORM_COPY_INGREDIENT,
    index,
  }),
  updateIngredient: () => ({ type: formTypes.RECIPE_FORM_UPDATE_INGREDIENT }),
  selectIngredient: (id, isRecipe, unit) => ({
    type: formTypes.RECIPE_FORM_SELECT_INGREDIENT,
    resourceId: isRecipe ? undefined : id,
    recipeId: isRecipe ? id : undefined,
    unit,
  }),
}

// filter
export const SET_RECIPE_FILTER = 'SET_RECIPE_FILTER'
export const setRecipeFilter = (filter) => ({ type: SET_RECIPE_FILTER, filter })

// recipe preparation

export const RESET_PREP = 'RESET_PREP'
export const resetPrep = (id) => ({ type: RESET_PREP, id })

export const EXPAND_ALL = 'EXPAND_ALL'
export const expandAll = (id) => ({ type: EXPAND_ALL, id })

export const SET_PREP_DOSE = 'SET_PREP_DOSE'
export const setPrepDose = (id, dose) => ({ type: SET_PREP_DOSE, id, dose })

export const TOGGLE_PREP_CHECK = 'TOGGLE_PREP_CHECK'
export const togglePrepCheck = (id, index) => ({
  type: TOGGLE_PREP_CHECK,
  id,
  index,
})

export const TOGGLE_PREP_EXPAND = 'TOGGLE_PREP_EXPAND'
export const togglePrepExpand = (id, index) => ({
  type: TOGGLE_PREP_EXPAND,
  id,
  index,
})
