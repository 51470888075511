import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { queryRevisions } from '../../actions/revisions'
import { INITIAL_STATE } from '../../reducers/revisions'
import PaginationControls from '../common/PaginationControls'
import { DateInput } from 'semantic-ui-calendar-react'
import RevisionListing from './RevisionListing'
import { Button } from 'semantic-ui-react'
import { COLORS } from '../../utils'

export default () => {
  const query = useSelector((state) => state.revisions.query)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(queryRevisions(INITIAL_STATE.query.filter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const [dateQuery, setDateQuery] = useState(INITIAL_STATE.query.filter.date)

  const { total, listing, filter, status } = query

  return (
    <>
      <Helmet title='Ревизии - Pantry' />
      <div className='flexwrap'>
        <div>
          <PaginationControls
            limit={filter.limit}
            offset={filter.offset}
            total={total}
            loading={status.isFetching}
            setFilter={(filter) =>
              dispatch(
                queryRevisions({
                  ...query.filter,
                  ...filter,
                })
              )
            }
          />
        </div>

        <DateInput
          clearable
          placeholder='дата'
          animation=''
          value={dateQuery}
          closable
          onChange={(e, { value }) => {
            setDateQuery(value)
            const date = moment(value, 'DD-MM-YYYY', true)
            if ((date.isValid() || _.isEmpty(value)) && filter.date !== value) {
              dispatch(queryRevisions({ ...filter, date: value, offset: 0 }))
            }
          }}
        />

        <div>
          <Button.Group size='small'>
            {_.map(
              [
                ['stock', 'наличност', COLORS.STOCK],
                ['product', 'продукт', COLORS.PRODUCT],
              ],
              ([type, text, color]) => (
                <Button
                  key={type}
                  content={text}
                  color={filter.type === type ? color : undefined}
                  onClick={() => {
                    dispatch(
                      queryRevisions({
                        ...filter,
                        type: filter.type === type ? undefined : type,
                        offset: 0,
                      })
                    )
                  }}
                />
              )
            )}
          </Button.Group>
        </div>
      </div>

      <RevisionListing listing={listing} loading={status.isFetching} />
    </>
  )
}
